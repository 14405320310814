import Helpers from "commons/helpers";
import Constants from "../../constants";
import BaseService from "./base.service";

class AdministrativeDivisionService extends BaseService {
    public getCountries = async (data?: {
        type?: number,
        name?: string,
        parentDivision?: string,
    }) => {
        const newdata = data ? Helpers.handleFormatParams(data) : "";
        const query = Helpers.isNullOrEmpty(newdata) ? "" : `?${newdata}`;
        const result = await this.api.get({
            path: Constants.ApiPath.ADMINISTRATIVE_DIVISION.GET_COUNTRIES + query,
        });
        return result.data.result;
    };
}

export default AdministrativeDivisionService;
