import { IManageUser } from "commons/interfaces";
import Constants from "../../constants";
import IdentityService from "./identity.service";

class UserService extends IdentityService {
    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.USER.GET_PAGED}?${data}`,
        });
        return result.data.result;
    };
}

export default UserService;
