const Resources = {
    Images: {
        LOGO: require("../assets/images/logo.webp"),
        THUMBNAIL: require("../assets/images/thumbnail.png"),
        AVATAR_THUMBNAIL: require("../assets/images/avatar_thumbnail.png"),
        EN: require("../assets/images/en.png"),
        VN: require("../assets/images/vi.png"),
    },
};

export default Resources;
