import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import Screens from "constants/screens";
import Constants from "../../constants";
import useModule from "hooks/useModule";

import { DashboardLayout } from "layout";
import { ModuleProduct } from "@maysoft/sale-common-react";

const ItemScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        brand: searchParams.get("brand"),
        types: searchParams.get("types"),
        searchText: searchParams.get("searchText"),
        manufacturerIds: searchParams.get("manufacturerIds"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.PRODUCT_LIST,
            detail: Screens.PRODUCT_EDIT,
        },
        keyOfStrings: "ITEM",
    });

    return (
        <DashboardLayout isPermission {...{ title, route }}>
            <ModuleProduct
                {...moduleProps}
                isBookingMode
                productTemplateCode={Constants.PRODUCT_TEMPLATE_CODE}
            />
        </DashboardLayout>
    );
};

export default ItemScreen;
