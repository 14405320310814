import Constants from "../../constants";
import SaleService from "./sale.service";

class PartnerService extends SaleService {
    public getAll = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.PARTNER.GET_ALL}?${data}`,
        });
        return result.data.result;
    }

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.PARTNER.GET_PAGED}?${data}`
        });
        return result.data.result;
    }

}

export default PartnerService;