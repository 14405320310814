import Helpers from "commons/helpers";
import Constants from "../../constants";
import SaleService from "./sale.service";
import { CashBookTypeV2, PaymentType } from "constants/enum";

export interface ICashBookExportRequest {
    endTime?: number;
    startTime?: number;
    searchText?: string;
    type?: CashBookTypeV2;
    paymentType?: PaymentType;
    organizationIds?: string[];
}

export interface ICashBookPagingRequest extends ICashBookExportRequest {
    pageSize?: number;
    pageNumber?: number;
    totalCount?: number;
    orderby?: string;
}

class CashBookService extends SaleService {

    public detail = async (id: string) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.CASH_BOOK.DETAIL}/${id}`
        });
        return result.data.result;
    }

    public getPagedV2 = async (data?: ICashBookPagingRequest) => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.get({
            path: `${Constants.ApiPath.CASH_BOOK.GET_PAGED_V2}?${query}`
        });
        return result.data.result;
    }

    public exportV2 = async (data: ICashBookExportRequest) => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.getExcel({
            path: `${Constants.ApiPath.CASH_BOOK.EXPORT_V2}?${query}`
        });
        return new Blob([result.data]);
    }
}

export default CashBookService;