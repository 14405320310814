import { Box, Modal } from "@maysoft/common-component-react";
import { useCallback, useMemo, useState } from "react";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import { IBookingDetailPopupUser } from "../interfaces";
import ConfirmModeFlightBookingDetailRefundPopupContent from "./containers/Refund/ConfirmModeFlightBookingDetailRefundPopupContent";
import EditModeFlightBookingDetailRefundPopupContent from "./containers/Refund/EditModeFlightBookingDetailRefundPopupContent";
import useLoadBookingDetailServiceFee, { IAdditionServiceCalculateServiceFeeItem } from "./hooks/useLoadServiceFee";
import useBookingDetailSubmit, { IUseBookingDetailPopupSubmitProps } from "./hooks/useBookingDetailSubmit";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";
import Strings from "constants/strings";
import { PaymentType } from "constants/enum";
import { array, number, object, string } from "yup";

interface BookingDetailRefundPopupProps extends Pick<IUseBookingDetailPopupSubmitProps, "onCompleted"> {
    visible: boolean;
    onClose: () => void;
}
const BookingDetailRefundPopup = (props: BookingDetailRefundPopupProps) => {
    const [mode, setMode] = useState<"edit" | "confirm">("edit");
    const { currency, data, getBookingDetailsByUserId, isUserBookingDetailRefunded, users } = useAddMoreBookingServiceContext();

    const { loading: isSubmitLoading, onRefundSubmit, renderLoadingContent, renderPaymentContent, commonProperties: { paymentType } } = useBookingDetailSubmit({ onCompleted: props.onCompleted, data });
    const { totalFee, loading: isLoadingServiceFee, serviceFeeByBookingDetailId, checked: items, onCalculateServiceFeeForRefund, onRecalculateServiceFeeByPaymentMethod } = useLoadBookingDetailServiceFee(data);
    const totalPartialRefundUnitPrice = items.reduce((prev, c) => prev + (c.unitPrice - c.fee) || 0, 0);
    const totalPartialRefundAmount = totalPartialRefundUnitPrice - totalFee;

    const schema = array().of(
        object().shape({
            _id: string(),
            userId: string().nullable(),
            bookingDetailId: string().nullable(),
            unitPrice: number().required("Số tiền hoàn vé không được để trống"),
            fee: number().required("Phí hoàn vé không được để trống"),
        })
    );
    const [errors, setErrors] = useState<Record<string, string>>({});
    const validateError = async (items: IAdditionServiceCalculateServiceFeeItem[] = []) => {
        try {
            await schema.validate(items, {
                abortEarly: false,
            });
        } catch (e: any) {
            if (e.name === "ValidationError") {
                const { value: values, inner: inners } = e;
                const errors = inners.reduce(
                    (prev: Record<string, string | undefined>, curr: any) => {
                        const path = curr.path;
                        const firstMatchIndex = path.match(/\d+/)?.[0];
                        const relevantValue = values[firstMatchIndex] as IAdditionServiceCalculateServiceFeeItem;

                        if (relevantValue) {
                            const newPath = path.replace(
                                `[${firstMatchIndex}]`,
                                relevantValue._id
                            );
                            prev[newPath] = curr.errors[0];
                        }
                        return prev;
                    },
                    {}
                );

                return errors;
            }
        }
        return null;
    }
    const changeToConfirmMode = async () => {
        const errors = await validateError(items);
        if (errors) {
            setErrors(errors);
            return;
        }
        setMode("confirm");
    };

    const checkedGroupByUserId = useMemo(() => {
        return items.reduce((prev, c) => {
            if (c.checked) {
                if (!prev[c.userId]) {
                    prev[c.userId] = {
                        ...(users.find((u) => u.id === c.userId) ?? {}),
                        details: [],
                    };
                }

                prev[c.userId].details.push(
                    getBookingDetailsByUserId(c.userId).find(
                        (d) => d.id === c.detailId
                    ) as IBookingDetail
                );
            }
            return prev;
        }, {} as Record<string, { details: IBookingDetail[] } & Partial<IBookingDetailPopupUser>>);
    }, [items, getBookingDetailsByUserId, users]);

    const renderPaymentContentAndRecalculateServiceFee = useCallback(({ title }: { title: string }) => {
        return renderPaymentContent({
            title,
            onChangePaymentType: (paymentType: PaymentType) => {
                onRecalculateServiceFeeByPaymentMethod(paymentType, 0);
            }
        });
    }, [onRecalculateServiceFeeByPaymentMethod, renderPaymentContent])
    const renderContent = () => {
        const submitLoadingShown = isSubmitLoading;

        const editModeShown = !submitLoadingShown && mode === "edit";

        const confirmModeShown = !submitLoadingShown && mode === "confirm";
        return <Box>
            <Box sx={{
                display: { xs: submitLoadingShown ? "block" : "none" },
            }}>
                {renderLoadingContent()}
            </Box>
            <Box sx={{
                display: { xs: editModeShown ? "block" : "none" },
            }}>
                <EditModeFlightBookingDetailRefundPopupContent
                    users={users}
                    currency={currency}
                    serviceFeeByBookingDetailId={serviceFeeByBookingDetailId}

                    isUserBookingRefunded={isUserBookingDetailRefunded}
                    isLoadingServiceFee={isLoadingServiceFee}

                    items={items}
                    errors={errors}
                    onChangeItemValue={(newValue) => {
                        onCalculateServiceFeeForRefund(newValue, paymentType);
                        const newValueId = newValue._id;
                        setErrors((errors) => {
                            const newErrors = { ...errors };
                            if (typeof newValue.unitPrice === "number" && !isNaN(newValue.unitPrice)) {
                                delete newErrors[`${newValueId}.unitPrice`];
                            }
                            if (typeof newValue.fee === "number" && !isNaN(newValue.fee)) {
                                delete newErrors[`${newValueId}.fee`];
                            }
                            return newErrors;
                        })
                    }}
                    totalServiceFee={totalFee}
                    partialRefundAmount={totalPartialRefundAmount}
                    totalPartialRefundUnitPrice={totalPartialRefundUnitPrice}

                    renderPaymentContent={renderPaymentContentAndRecalculateServiceFee}
                />
            </Box>
            <Box sx={{
                display: { xs: confirmModeShown ? "block" : "none" },
            }}>
                <ConfirmModeFlightBookingDetailRefundPopupContent
                    checkedGroupByUserId={checkedGroupByUserId}
                    refundAmount={totalPartialRefundAmount}
                />
            </Box>
        </Box>
    };
    const getActions = () => {
        if (isSubmitLoading) {
            return {
                hasActionButton: false,
                title: Strings.Common.PROCESSING,
            };
        }
        if (mode === "edit") {
            return {
                onAction: changeToConfirmMode,
                hasActionButton: true,
                buttonAction: Strings.Common.CONFIRM,
                title: Strings.BOOKING.REFUND,
                onClickCloseIcon: props.onClose,
            };
        }

        if (mode === "confirm") {
            return {
                onAction: items.length > 0 ? () => {
                    onRefundSubmit({
                        selectedItems: items.filter((c) => c.checked),
                        serviceFeeByBookingDetailId,
                    })
                } : undefined,
                hasActionButton: true,
                buttonAction: Strings.Common.CONFIRM,
                closeButton: Strings.BOOKING.POPUP_GO_BACK_BUTTON,
                onClose: () => setMode("edit"),
                title: Strings.BOOKING.REFUND_POPUP_CONFIRM_TITLE,
                onClickCloseIcon: props.onClose,
            };
        }

        return {};
    };
    return (
        <Modal
            {...getActions()}
            fullWidth
            maxWidth="md"
            visible={props.visible}
        >
            {renderContent()}
        </Modal>
    );
};

export default BookingDetailRefundPopup;