import moment from "moment";
import { Card, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "constants/screens";
import Constants from "constants/index";
import UserService from "services/identity/user.service";
import CashBookService from "services/sale/cashBook.service";
import useDataBookingOrder from "components/BookingOrder/useDataBookingOrder.hook";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { CashBookTypeV2, OrderType, } from "constants/enum";
import useDataRequestBooking, { IPersionalInfo } from "components/Booking/useDataRequestBooking.hook";
import useDataPaymentInvoice, { IDetailCashBookV2, IInvoiceCashBookV2 } from "./useDataPaymentInvoice.hook";



const PaymentInvoiceDetailScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const { paymentTypeList } = useDataBookingOrder();
    const { getDataMapOrgaByIds } = useDataRequestBooking();
    const {
        getAmountCell,
        getSlipTypeCell,
        getSlipStatusCell,
    } = useDataPaymentInvoice();

    const userProfile = useSelector((state: RootState) => state.userInfo?.userProfile);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const [dataMapOrg, setDataMapOrg] = useState<Map<string, any>>(new Map());
    const [dataMapUser, setDataMapUser] = useState<Map<string, string>>(new Map());
    const [dataDetail, setDataDetail] = useState<IDetailCashBookV2>({} as IDetailCashBookV2);
    const [dataMapPartner, setDataMapPartner] = useState<Map<string, IPersionalInfo>>(new Map());

    const idDetail = searchParams.get("id");

    useEffect(() => {
        if (Helpers.isNullOrEmpty(idDetail)) {
            handleGoBack();
        } else {
            getDetail(idDetail);
        }
    }, [idDetail, userProfile?.organizationId]);

    const handleGoBack = () => {
        const itemP = listPathName.find(el => el.pathName === Screens.PAYMENT_LIST);
        navigate(Screens.PAYMENT_LIST + (itemP?.query ? itemP?.query : ""));
    }

    const getDetail = async (id: string) => {
        try {
            dispatch(showLoading(true));

            const result = await new CashBookService().detail(id);

            setDataDetail(result);

            const dataOrg = await getDataMapOrgaByIds([result?.organizationId]);
            setDataMapOrg(dataOrg);

            const newDataUser: Map<string, string> = new Map();

            const ids = [result?.depositInfo?.createUser, result?.depositInfo?.approvalUser];

            const resultUser = await new UserService().getPaged(Helpers.handleFormatParams({
                pageNumber: 1,
                pageSize: ids.length,
                listStatus: [1],
                selectedIds: ids,
                clientId: Constants.CLIENT_ID,
                organizationId: userProfile?.organizationId || undefined,
            }));

            resultUser?.selectedItems?.forEach((el: any) => {
                const fullname = `${el.organizationUserProfile?.lastName || ""} ${el.organizationUserProfile?.firstName || ""}`;
                newDataUser.set(el.id, fullname || el.fullName)
            });

            setDataMapUser(newDataUser);

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        } finally {
            dispatch(showLoading(false));
        }
    };

    const newItemOrganization = useMemo(() => (
        dataMapOrg.get(dataDetail?.organizationId)
    ), [dataMapOrg, dataDetail?.organizationId]);

    const totalCountCell = useMemo(() => (
        getAmountCell({
            type: dataDetail?.type,
            amount: dataDetail?.amount,
            slipType: dataDetail?.cashBookType,
            currency: dataDetail?.currency || userProfile?.currency,
        })
    ), [
        getAmountCell,
        dataDetail?.type,
        dataDetail?.amount,
        dataDetail?.currency,
        userProfile?.currency,
        dataDetail?.cashBookType,
    ]);

    const slipTypeCell = useMemo(() => (
        getSlipTypeCell({
            type: dataDetail?.type,
            slipType: dataDetail?.cashBookType,
        })
    ), [
        getSlipTypeCell,
        dataDetail?.type,
        dataDetail?.cashBookType,
    ]);

    const slipStatusCell = useMemo(() => (
        getSlipStatusCell(dataDetail?.slipStatus)
    ), [getSlipStatusCell, dataDetail?.slipStatus]);

    return (
        <DashboardLayout
            isPermission
            isRequiredOrganization
            title={"Chi tiết giao dịch"}
            route={[
                { title: "Quản lý giao dịch", route: Screens.PAYMENT_LIST },
                { title: Strings.Common.VIEW, route: "" }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="end">
                        <Button color="secondary" onClick={handleGoBack}>{Strings.Common.GO_BACK}</Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <Box p={3}>
                            <Grid container spacing={2}>
                                {/* #region Transaction Info */}
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6">
                                        {"Thông tin giao dịch"}
                                    </Typography>
                                    <Box
                                        paddingTop={1}
                                        paddingLeft={2}
                                        sx={{
                                            gap: 1,
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography variant="button">
                                            {"Mã giao dịch"}:&ensp;
                                            {dataDetail?.slipCode}
                                        </Typography>

                                        <Typography variant="button">
                                            {"Mã giao dịch CTT"}:&ensp;
                                            {dataDetail?.transactionId}
                                        </Typography>

                                        <Typography variant="button">
                                            {"Thời gian"}:&ensp;
                                            {moment(Number(dataDetail?.date) * 1000).format("HH:mm DD/MM/YYYY")}
                                        </Typography>

                                        <Typography variant="button">
                                            {"Phương thức"}:&ensp;
                                            {paymentTypeList.find(el => el.code === Number(dataDetail?.paymentMethod))?.name || ""}
                                        </Typography>

                                        <Typography variant="button">
                                            {"Loại giao dịch"}:&ensp;
                                            <Typography
                                                variant="button"
                                                color={slipTypeCell?.color as any}
                                            >
                                                {slipTypeCell?.title || ""}
                                            </Typography>
                                        </Typography>

                                        <Typography variant="button">
                                            {"Trạng thái"}:&ensp;
                                            <Typography
                                                variant="button"
                                                color={slipStatusCell?.color as any}
                                            >
                                                {slipStatusCell?.title || ""}
                                            </Typography>
                                        </Typography>
                                    </Box>
                                </Grid>

                                {/* #region Customer Info */}
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6">
                                        {"Thông tin khách hàng"}
                                    </Typography>
                                    <Box
                                        paddingTop={1}
                                        paddingLeft={2}
                                        paddingRight={2}
                                        sx={{
                                            gap: 1,
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography variant="button">
                                            {"Tên công ty"}:&ensp;
                                            {newItemOrganization?.name}
                                        </Typography>
                                        <Typography variant="button">
                                            {"Mã công ty"}:&ensp;
                                            {newItemOrganization?.code}
                                        </Typography>
                                        <Typography variant="button">
                                            {"Email"}:&ensp;
                                            {newItemOrganization?.email}
                                        </Typography>
                                        <Typography variant="button">
                                            {"Số điện thoại"}:&ensp;
                                            {newItemOrganization?.phoneNumber}
                                        </Typography>
                                    </Box>
                                </Grid>

                                {/* #region Order Info */}
                                <Grid item xs={12}>
                                    <Typography sx={{ marginBottom: 2 }} variant="h6">
                                        {"Thông tin chi tiết"}
                                    </Typography>
                                    {
                                        (slipTypeCell.code === CashBookTypeV2.Deposit) &&
                                        <Card sx={{
                                            marginLeft: 2,
                                            marginRight: 2,
                                            marginBottom: 2,
                                            border: "0.5px #D2D2D9 solid",
                                        }}>
                                            <Box gap={1} padding={3}>
                                                <Box gap={1} display={"grid"}>
                                                    <Typography variant="button">
                                                        {"Người tạo yêu cầu"}:&ensp;
                                                        {dataDetail?.depositInfo?.createUser
                                                            ? dataMapUser.get(dataDetail?.depositInfo?.createUser)
                                                            : "Chưa xác định"
                                                        }
                                                    </Typography>

                                                    <Typography variant="button">
                                                        {"Người phê duyệt"}:&ensp;
                                                        {dataDetail?.depositInfo?.approvalUser
                                                            ? dataMapUser.get(dataDetail?.depositInfo?.approvalUser)
                                                            : "Chưa xác định"
                                                        }
                                                    </Typography>

                                                    <Typography variant="button">
                                                        {"Ghi chú"}:&ensp;
                                                        {dataDetail?.depositInfo?.note}
                                                    </Typography>
                                                </Box>
                                                <Box display={"flex"} justifyContent={"end"}>
                                                    <Typography variant="button">
                                                        {"Số tiền nạp"}:&ensp;
                                                        {totalCountCell?.title}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Card>
                                    }
                                    {
                                        (slipTypeCell.code !== CashBookTypeV2.Deposit) &&
                                        [...dataDetail?.invoices || []].map((itemInvoice, index) => (
                                            <RenderItemInvoice
                                                key={index}
                                                code={slipTypeCell.code}
                                                itemInvoice={itemInvoice}
                                                colorAmount={totalCountCell?.color}
                                            />
                                        ))
                                    }
                                </Grid>

                                <Grid item xs={12}>
                                    <Box sx={{
                                        marginLeft: 2,
                                        paddingRight: 4,
                                        display: "flex",
                                        justifyContent: "end",
                                    }}>
                                        <Typography
                                            variant="h6"
                                            sx={{ display: "inline-flex" }}
                                            color={totalCountCell?.color as any}
                                        >
                                            {"Tổng tiền"}:&ensp;
                                            {totalCountCell?.title}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default PaymentInvoiceDetailScreen;

const RenderItemInvoice = ({
    itemInvoice, code, colorAmount,
}: {
    colorAmount: any;
    code: CashBookTypeV2;
    itemInvoice: IInvoiceCashBookV2
}) => {

    return (
        <Card key={itemInvoice.id} sx={{
            marginLeft: 2,
            marginRight: 2,
            marginBottom: 2,
            border: "0.5px #D2D2D9 solid",
        }}>
            <Box padding={3} paddingTop={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box gap={1} display={"grid"}>
                            <Box sx={{
                                gap: 1,
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                            }}>
                                <Box gap={1} display={"grid"}>
                                    <Typography variant="button">
                                        {"Mã đơn hàng"}:&ensp;
                                        {itemInvoice?.orderCode}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography variant="button">
                                {"Nội dung"}:&ensp;
                                {itemInvoice?.note}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box gap={1} display={"grid"}>
                            {[...itemInvoice?.details || []].map(item => {
                                let newExtraInformation = "";
                                if ([
                                    OrderType.Transfer,
                                    OrderType.AdditionService,
                                    OrderType.PartialRefund,
                                ].includes(itemInvoice.orderType)) {

                                } else {
                                    newExtraInformation = item.extraInformation;
                                }

                                return (
                                    <RenderItemFlightInfo
                                        key={item.id}
                                        extraInformation={newExtraInformation}
                                    />
                                )
                            })}
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{
                            gap: 1,
                            height: "100%",
                            display: "flex",
                            alignItems: "end",
                            flexDirection: "column",
                        }}>
                            <Typography variant="button">
                                {"Số tiền"}:&ensp;
                                <Typography variant="button">
                                    {`${Helpers.formatCurrency(Number(itemInvoice?.amount || 0) - Number(itemInvoice?.serviceFee || 0))} ${itemInvoice?.currency || "VND"}`}
                                </Typography>
                            </Typography>
                            <Typography variant="button">
                                {"Phí dịch vụ"}:&ensp;
                                {`${Helpers.formatCurrency(Number(itemInvoice?.serviceFee || 0))} ${itemInvoice?.currency || "VND"}`}
                            </Typography>
                            <Typography variant="button" fontWeight="bold">
                                {"Tổng tiền thanh toán"}:&ensp;
                                <Typography variant="button" fontWeight="bold" color={colorAmount}>
                                    {(code === CashBookTypeV2.Refund) && "-"}
                                    {`${Helpers.formatCurrency(Number(itemInvoice?.amount || 0))} ${itemInvoice?.currency || "VND"}`}
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
};

const RenderItemFlightInfo = (props: { extraInformation: any }) => {
    const dataExtraInfo = Helpers.converStringToJson(props?.extraInformation);

    const carrierMarketing = [
        `${dataExtraInfo?.CarrierMarketingObj?.Code || ""}`,
        `${dataExtraInfo?.CarrierMarketingObj?.Name?.["vi"] || ""}`
    ];

    const text = [`${"Hãng bay"}: ${carrierMarketing?.join(" - ")}`];

    if (!Helpers.isNullOrEmpty(dataExtraInfo?.CabinClass)) {
        text.push(`${"Hạng cabin"}: ${dataExtraInfo?.CabinClass || "..."}`);
    }

    const itemHandBaggage = dataExtraInfo?.FreeBaggage?.find((el: any) => `${el.IsHandBaggage}` === "true");
    if (!Helpers.isNullOrEmpty(itemHandBaggage?.Name)
        || !Helpers.isNullOrEmpty(itemHandBaggage?.Quantity)) {
        text.push(`${"Hành lý xách tay"}: ${itemHandBaggage?.Quantity || ""} kiện ${itemHandBaggage?.Name || ""}`);
    }

    const itemSignedBaggage = dataExtraInfo?.FreeBaggage?.find((el: any) => `${el.IsHandBaggage}` === "false");
    if (!Helpers.isNullOrEmpty(itemSignedBaggage?.Name)
        || !Helpers.isNullOrEmpty(itemSignedBaggage?.Quantity)) {
        text.push(`${"Hành lý ký gửi"}: ${itemSignedBaggage?.Quantity} kiện ${itemSignedBaggage?.Name || ""}`);
    }

    return (
        dataExtraInfo
            ? <Box gap={1} display={"grid"}>
                <Box display={"grid"}>
                    <Typography variant="button">
                        <Typography variant="button" fontWeight="bold">
                            {dataExtraInfo?.DepartPlaceObj?.Name || ""}
                            <Typography variant="button">
                                {` (${dataExtraInfo?.DepartPlaceObj?.Code || ""})`}
                            </Typography>
                        </Typography>
                        &ensp; &#8594; &ensp;
                        <Typography variant="button" fontWeight="bold">
                            {dataExtraInfo?.ArrivalPlaceObj?.Name || ""}
                            <Typography variant="button">
                                {` (${dataExtraInfo?.ArrivalPlaceObj?.Code || ""})`}
                            </Typography>
                        </Typography>
                    </Typography>

                    <Typography variant="button">
                        {`(`}
                        <Typography variant="button">
                            {moment(Helpers.getDateValue(dataExtraInfo?.DepartDate)).format("HH:mm")}
                            &nbsp;{Strings.Common.DAY.toLocaleLowerCase()}&nbsp;
                            {moment(Helpers.getDateValue(dataExtraInfo?.DepartDate)).format("DD/MM/YYYY")}
                        </Typography>
                        &ensp; &#8594; &ensp;
                        <Typography variant="button">
                            {moment(Helpers.getDateValue(dataExtraInfo?.ArrivalDate)).format("HH:mm")}
                            &nbsp;{Strings.Common.DAY.toLocaleLowerCase()}&nbsp;
                            {moment(Helpers.getDateValue(dataExtraInfo?.ArrivalDate)).format("DD/MM/YYYY")}
                        </Typography>
                        {`)`}
                    </Typography>
                </Box>

                <Typography variant="button">
                    &emsp;-&nbsp;{text.join("; ")}
                </Typography>
            </Box>
            : <></>
    );
};
