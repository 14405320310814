import Constants from "constants/index";
import BookingService from "./booking.service";

export interface IReqIssueTicket {
    bookingCode: string
    itineraryId: string
    acceptChange: number
    itineraryDetailId: string
    changeTimes: IChangeTime[]
}

export interface IChangeTime {
    leg: number
    departDT: string
    arrivalDT: string
    departDate: number
    arrivalDate: number
    segment: ISegment[]
}

export interface ISegment {
    index: number
    departDT: string
    departDate: string
    arrivalDT: string
    arrivalDate: string
}

class FlightService extends BookingService {
    public issue = async (data: IReqIssueTicket): Promise<string> => {
        const result = await this.api.get({
            path: Constants.ApiPath.FLIGHT.ISSUE,
            data: data,
        });
        return result.data.result;
    };

    public retrieveBooking = async (bookingCode: string): Promise<any> => {
        const result = await this.api.get({
            path: Constants.ApiPath.FLIGHT.RETRIEVE_BOOKING + `/${bookingCode}`,
        });
        return result.data.result;
    };

};

export default FlightService;
