import { RootState } from "store";
import { Grid } from "@mui/material";
import { Info } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, ReactNode, useState } from "react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Constants from "constants/index";
import Screens from "constants/screens";

import { tabArrLanguage } from "assets/data";
import { useAuth } from "providers/authProvider";
import { ITitleRoute } from "commons/interfaces";
import { showLoading } from "store/slice/loadingAPI.slice";
import { updateTitleRoute } from "store/slice/titleRoute.slice";
import { resetDataAlert, resetDataMessage } from "store/slice/message.slice";
import { Autocomplete, Box, Button, Loading, SnackbarAlert, SnackbarMessage, Typography } from "@maysoft/common-component-react";

interface IProps {
    disabled?: boolean;
    children: ReactNode;
    title?: string;
    route?: ITitleRoute[];

    isPermission: boolean; // Cần có quyền mới hiện children
    isRequiredOrganization?: boolean; // Cần có tổ chức mới hiện children

    onBackPress?: () => void;
    onActionPress?: () => void;
    onEditPress?: () => void;
    onCancelPress?: () => void;
    onSaveAndPrintPress?: () => void;
    onConfirmPress?: () => void;

    nameBackPress?: string;
    nameActionPress?: string;
    nameSaveAndPrintPress?: string;
    nameOtherPress?: string;
    nameCancelPress?: string;
    colorBackPress?: "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "default";
    colorSubmitPress?: "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "default";
    colorOtherPress?: "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "default";

    defaultLanguage?: string;
    isMultiLanguage?: boolean;
    onChangeLanguage?: (value: string) => void;
}

const DashboardLayout: React.FunctionComponent<IProps> = ({
    title,
    route,
    children,
    isPermission,
    isRequiredOrganization,
    disabled,
    onBackPress,
    onActionPress,
    onEditPress,
    onCancelPress,
    onSaveAndPrintPress,
    onConfirmPress,
    nameBackPress,
    nameActionPress,
    colorBackPress,
    colorSubmitPress,
    nameOtherPress,
    nameCancelPress,
    nameSaveAndPrintPress,
    colorOtherPress,
    isMultiLanguage,
    defaultLanguage,
    onChangeLanguage,
}: IProps) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const dispatchRedux = useDispatch();

    const loading = useSelector((state: RootState) => state.loadingAPI.loading);
    const dataAlert = useSelector((state: RootState) => state.messageSlice.dataAlert);
    const userProfile = useSelector((state: RootState) => state.userInfoSlice.userProfile);
    const dataMessage = useSelector((state: RootState) => state.messageSlice.dataMessage);

    const [tabValue, setTabValue] = useState<string>(Constants.DefaultLanguage);

    useEffect(() => {
        const handleEventNotFound = () => {
            Helpers.showAlert(
                Strings.Message.NOT_FOUND,
                "error",
                () => {
                    Helpers.isFunction(onBackPress) ? onBackPress() : navigate(Screens.DASHBOARD);
                }
            );
            dispatchRedux(showLoading(false));
        }

        const handleNotDataPermission = () => {
            Helpers.showAlert(
                Strings.Message.NOT_DATA_PERMISSION,
                "error",
                () => {
                    Helpers.isFunction(onBackPress) ? onBackPress() : navigate(Screens.DASHBOARD);
                }
            );
            dispatchRedux(showLoading(false));
        };

        const handleNotPermission = () => {
            Helpers.showAlert(
                Strings.Message.NOT_PERMISSION,
                "error",
                async () => { await auth.signoutRedirect(); }
            );
            dispatchRedux(showLoading(false));
        };

        __EventEmitter.addListener(Constants.EventName.NOT_FOUND, handleEventNotFound);
        __EventEmitter.addListener(Constants.EventName.NOT_PERMISSION, handleNotPermission);
        __EventEmitter.addListener(Constants.EventName.NOT_DATA_PERMISSION, handleNotDataPermission);

        return () => {
            __EventEmitter.removeListener(Constants.EventName.NOT_FOUND, handleEventNotFound);
            __EventEmitter.removeListener(Constants.EventName.NOT_PERMISSION, handleNotPermission);
            __EventEmitter.removeListener(Constants.EventName.NOT_DATA_PERMISSION, handleNotDataPermission);
        }
    }, [navigate])

    useEffect(() => {
        if (defaultLanguage) {
            setTabValue(defaultLanguage);
        }
    }, [defaultLanguage]);

    useEffect(() => {
        dispatchRedux(
            updateTitleRoute({
                route: route || [],
                titleScreen: title || "",
                targerScreen: `${window.location.pathname}${window.location.search}`,
            })
        );
        // lưu path lại
        sessionStorage.setItem(Constants.StorageKeys.FROM, `${window.location.pathname}${window.location.search}`);
    }, [title, route]);

    useEffect(() => {
        return () => {
            dispatchRedux(resetDataAlert());
            dispatchRedux(resetDataMessage());
        };
    }, [dispatchRedux]);

    const renderBoxChildren = () => {
        return (
            <>
                {
                    // (Helpers.isFunction(onBackPress) || Helpers.isFunction(onActionPress) || isMultiLanguage) &&
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mb: 2 }}>
                        {isMultiLanguage && (
                            <Box width="150px" mr={2}>
                                <Autocomplete
                                    size="small"
                                    isSelectedBox
                                    variant="outlined"
                                    defaultValue={tabValue}
                                    data={tabArrLanguage || []}
                                    onChange={(newValue) => {
                                        setTabValue(newValue);
                                        Helpers.isFunction(onChangeLanguage) && onChangeLanguage(newValue);
                                    }}
                                />
                            </Box>
                        )}
                        {Helpers.isFunction(onBackPress) && (
                            <Button color={colorBackPress || "secondary"} onClick={onBackPress}>
                                {nameBackPress || Strings.Common.GO_BACK}
                            </Button>
                        )}
                        {Helpers.isFunction(onCancelPress) && (
                            <Button style={{ marginLeft: 5 }} color="error" onClick={onCancelPress}>
                                {nameCancelPress || Strings.Common.CANCEL}
                            </Button>
                        )}
                        {Helpers.isFunction(onActionPress) && !disabled && (
                            <Button style={{ marginLeft: 5 }} color={colorSubmitPress || "dark"} onClick={onActionPress}>
                                {nameActionPress || Strings.Common.SAVE}
                            </Button>
                        )}
                        {Helpers.isFunction(onEditPress) && (
                            <Button style={{ marginLeft: 5 }} color={colorOtherPress ? colorOtherPress : "dark"} onClick={onEditPress}>
                                {nameOtherPress || Strings.Common.EDIT}
                            </Button>
                        )}
                        {Helpers.isFunction(onSaveAndPrintPress) && (
                            <Button style={{ marginLeft: 5 }} color={"success"} onClick={onSaveAndPrintPress}>
                                {nameSaveAndPrintPress || Strings.Common.PRINT}
                            </Button>
                        )}
                        {Helpers.isFunction(onConfirmPress) && (
                            <Button style={{ marginLeft: 5 }} color={"success"} onClick={onConfirmPress}>
                                {Strings.Common.CONFIRM}
                            </Button>
                        )}
                    </Box>
                }
                {children}
            </>
        );
    };

    const renderContentMessage = () => (
        <Box
            sx={{
                padding: 1,
                marginTop: 2,
                display: "flex",
                borderRadius: 2.5,
                backgroundColor: "#fff",
            }}
        >
            <Box pr={1} pt="4px">
                <Info fontSize="inherit" />
            </Box>
            <Grid container>
                <Grid item xs={12} sm={9}>
                    <Typography variant="button" fontWeight="bold">
                        {Strings.Message.DONT_HAVE_ORGANIZATION}
                        <a
                            className="hyperlink"
                            onClick={() => {
                                __EventEmitter.emit(Constants.EventName.CHOOSE_ORGANIZATION);
                            }}
                        >
                            {Strings.Common.SELECT.toLowerCase()}
                        </a>
                        {Strings.Message.CORRESPONDING_ORGANIZATION}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button color="info" variant="text" sx={{ float: "right" }} onClick={() => navigate(Screens.ORGANIZATION_EDIT)}>
                        {Strings.PROFILE.CREATE_ORGANIZATION}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );

    const renderView = () => {
        if (isPermission) {
            if (
                isRequiredOrganization === true &&
                !Helpers.isNullOrEmpty(userProfile?.identityId) &&
                Helpers.isNullOrEmpty(userProfile?.organizationId)
            ) {
                return renderContentMessage();
            } else {
                return renderBoxChildren();
            }
        } else {
            return (
                <Box textAlign="center">{Strings.Message.NOT_PERMISSION}</Box>
            );
        }
    };

    return (
        <>
            <Loading visible={loading} />
            <Box
                sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                    px: 1,
                    py: 2,
                    position: "relative",
                    [breakpoints.up("xl")]: {
                        transition: transitions.create(["margin-left", "margin-right"], {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }),
                    },
                })}
            >
                {!Helpers.isNullOrEmpty(userProfile?.id) && renderView()}

                <SnackbarMessage
                    dataMessage={dataMessage}
                    onClose={() => {
                        dispatchRedux(resetDataMessage());
                    }}
                />

                <SnackbarAlert
                    dataAlert={dataAlert as any}
                    onClose={() => {
                        dispatchRedux(resetDataAlert());
                    }}
                />
            </Box>
        </>
    );
};

export default DashboardLayout;
