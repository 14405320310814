import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import Screens from "constants/screens";
import Constants from "../../constants";
import useModule from "hooks/useModule";

import { DashboardLayout } from "layout";
import { ModulePartner, PartnerType } from "@maysoft/sale-common-react";

const ManufacturerScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),

        types: searchParams.getAll("types")?.map((el) => Number(el)),
        loyaltyClasses: searchParams.getAll("loyaltyClasses")?.map((el) => Number(el)),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.MANUFACTURER_LIST,
            detail: Screens.MANUFACTURER_EDIT,
        },
        keyOfStrings: "MANUFACTURER",
    });

    return (
        <DashboardLayout isPermission isRequiredOrganization {...{ title, route }}>
            <ModulePartner
                key={moduleProps?.mode}
                {...moduleProps}
                isBookingMode={true}
                titlePrimary="khách sạn"
                partnerType={PartnerType.Manufacturer}
                productTemplateCode={Constants.PRODUCT_TEMPLATE_CODE}
                hiddenInput={{
                    hiddenInputEmail: false,
                    hiddenInputWebsite: false,
                    hiddenInputFaxNumber: false,
                    hiddenInputTaxNumber: false,
                }}
            />
        </DashboardLayout>
    );
};

export default ManufacturerScreen;
