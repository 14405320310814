import { RootState } from "store";
import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
    AppBar as MuiAppBar,
    Drawer as MuiDrawer,
    Box as MuiBox,
    useMediaQuery,
} from "@mui/material";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import Screens from "constants/screens";
import Resources from "commons/resources";
import InaccessibleLayout from "./inaccessibleLayout";

import { Navbar } from "components";
import { useMapScreenName } from "routes/mapScreenName";
import { IMenuItem, IRecordMenuDetail } from "commons/interfaces";
import { MenuBarEliminateRecursion } from "@maysoft/common-component-react";

const drawerWidth = 260;

const tow_rem = "2rem"; //1rem = 16px;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(12)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(12)} + 1px)`,
    },
});

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const MobileAppBar = styled(MuiAppBar)({
    backgroundColor: "transparent",
});

const DesktopAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    marginTop: "1rem",
    marginRight: "1rem",
    boxShadow: "none",
    backgroundColor: "transparent",
    zIndex: theme.zIndex.drawer,
    width: `calc(100% - (${theme.spacing(12)} + 1px) - ${tow_rem})`,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px  - ${tow_rem})`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const MobileDrawer = styled(MuiDrawer)(
    ({ theme, ownerState }: { theme?: Theme | any; ownerState: any }) => {
        const { palette } = theme;
        const { color } = ownerState;
        const { gradients } = palette;
        return {
            "& .MuiDrawer-paper": {
                margin: "1rem",
                overflow: "auto",
                width: drawerWidth,
                borderRadius: "1rem",
                scrollbarWidth: "none",
                backgroundSize: "cover",
                boxSizing: "border-box",
                maxHeight: `calc(100% - ${tow_rem})`,
                backgroundColor: gradients[color].main,
                zIndex: 1200,
            },
        };
    }
);

const DesktopDrawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, ownerState }: { theme?: Theme | any; ownerState: any }) => {
    const { palette } = theme;
    const { gradients } = palette;
    const { open, color } = ownerState;
    return {
        flexShrink: 0,
        width: drawerWidth,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": {
                ...openedMixin(theme),
                margin: "1rem",
                borderRadius: "1rem",
                scrollbarWidth: "none",
                maxHeight: `calc(100% - ${tow_rem})`,
                maxWidth: `calc(${drawerWidth}px + ${tow_rem})`,
                backgroundColor: gradients[color].main,
            },
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": {
                ...closedMixin(theme),
                margin: "1rem",
                borderRadius: "1rem",
                scrollbarWidth: "none",
                maxHeight: `calc(100% - ${tow_rem})`,
                backgroundColor: gradients[color].main,
                maxWidth: `calc((${theme.spacing(12)} - 1px) + ${tow_rem} +1rem)`,
            },
        }),
    };
});

interface IProps {
    accessible: boolean;
    routes: IMenuItem[];
    window?: () => Window;
    menuDetail?: IRecordMenuDetail[];
}


const MainLayout = (props: IProps) => {
    const elementRef = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();
    const dataMapScreenName = useMapScreenName();

    const [open, setOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const matches = useMediaQuery("(min-width: 575px)");
    const matchesMaxWidth768px = useMediaQuery("(max-width: 768px)");

    const [valueMarginTop, setValueMarginTop] = useState(120);
    const headerMobile = document.getElementById("id_header_mobile")?.clientHeight;
    const headerDesktop = document.getElementById("id_header_desktop")?.clientHeight;

    const container = props.window !== undefined ? () => props.window().document.body : undefined;

    const { titleScreen, route, listPathName } = useSelector((state: RootState) => state.titleRoute);

    const handleMobileDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
        setOpen(!open);
    };

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const updateElementSize = () => {
            const a = document.getElementById("headerMobile")?.clientHeight;
            const b = document.getElementById("headerDesktop")?.clientHeight;

            setValueMarginTop(getHeightHeader(a, b));

            if (elementRef && elementRef.current) {

            } else {

            }
        };
        // Add event listener to update element size on window resize
        window.addEventListener("resize", updateElementSize);
        // Initial update
        updateElementSize();
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", updateElementSize);
        };
    }, []);

    useEffect(() => {
        setIsMobile(matchesMaxWidth768px);
    }, [matchesMaxWidth768px]);

    useEffect(() => {
        if (matches) {
            mobileOpen && setMobileOpen(false);
        } else {
            setMobileOpen(false);
            setOpen(false);
        }
    }, [matches]);

    useEffect(() => {
        setValueMarginTop(getHeightHeader(headerMobile, headerDesktop));
    }, [headerMobile, headerDesktop]);

    const getHeightHeader = (headerMobile?: number, headerDesktop?: number) => {
        if (headerMobile < 0 || headerDesktop < 0) {
            if (headerMobile > 0) {
                return headerMobile;
            }
            if (headerDesktop > 0) {
                return headerDesktop;
            }
        } else {
            if (headerMobile > headerDesktop) {
                return headerMobile;
            } else {
                return headerDesktop;
            }
        }
    }

    return (
        <MuiBox
            ref={elementRef}
            sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "unset",
                backgroundColor: Constants.Styles.COLOR_LAYOUT.BACKGROUND_COLOR_ROOT,
            }}
        >
            {props.accessible &&
                <MobileAppBar
                    id="id_header_mobile"
                    sx={isMobile
                        ? {
                            position: "relative",
                            display: "block",
                        }
                        : {
                            position: "absolute",
                            display: {
                                xs: {
                                    ml: { sm: `${drawerWidth}px` },
                                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                                },
                                sm: "none",
                                md: "none",
                            },
                        }}
                >
                    {isMobile && (
                        <Navbar
                            route={route}
                            isMini={!open}
                            title={titleScreen}
                            handleDrawerToggle={handleMobileDrawerToggle}
                        />
                    )}
                </MobileAppBar>
            }

            {props.accessible &&
                <DesktopAppBar
                    id="id_header_desktop"
                    position="absolute"
                    open={open}
                    sx={{
                        display: isMobile
                            ? "none"
                            : {
                                xs: "none",
                                sm: "block",
                                md: "block",
                                boxShadow: 0,
                            },
                    }}
                >
                    {!isMobile && (
                        <Navbar
                            route={route}
                            isMini={!open}
                            title={titleScreen}
                            handleDrawerToggle={handleDrawerToggle}
                        />
                    )}
                </DesktopAppBar>
            }

            {props.accessible &&
                <MobileDrawer
                    id="header"
                    open={mobileOpen}
                    variant="temporary"
                    container={container}
                    onClose={handleMobileDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                    ownerState={{ open: mobileOpen, color: "light" }}
                    sx={{
                        display: isMobile
                            ? "block"
                            : {
                                xs: "block",
                                sm: "none",
                            },
                    }}
                >
                    <MenuBarEliminateRecursion
                        openMenu={mobileOpen}
                        language={Strings.getLanguage()}
                        dataMenu={props.menuDetail || []}
                        dataMapScreenName={dataMapScreenName}
                        handleDrawerToggle={handleMobileDrawerToggle}

                        titleApp={Strings.App.TITLE}
                        logoApp={Resources.Images.LOGO}

                        pathNameDefault={Screens.HOME}
                        listPathName={[...listPathName || []]}
                        pathNameCurrent={location?.pathname || Screens.HOME}
                        onNavigate={(pathName) => { navigate(pathName) }}

                        onChangeLanguage={(value) => {
                            Strings.setLanguage(value);
                            Helpers.setItemInLocalStorage(Constants.StorageKeys.LANGUAGE, value);

                            __EventEmitter.emit(Constants.EventName.LANGUAGE_CHANGE);
                        }}

                        color={{
                            background: "#FFFFFF",

                            hoverMenu: "#EBF0FF",
                            activeMenu: "#EBF0FF",
                            activeSubMenu: "#EBF0FF",

                            text: "#000000",
                            hoverText: "#0A47FF",
                            activeText: "#0A47FF",
                        }}
                    />
                </MobileDrawer>
            }

            {props.accessible &&
                <DesktopDrawer
                    variant="permanent"
                    ownerState={{ open: open, color: "light" }}
                    sx={{
                        display: isMobile
                            ? "none"
                            : {
                                xs: "none",
                                sm: "block",
                            },
                    }}
                >
                    <MenuBarEliminateRecursion
                        openMenu={open}
                        language={Strings.getLanguage()}
                        dataMenu={props.menuDetail || []}
                        dataMapScreenName={dataMapScreenName}

                        titleApp={Strings.App.TITLE}
                        logoApp={Resources.Images.LOGO}

                        pathNameDefault={Screens.HOME}
                        listPathName={[...listPathName || []]}
                        pathNameCurrent={location?.pathname || Screens.HOME}
                        onNavigate={(pathName) => { navigate(pathName) }}

                        onChangeLanguage={(value) => {
                            Strings.setLanguage(value);
                            Helpers.setItemInLocalStorage(Constants.StorageKeys.LANGUAGE, value);

                            __EventEmitter.emit(Constants.EventName.LANGUAGE_CHANGE);
                        }}

                        color={{
                            background: "#FFFFFF",

                            hoverMenu: "#EBF0FF",
                            activeMenu: "#EBF0FF",
                            activeSubMenu: "#EBF0FF",

                            text: "#000000",
                            hoverText: "#0A47FF",
                            activeText: "#0A47FF",
                        }}
                    />
                </DesktopDrawer>
            }

            {/* Child screen element */}
            <MuiBox
                component="main"
                sx={({ breakpoints }: Theme) => isMobile
                    ? {
                        px: 0,
                        flexGrow: 1,
                        marginTop: 0,
                        width: "100%",
                    }
                    : {
                        flexGrow: 1,
                        px: { xs: 0, sm: 2 },

                        marginTop: `${valueMarginTop || 150}px`,
                        [breakpoints.up(1024)]: {
                            marginTop: `${valueMarginTop || 88}px`,
                        },
                        [breakpoints.between(900, 1024)]: {
                            flexDirection: !open ? "row" : "column",
                            marginTop: !open ? `${valueMarginTop || 88}px` : `${valueMarginTop || 150}px`,
                        },

                        width: {
                            xs: "100%",
                            sm: `calc(100% - ${drawerWidth}px)`,
                        },
                    }
                }
            >
                {props.accessible
                    ? <Outlet />
                    : <InaccessibleLayout />
                }
            </MuiBox>
        </MuiBox>
    );
};

export default MainLayout;
