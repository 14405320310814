import Helpers from "commons/helpers";
import Constants from "constants/index";
import ProfileService from "services/identity/profile.service";

import { call, put, takeLatest } from "redux-saga/effects";
import { GroupUserDefault, OrganizationType } from "constants/enum";
import { RoleLevel, RoleType } from "@maysoft/common-component-react";
import { UserInfoState, UserProfile, fetchUserInfo, fetchUserInfoFailed, fetchUserInfoSuccess } from "store/slice/userInfo.slice";
import { ICodename, IGroupUser, IOrganizationUserProfile, IUserAuthorization, IUserAuthorizationResponse, IUserInfo } from "commons/interfaces";

const profileService = new ProfileService();

const ROLE_DEFAULT = RoleType.Normal | RoleType.Member;

function* fetchingUser() {
    try {
        // const oldState: UserInfoState = yield select(state => state.userInfo);
        // if (oldState?.userProfile?.id) {
        //     yield put(fetchUserInfoSuccess(undefined));
        //     return;
        // }

        let organizationId = Helpers.getItemInLocalStorage(Constants.StorageKeys.ORGANIZATION_ID, "") as string;
        let organizationType = OrganizationType.Normal;

        const resultProfile: IUserInfo = yield call(profileService.getUserInfo);

        const currentServiceOrganizations =
            [...(resultProfile?.currentServiceOrganizations || [])].filter((el) => el.tenantCode === Constants.TENANT_CODE) || [];

        if (currentServiceOrganizations.length > 0) {
            const itemFirst = currentServiceOrganizations?.[0];
            const index = currentServiceOrganizations?.findIndex((el) => el.id === organizationId);
            if (index === -1 && organizationId !== "0") {
                organizationId = itemFirst?.id;
                organizationType = itemFirst?.type;
            }
        } else {
            organizationId = "0";
        }

        const userAuthorization: IUserAuthorization = yield call(
            profileService.getUserAuthorization,
            organizationId === "0" ? undefined : organizationId
        );

        let itemGroupDefaul: IGroupUser;
        let currency: string = undefined;

        let itemOrganizationUserProfile: IOrganizationUserProfile | undefined = [...(resultProfile.organizationUserProfiles || [])]?.find(
            (item) => item.organizationId === organizationId
        );

        let itemUserAuthorizationResponse = {
            roleCode: "",
            roleType: ROLE_DEFAULT,
            roleLevel: RoleLevel.Default,
            roleName: "Organization Member",
        } as IUserAuthorizationResponse;

        if ([...(userAuthorization?.userAuthorizationResponse || [])].length > 0) {
            itemUserAuthorizationResponse = [...(userAuthorization?.userAuthorizationResponse || [])].reduce((acc, curr) => {
                return Number(acc.roleLevel) < Number(curr.roleLevel) ? acc : curr;
            }, [...(userAuthorization?.userAuthorizationResponse || [])][0]);
        }

        let listOrganization: ICodename[] = currentServiceOrganizations?.map((e) => {
            if (e.id === organizationId) {
                currency = e.currency;
                organizationType = e.type;
            }

            return {
                detail: e,
                code: e.id,
                name: Helpers.renderValueByLanguage(e.name?.value) || "",
            } as ICodename;
        });

        let listGroup: ICodename[] = [...(resultProfile.groupUsers || [])]?.map((item) => {
            if (item.default === GroupUserDefault.Default && item.organizationId === organizationId) {
                itemGroupDefaul = item;
            }

            return {
                code: item.groupId,
                name: Helpers.renderValueByLanguage(item.groupName?.value) || "",
                detail: {
                    gene: item.groupGene,
                    group: item.organizationId,
                },
            } as ICodename;
        });

        if (Helpers.isNullOrEmpty(currency)) {
            currency = resultProfile?.defaultCurrency || Constants.CURRENCY_DEFAULT;
        }

        const menuDetails = [...(userAuthorization?.menuDetails || [])];
        const resourceMenu: string[] = [...(userAuthorization?.menus || [])];
        const resourceCodes = [...(userAuthorization?.roleResourcePermissions || [])]?.map((item) => ({
            resourceURI: item.resourceURI,
            permission: item.permission,
        }));

        let propsTemp: any = {
            email: resultProfile?.userProfile?.email,
            gender: resultProfile?.userProfile?.gender,
            birthDate: resultProfile?.userProfile?.birthDate,
            phoneNumber: resultProfile?.userProfile?.phoneNumber,
            fullName: resultProfile?.userProfile?.fullName || resultProfile?.userProfile?.email,
            status: resultProfile?.userProfile?.status || 0,
        };

        if (itemOrganizationUserProfile) {
            const fullName = `${itemOrganizationUserProfile?.lastName || ""} ${itemOrganizationUserProfile?.firstName || ""}`.trim();

            propsTemp = {
                gender: itemOrganizationUserProfile?.gender,
                email: itemOrganizationUserProfile?.email || "",
                fullName: fullName || itemOrganizationUserProfile?.email,
                birthDate: itemOrganizationUserProfile?.dateOfBirth || 0,
                phoneNumber: itemOrganizationUserProfile?.phoneNumber || "",

                passportNo: itemOrganizationUserProfile?.passportNo,
                nationality: itemOrganizationUserProfile?.nationality || "",
                passportExpiredDate: itemOrganizationUserProfile?.passportExpiredDate,
                passportIssuedPlace: itemOrganizationUserProfile?.passportIssuedPlace,

                status: itemOrganizationUserProfile?.activeStatus || 0,
            };
        }

        const userProfile: UserProfile = {
            id: resultProfile?.userProfile?.id || itemOrganizationUserProfile?.id || "",
            organizationId: organizationId === "0" ? undefined : organizationId,
            groupId: itemGroupDefaul?.groupId || "",

            roleCode: itemUserAuthorizationResponse?.roleCode || "",
            roleName: itemUserAuthorizationResponse?.roleName || "Default",
            roleType: itemUserAuthorizationResponse?.roleType ?? ROLE_DEFAULT,
            roleLevel: itemUserAuthorizationResponse?.roleLevel ?? RoleLevel.Default,

            userName: resultProfile?.userProfile?.userName || "",
            avatarId: resultProfile?.userProfile?.avatarId || "",
            avatarUrl: resultProfile?.userProfile?.avatarUrl || "",
            identityId: resultProfile?.userProfile?.identityId || "",

            ...propsTemp,

            organizationType,
            currency,
        };

        const result: UserInfoState = {
            listGroup,
            userProfile,
            menuDetails,
            resourceMenu,
            resourceCodes,
            listOrganization,
            currentOrganization: organizationId || "",
            accessible: userAuthorization?.accessible,
        };

        Helpers.setItemInLocalStorage(Constants.StorageKeys.ORGANIZATION_ID, organizationId || "");

        yield put(fetchUserInfoSuccess(result));
    } catch (error) {
        yield put(fetchUserInfoFailed());
    }
}

export default function* userInfoSaga() {
    yield takeLatest(fetchUserInfo().type, fetchingUser);
}
