import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, IPropDetailContainer, Typography, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "constants/screens";
import CardItemBookingOrder from "./cardItemBookingOrder";
import CardBasicBookingOrder from "./cardBasicBookingOrder";
import useDataBookingOrder from "../useDataBookingOrder.hook";
import CardPaymentBookingOrder from "./cardPaymentBookingOrder";
import CardCustomerBookingOrder from "./cardCustomerBookingOrder";
import CardHistoriesBookingOrder from "./cardHistoriesBookingOrder";
import OrganizationService from "services/identity/organization.service";
import RequestBookingService from "services/booking/requestBooking.service";
import { IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import OrderService, { IOrderDetail, IOrderHistorie, IRecordOrder } from "services/sale/order.service";



const orderService = new OrderService();
const organizationService = new OrganizationService();
const requestBookingService = new RequestBookingService();

export type IOrderDetailExtends = IOrderDetail & { orderType: number };

const BookingOrderDetailContainer = (props: IPropDetailContainer) => {
    const navigate = useNavigate();
    const {
        userInfo,
        onError,
        onHideLoading,
        onShowLoading,
    } = useCommonComponentContext();

    const { listOrderStatus, paymentTypeList, getDataMapUserByIds } = useDataBookingOrder();

    const [dataDetail, setDataDetail] = useState<IRecordOrder | undefined>(undefined);

    const [dataMapUser, setDataMapUser] = useState<Map<string, string>>(new Map());
    const [dataBooking, setDataBooking] = useState<IDetailBooking | undefined>(undefined);
    const [dataMapExtraInfoBooking, setDataMapExtraInfoBooking] = useState<Map<string, string>>(new Map());
    const [dataMapOrderDetail, setDataMapOrderDetail] = useState<Map<string, IOrderDetailExtends>>(new Map());
    const [dataMapOrderHistories, setDataMapOrderHistories] = useState<Map<string, IOrderHistorie[]>>(new Map());
    const [dataOrganization, setDataOrganization] = useState<{
        id?: string,
        name?: string,
        code?: string,
        email?: string,
        createUser?: string,
        phoneNumber?: string,
    }>({});

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(userInfo?.userProfile.organizationId) &&
            !Helpers.isNullOrEmpty(props.idDetail)) {
            getDetailOrder(props.idDetail);
        }
    }, [userInfo?.userProfile.organizationId, props.idDetail]);

    const getDetailOrder = async (id: string) => {
        try {
            onShowLoading();

            let newDataUser = new Map();
            let newDataMapOrderDetails = new Map();
            let newDataMapOrderHistories = new Map();

            let resultOrder: IRecordOrder | undefined = undefined;

            resultOrder = await orderService.getDetailOrderIncludeSubOrder(id);

            if (Helpers.isNullOrEmpty(resultOrder?.referenceId)) {
            } else {
                resultOrder = await orderService.getDetailOrderIncludeSubOrder(resultOrder?.referenceId);
            }

            const newDataBooking = await getExtraInfoDetailBookingByOrderId(resultOrder?.id);

            // Order Root
            newDataMapOrderHistories.set(resultOrder?.orderCode, [...resultOrder?.orderHistories || []]);

            const uids = [...resultOrder?.orderHistories || []].map(el => el.createUser);
            newDataUser = await getDataMapUserByIds({
                ids: uids,
                newDataUser: newDataUser,
                orgId: resultOrder?.organization?.id,
            });

            newDataMapOrderDetails = converArrayObjToMapByOrderDetail({
                orderType: resultOrder?.type,
                newMap: newDataMapOrderDetails,
                data: [...resultOrder?.orderDetails || []],
            });

            // Order Children
            let userIds: string[] = [];
            for (const element of [...resultOrder?.subOrder || []]) {

                const temps = [...element.orderHistories || []];

                const uids = temps.map(el => el.createUser);

                userIds = [...userIds || [], ...uids || []];

                newDataMapOrderHistories.set(element.orderCode, temps);

                newDataMapOrderDetails = converArrayObjToMapByOrderDetail({
                    orderType: element?.type,
                    newMap: newDataMapOrderDetails,
                    data: [...element.orderDetails || []],
                });
            };

            if (userIds.length > 0) {
                newDataUser = await getDataMapUserByIds({
                    ids: userIds,
                    newDataUser: newDataUser,
                    orgId: userInfo?.userProfile?.organizationId || "0",
                });
            }

            // get Org info
            const resultOrg = await organizationService.getOrganizationByIds([resultOrder?.organizationId]);
            const itemOrgProfile = [...resultOrg[0]?.organizationProfiles || []].find(org => (org.parentGroup === "0"));
            if (!userIds.includes(resultOrg[0]?.createUser) &&
                !uids.includes(resultOrg[0]?.createUser)) {
                newDataUser = await getDataMapUserByIds({
                    orgId: resultOrg[0]?.id,
                    newDataUser: newDataUser,
                    ids: [resultOrg[0]?.createUser],
                });
            };

            const currency = resultOrder?.currency || userInfo?.userProfile?.currency;
            setDataDetail({ ...resultOrder, currency: currency });

            setDataMapUser(newDataUser);
            setDataBooking(newDataBooking.dataBooking);
            setDataMapOrderDetail(newDataMapOrderDetails);
            setDataMapOrderHistories(newDataMapOrderHistories);
            setDataMapExtraInfoBooking(newDataBooking.newDataMap);

            setDataOrganization({
                id: resultOrg[0]?.id,
                email: itemOrgProfile?.email,
                code: resultOrg[0]?.organizationCode,
                name: resultOrg[0]?.name?.value?.["vi"],
                phoneNumber: itemOrgProfile?.phoneNumber,
                createUser: newDataUser.get(resultOrg[0]?.createUser),
            });
        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            onError(e);
        } finally {
            onHideLoading();
        }
    };

    const getExtraInfoDetailBookingByOrderId = async (orderId: string): Promise<{
        newDataMap: Map<string, string>,
        dataBooking: IDetailBooking | undefined,
    }> => {
        try {
            const newDataMap: Map<string, string> = new Map();

            const result = await requestBookingService.getDetailByOrderId(orderId);

            for (const element of [...result?.bookingDetails || []]) {
                newDataMap.set(element.id, element.extraInfo);
            };

            return { dataBooking: result, newDataMap };
        } catch (error) {
            return { dataBooking: undefined, newDataMap: new Map() };
        }
    };

    const converArrayObjToMapByOrderDetail = (props: {
        data: IOrderDetail[],
        orderType: number,
        newMap: Map<string, IOrderDetailExtends>,
    }): Map<string, IOrderDetailExtends> => {
        for (const element of props.data) {
            props.newMap.set(element.id, {
                ...element,
                orderType: props.orderType,
            });
        };
        return props.newMap;
    };

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(dataDetail?.id)
            && (dataDetail?.id !== props.idDetail)) {
            document.getElementById(props.idDetail).scrollIntoView();
        }
    }, [dataDetail?.id, props.idDetail]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Button color={"secondary"} onClick={() => { props.onGoBack(); }}>
                            {Strings.Common.GO_BACK}
                        </Button>
                    </Box>
                </Grid>
                {dataDetail?.id &&
                    <Grid item xs={12} sm={12} md={7} lg={8}>
                        {
                            [dataDetail, ...dataDetail?.subOrder || []].map((itemDetail, index) => (
                                <Card id={itemDetail?.id} key={index} sx={{ mb: 3 }}>
                                    <Grid container spacing={2} padding={3}>
                                        <Grid item xs={12}>
                                            <CardBasicBookingOrder
                                                index={index}
                                                dataBooking={dataBooking}
                                                orderCode={itemDetail?.orderCode}
                                                orderDate={itemDetail?.orderDate}
                                                paymentStatus={itemDetail?.paymentStatus}
                                                orderDetailType={itemDetail?.orderDetails?.[0]?.type}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CardItemBookingOrder
                                                orderType={itemDetail?.type}
                                                orderDetails={itemDetail?.orderDetails}
                                                paymentStatus={itemDetail?.paymentStatus}
                                                organizationId={dataDetail?.organizationId}
                                                currency={itemDetail?.currency || userInfo?.userProfile?.currency}

                                                dataMapOrderDetail={dataMapOrderDetail}
                                                dataMapExtraInfoBooking={dataMapExtraInfoBooking}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CardPaymentBookingOrder
                                                amount={itemDetail?.amount}
                                                paymentTypeList={paymentTypeList}
                                                serviceFee={itemDetail?.serviceFee}
                                                paymentFee={itemDetail?.paymentFee}
                                                paymentMethod={itemDetail?.paymentMethod}
                                                paymentStatus={itemDetail?.paymentStatus}
                                                serviceFeeTax={itemDetail?.serviceFeeTax}
                                                currency={itemDetail?.currency || userInfo?.userProfile?.currency}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            ))
                        }
                    </Grid>
                }
                {dataDetail?.id &&
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={12} lg={12}>
                                <CardCustomerBookingOrder {...dataOrganization} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} lg={12}>
                                <Card>
                                    <Box padding={2}>
                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {"Lịch sử đơn hàng"}
                                        </Typography>
                                        {
                                            Array.from(dataMapOrderHistories.keys()).map(key => (
                                                <CardHistoriesBookingOrder
                                                    orderCode={key}
                                                    dataMapUser={dataMapUser}
                                                    listOrderStatus={listOrderStatus}
                                                    dataOrderHistories={dataMapOrderHistories.get(key)}
                                                />
                                            ))
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </>
    );
};

export default BookingOrderDetailContainer;
