import Helpers from "commons/helpers";
import { IBookingDetail, IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import { useCallback, useState, useRef, useMemo } from "react";
import ServiceFeeSetting, { ICalculateServiceFeeBookingDetailRequest, IBookingDetailServiceFeeItem } from "services/sale/serviceFeeSetting";
import { BookingHelpers } from "commons/bookingHelpers";
import { PaymentType } from "constants/enum";
export interface IAdditionServiceCalculateServiceFeeItem {
    _id: string;
    checked: boolean;
    detailId: string;
    userId: string;
    quantity: number;
    unitPrice: number,
    fee: number
    otherFee?: number
}

const serviceFeeSetting = new ServiceFeeSetting();
const useLoadBookingDetailServiceFee = (data: IDetailBooking) => {
    const [serviceFeeByBookingDetailId, setServiceFeeByBookingDetailId] = useState<Record<string, IBookingDetailServiceFeeItem>>({});
    const totalServiceFee = useMemo(() => Object.values(serviceFeeByBookingDetailId).reduce((prev, c) => prev + c.fee, 0), [serviceFeeByBookingDetailId]);
    const [paymentFee, setPaymentFee] = useState<number>(0);
    const [isLoadingFee, setIsLoadingServiceFee] = useState(false);
    const { organizationId } = data;

    const latestServiceFeeIdRef = useRef<null | string>(null);
    const onLoadServiceFee = useCallback(
        async (items: IAdditionServiceCalculateServiceFeeItem[], paymentMethod: PaymentType, amount: number) => {
            const currentId = Helpers.generateRandomId();
            latestServiceFeeIdRef.current = currentId;
            try {
                setIsLoadingServiceFee(true);
                const serviceFeeResponse =
                    await serviceFeeSetting.calculateServiceFeeExtra({
                        bookingDetailRequest: getNewBookingDetailRequests(items),
                        organizationId: organizationId,
                        paymentMethod,
                        amount,
                    });
                if (latestServiceFeeIdRef.current !== currentId) {
                    // prevent update old service setting fee
                    return;
                }
                setServiceFeeByBookingDetailId(
                    serviceFeeResponse.bookingDetailServiceFeeResponse.reduce((c, n) => {
                        c[n.bookingDetailId] = n;
                        return c;
                    }, {} as Record<string, IBookingDetailServiceFeeItem>)
                );
                setPaymentFee(serviceFeeResponse.paymentFee);
                setIsLoadingServiceFee(false);
            } catch (e) {
                setIsLoadingServiceFee(false);
                // TODO: Do something when failed to load service fee
                if (latestServiceFeeIdRef.current !== currentId) {
                    // prevent update old service setting fee
                    return;
                }
                const err = Helpers.renderExceptionError(e);
                Helpers.showAlert(err, "error");
            }

            function getNewBookingDetailRequests(newChecked: IAdditionServiceCalculateServiceFeeItem[]) {
                const bookingDetailMapByBookingDetailId = data.bookingDetails.reduce((prev, c) => {
                    prev[c.id] = c;
                    return prev;
                }, {} as Record<string, IBookingDetail>);
                const detailRequestMapByDetailId = newChecked.reduce((prev, c) => {
                    const bookingDetailItem = bookingDetailMapByBookingDetailId[c.detailId];
                    const detailId = bookingDetailItem.id;
                    const { isInternational = true } = BookingHelpers.getFlightExtraInfo(bookingDetailItem, c.userId);
                    if (!prev[detailId]) {
                        prev[detailId] = {
                            bookingDetailId: bookingDetailItem.id,
                            bookingId: bookingDetailItem.bookingId,
                            quantity: 0,
                            amount: bookingDetailItem.amount,
                            unitPrice: bookingDetailItem.amount,
                            extraInformation: bookingDetailItem.extraInfo,
                            feeCode: undefined,
                            itemId: undefined,
                            isInternational,
                        }
                    }
                    prev[c.detailId].quantity += c.quantity;
                    return prev;
                }, {} as Record<string, ICalculateServiceFeeBookingDetailRequest>);
                return Object.values(detailRequestMapByDetailId);
            }
        },
        [organizationId, data.bookingDetails]
    );

    const [items, setItems] = useState<IAdditionServiceCalculateServiceFeeItem[]>([]);
    const onCalculateServiceFeeForRefund = useCallback(
        async (item: IAdditionServiceCalculateServiceFeeItem, paymentType: PaymentType) => {
            if (!item._id) {
                item._id = Helpers.generateRandomId();
            }

            let output = [] as typeof items;
            let mustRecalculateServiceFee = false;
            setItems((prev) => {
                output = prev;

                const currentItemIndex = prev.findIndex((c) => c._id === item._id);
                const existed = currentItemIndex > -1;
                if (existed) {
                    output = [...prev];
                    output[currentItemIndex] = { ...prev[currentItemIndex], ...item };
                    mustRecalculateServiceFee = prev[currentItemIndex].checked !== item.checked;
                } else {
                    output = [...prev, item];
                    mustRecalculateServiceFee = true;
                }
                return output;
            });

            if (!mustRecalculateServiceFee) {
                return;
            }
            const checkedOutput = output.filter((c) => c.checked);
            onLoadServiceFee(checkedOutput, paymentType, 0);
        },
        [onLoadServiceFee]
    );
    const onCalculateServiceFeeForAddAdditionalService = useCallback(
        async (
            updatedItem: Pick<IAdditionServiceCalculateServiceFeeItem, "userId" | "detailId" | "quantity">,
            paymentType: PaymentType,
            amount: number,
        ) => {
            const bookingId = updatedItem.detailId;
            let newChecked = [] as typeof items;
            const found = items.find((c) => c.detailId === bookingId && c.userId === updatedItem.userId);
            if (found) {
                if (updatedItem.quantity === 0) {
                    newChecked = items.filter((c) => c !== found);
                } else {
                    newChecked = items.map((c) => {
                        if (c === found) {
                            return { ...c, quantity: updatedItem.quantity };
                        }
                        return c;
                    });
                }
            } else {
                newChecked = [
                    ...items,
                    { quantity: updatedItem.quantity, checked: true, unitPrice: 0, fee: 0, userId: updatedItem.userId, detailId: bookingId, _id: Helpers.generateRandomId() },
                ];
            }
            if (newChecked.length === 0) {
                setServiceFeeByBookingDetailId({});
                setItems(newChecked);
                return;
            }
            setItems(newChecked);
            const checkedOutput = newChecked.filter((c) => c.checked);
            await onLoadServiceFee(checkedOutput, paymentType, amount);
        },
        [items, onLoadServiceFee]
    );
    const onCalculateServiceFeeForTransfer = useCallback(
        async (
            item: IAdditionServiceCalculateServiceFeeItem,
            paymentType: PaymentType,
            amount: number,
        ) => {
            let newChecked = items;
            const found = items.find((c) => c._id === item._id);
            if (found) {
                // newChecked = newChecked.filter(f => f !== found);
                newChecked = newChecked.map((c) => {
                    if (c._id === item._id) {
                        return { ...c, ...item };
                    }
                    return c;
                });
            } else {
                newChecked = [
                    ...items,
                    {
                        detailId: item.detailId,
                        userId: item.userId,
                        quantity: item.quantity,
                        unitPrice: item.unitPrice,
                        fee: item.fee,
                        otherFee: item.otherFee,
                        checked: item.checked,
                        _id: item._id
                    },
                ];
            }
            setItems(newChecked);
            if (newChecked.length === 0) {
                setServiceFeeByBookingDetailId({});
            } else {
                const checkedOutput = newChecked.filter((c) => c.checked);
                onLoadServiceFee(checkedOutput, paymentType, amount);
            }
        },
        [items, onLoadServiceFee]
    );

    const onRecalculateServiceFeeByPaymentMethod = useCallback(async (newPaymentMethod: PaymentType, amount: number) => {
        const checkedOutput = items.filter((c) => c.checked);
        await onLoadServiceFee(checkedOutput, newPaymentMethod, amount);
    }, [items, onLoadServiceFee]);

    return {
        loading: isLoadingFee,
        totalFee: totalServiceFee,
        totalPaymentFee: paymentFee,
        serviceFeeByBookingDetailId,

        checked: items,
        onCalculateServiceFeeForRefund,
        onCalculateServiceFeeForAddAdditionalService,
        onCalculateServiceFeeForTransfer,
        onRecalculateServiceFeeByPaymentMethod,
    };
};

export default useLoadBookingDetailServiceFee;
