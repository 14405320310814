import Helpers from "commons/helpers";
import Constants from "../../constants";
import IdentityService from "./identity.service";

class ProfileService extends IdentityService {
    public getUserInfo = async () => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.PROFILE.USER_INFO}`,
        });
        return result.data.result;
    };

    public getUserAuthorization = async (organizationId?: string) => {
        const result = await this.api.get({
            path: Constants.ApiPath.PROFILE.USER_AUTHORIZATION,
            query: Helpers.isNullOrEmpty(organizationId) ? undefined : { organizationId: organizationId },
        });
        return result.data.result;
    };

}

export default ProfileService;
