
import Helpers from "commons/helpers";
import Constants from "constants/index";
import BookingService from "./booking.service";

import { IGetPaged } from "commons/interfaces";
import { IDetailBooking, IRequestCreateBooking, IResponseUpdateBooking } from "components/Booking/useDataRequestBooking.hook";
import { ItineraryType } from "constants/enum";


export interface IReqGetPagedBooking {
    type?: number;
    buyer?: string;
    seller?: string;
    searchText?: string;
    confirmStatus?: string;
    organizationId?: string;
    pageSize?: number;
    pageNumber?: number;
    orderby?: string;
}

export interface IReqGetPagedBookingV2 {
    code?: string;
    type?: number;
    buyer?: string;
    seller?: string;
    status?: number[];
    expiryDate?: number;
    searchText?: string;
    requestStatus?: number;
    confirmStatus?: number;
    supplierCode?: string[];
    paymentStatus?: number[];
    bookingEndTime?: string | number;
    bookingStartTime?: string | number;

    pageSize?: number;
    pageNumber?: number;
    orderby?: string;
    organizationId?: string;
}

export interface IRecordBookingV2 {
    id: string
    type: number
    bookingCode: string
    bookingDetailType: number
    tenantCode: string
    serviceCode: string
    organizationId: string
    name: string
    phoneNumber: string
    email: string
    startTime: string
    endTime: string
    serviceFee: number
    totalAmount: number
    quoteAmount: number
    bookingQuantity: number
    currency: string
    description: string
    confirmStatus: number
    supplierCode: string
    extraInfo: any
    orderId: string;
    itineraryDetailConfirmStatus: number
    externalBookingCode: string
    needApprove: number
    paymentStatus: number
    bookingTime: string
    createTime: string
    createUser: string
    updateTime: string
    updateUser: string
}

export interface IRecordBooking {
    sellerName: string
    sellerPhoneNumber: string
    sellerEmail: string
    buyerName: string
    supplierName: string
    brandName: string
    brandAddress: string
    manufacturerName: string
    createUserName: string
    location: any
    organization: any
    bookingDetails: any
    type: number
    bookingCode: string
    tenantCode: string
    serviceCode: string
    organizationId: string
    buyer: string
    seller: string
    name: string
    phoneNumber: string
    email: string
    startTime: string
    endTime: string
    totalAmount: number
    quoteAmount: number
    bookingQuantity: number
    currency: string
    adultSlot: number
    childrenSlot: number
    childrenSlotDetail: any
    description: string
    confirmStatus: number
    orderId: string
    referenceCode: string
    supplierId: string
    supplierCode: string
    brandId: string
    manufacturerId: string
    extraInfo: string
    id: string
    status: number
    createTime: string
    createUser: string
    updateTime: string
    updateUser: string
}

export interface ICreateSubOrderRequest {
    bookingId: string;
    pricingType: number;
    paymentMethod: number;
    currency: string;
    amount: number;
    tax: number;
    discount: number;
    amendType: number;
    note: string;
    documents: {
        documentId: string;
        documentUrl: string;
    }[];
    bookingDetails: ICreateSubOrderRequestBookingDetail[];
    paymentFee: number;
    extraFee: number;
    extraFeeDetail: string;
}
interface ICreateSubOrderRequestBookingDetail {
    members: string[],
    type: ItineraryType;
    itemId: string;
    quantity: number;
    description: string;
    extraInformation: string;
    amount: number;
    tax: number;
    discount: number;
    promotion: number;
    unitPrice: number;
    specificTax: number;
    feeCode: string;
    serviceFee: number;
    serviceFeeTax: number;
    extraFee: number;
    extraFeeDetail: string; // IBookingDetailExtraFeeDetail
    bookingDetailId: string;
}
export interface IBookingDetailExtraFeeDetail {
    additionFee?: number;

    transferFee?: number;
    otherFee?: number;

    partialRefundFee?: number;
}
export interface ICreateSubOrderResponse { }

class RequestBookingService extends BookingService {

    public getPaged = async (data: IReqGetPagedBooking): Promise<IGetPaged<IRecordBooking>> => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.get({
            path: Constants.ApiPath.BOOKING.GET_PAGED + `?${query}`,
        });
        return result.data.result;
    };

    public getPagedV2 = async (data: IReqGetPagedBookingV2): Promise<IGetPaged<IRecordBookingV2>> => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.get({
            path: Constants.ApiPath.BOOKING.GET_PAGEDV2 + `?${query}`,
        });
        return result.data.result;
    };

    public getDetail = async (id: string): Promise<IDetailBooking> => {
        const result = await this.api.get({
            path: Constants.ApiPath.BOOKING.DETAIL + `/${id}`,
        });
        return result.data.result;
    };

    public getDetailByOrderId = async (id: string): Promise<IDetailBooking> => {
        const result = await this.api.get({
            path: Constants.ApiPath.BOOKING.DETAIL_BY_ORDER_ID + `/${id}`,
        });
        return result.data.result;
    };

    public create = async (data: IRequestCreateBooking): Promise<any> => {
        const result = await this.api.post({ path: Constants.ApiPath.BOOKING.CREATE, data, });
        return result.data.result;
    };

    public update = async (data: IResponseUpdateBooking): Promise<any> => {
        const result = await this.api.put({ path: Constants.ApiPath.BOOKING.UPDATE, data });
        return result.data.result;
    };

    public confirm = async (data: {
        id: string,
        possessionKey?: string
    }): Promise<string> => {
        const result = await this.api.put({ path: Constants.ApiPath.BOOKING.CONFIRM, data });
        return result.data.result;
    };

    public cancel = async (data: {
        id: string,
        possessionKey?: string
    }): Promise<any> => {
        const result = await this.api.put({ path: Constants.ApiPath.BOOKING.CANCEL, data });
        return result.data.result;
    };

    public delete = async (data: {
        id: string,
        possessionKey?: string
    }): Promise<any> => {
        const result = await this.api.delete({ path: Constants.ApiPath.BOOKING.DELETE, data });
        return result.data.result;
    };

    public exportCsv = async (data: IReqGetPagedBookingV2) => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.getExcel({
            path: Constants.ApiPath.BOOKING.EXPORT_CSV + `?${query}`
        });

        return new Blob([result.data]);
    };

    public exportBookingOrderCsv = async (data: {
        organizationId: string;
        bookingEndTime?: string | number;
        bookingStartTime?: string | number;
        type?: number;
        code?: string;
        searchText?: string;
        supplierCode?: string;
        paymentStatus?: number;
        requestStatus?: number;
    }) => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.getExcel({
            path: Constants.ApiPath.BOOKING.EXPORT_BOOKING_ORDER_CSV + `?${query}`
        });

        return new Blob([result.data]);
    };

    public createSubOrder = async (request: ICreateSubOrderRequest): Promise<ICreateSubOrderResponse> => {
        const result = await this.api.post({
            path: Constants.ApiPath.BOOKING.CREATE_SUBORDER,
            data: request,
        });
        return result.data.result;
    };

    public getIssuedByTargetCode = async (code: string): Promise<string> => {
        const result = await this.api.get({
            path: Constants.ApiPath.BOOKING.GET_ISSUED_BY_TARGET_CODE.concat(`/${code}`),
        });
        return result.data.result;
    };
};

export default RequestBookingService;
