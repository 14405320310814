import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ModuleStaff, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import Constants from "constants/index";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";
import { ITitleRoute } from "commons/interfaces";
import { setListPathName } from "store/slice/titleRoute.slice";


interface IModel {
    mode?: number;
    title?: string;
    route?: ITitleRoute[];
}

interface IRequestGetPage {
    orderby?: string;
    pageSize?: number;
    pageNumber?: number;
    totalCount?: number;
    searchText?: string;
    groupId?: string;
    fullName?: string;
    listStatus?: number[];
    roleCode?: string;
    isAll?: boolean;
}

const StaffScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const idUser = searchParams.get("id");
    const modeList = (Screens.STAFF_LIST.includes(window.location.pathname));
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const resourcePermissions = getResourcePermissions(Constants.ResourceUri.STAFF);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const itemPathName = listPathName.find(el => el.pathName === Screens.STAFF_LIST);

    const [model, setModel] = useState<IModel>({});
    const [dataRequest] = useState<IRequestGetPage>({
        groupId: searchParams.get("groupId"),
        roleCode: searchParams.get("roleCode"),
        searchText: searchParams.get("searchText"),
        listStatus: searchParams.getAll("listStatus").map(el => Number(el)),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
        totalCount: itemPathName?.totalCount,
    } as IRequestGetPage);

    useEffect(() => {
        if (Helpers.isNullOrEmpty(idUser)) {
            setModel({
                mode: Mode.Create,
                title: Strings.STAFF.TITLE_CREATE_VIEW,
                route: [
                    { title: Strings.STAFF.TITLE_MENU, route: Screens.STAFF_LIST },
                    { title: Strings.Common.ADD_NEW, route: "" },
                ],
            });
        } else {
            let mode = (pramsMode || Mode.Update);
            setModel({
                mode: mode,
                title: (mode === Mode.View) ? Strings.STAFF.TITLE_DETAIL_VIEW : Strings.STAFF.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.STAFF.TITLE_MENU, route: Screens.STAFF_LIST },
                    {
                        title: (mode === Mode.View) ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                        route: ""
                    },
                ],
            });
            navigate(Screens.STAFF_EDIT + `?id=${idUser}&mode=${mode}`, { replace: true });
        }
    }, [idUser, pramsMode]);

    const handleGoBack = () => {
        const itemP = listPathName.find(el => el.pathName === Screens.STAFF_LIST);
        navigate(Screens.STAFF_LIST + (itemP ? itemP.query : ""));
    };

    return (
        <DashboardLayout
            isPermission
            isRequiredOrganization
            title={modeList ? Strings.STAFF.TITLE_LIST_VIEW : model.title}
            route={modeList ? [{ title: Strings.STAFF.TITLE_MENU, route: "" }] : model.route}
        >
            <ModuleStaff
                hidenIdCardInfo

                hidenActionCreate
                hidenActionDeleteAllService
                hidenActionDelete={!resourcePermissions.canDelete}
                hidenActionUpdate={!resourcePermissions.canDelete}
                hidenActionInvitation={!resourcePermissions.canCreate}

                lableGroup={Strings.GROUP.TITLE_MENU}
                lableStaff={Strings.STAFF.TITLE_MENU}
                lableOrganization={Strings.ORGANIZATION.TITLE_MENU}

                keyModuleContainer={{
                    list: { pathName: Screens.STAFF_LIST },
                    detail: { pathName: Screens.STAFF_EDIT },
                    create: { pathName: Screens.STAFF_CREATE },
                }}
                requestGetPaged={dataRequest}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.STAFF_LIST, query, totalCount: totalCount }));
                    navigate(Screens.STAFF_LIST + query, { replace: true });
                }}
                onNavigate={(data) => {
                    if (data.id) {
                        navigate(Screens.STAFF_EDIT + `?id=${data.id}&mode=${data.mode}`);
                    } else {
                        navigate(Screens.STAFF_CREATE);
                    }
                }}

                idDetail={idUser}
                mode={model.mode}
                onGoBack={handleGoBack}
                onChangeMode={(value) => {
                    setModel({
                        mode: value,
                        title: (value === Mode.View)
                            ? Strings.STAFF.TITLE_DETAIL_VIEW : Strings.STAFF.TITLE_UPDATE_VIEW,
                        route: [
                            { title: Strings.STAFF.TITLE_MENU, route: Screens.STAFF_LIST },
                            {
                                title: (value === Mode.View)
                                    ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                                route: ""
                            },
                        ],
                    });
                    navigate(Screens.STAFF_EDIT + `?id=${idUser}&mode=${value}`, { replace: true });
                }}
            />
        </DashboardLayout>
    );
};

export default StaffScreen;
