import { Box, Typography } from "@maysoft/common-component-react";
import Helpers from "commons/helpers"
import { BookingDetailAmendType } from "constants/enum";
import usePaymentMethodCodenames from "hooks/usePaymentMethodCodenames";

const OperationHistoryOrderDataText = (props: { amount: number, currency: string, orderCode?: string, paymentMethod?: any, amendType: BookingDetailAmendType }) => {
    const paymentMethodCodenames = usePaymentMethodCodenames();
    const paymentMethodText = paymentMethodCodenames.find((item) => item.code === props.paymentMethod)?.name;

    return (
        <Box display="flex" flexDirection="row"alignItems="center" gap={1}>
            {props.orderCode && <Typography variant="button" textTransform="none">Mã đơn hàng&nbsp;{props.orderCode}</Typography>}
            {props.orderCode && <span>-</span>}

            <Typography variant="button" textTransform="none">Số tiền:&nbsp;{Helpers.formatCurrency(props.amount)} {props.currency}</Typography>

            {paymentMethodText && <span>-</span>}
            {paymentMethodText && <Typography variant="button" textTransform="none">Thanh toán&nbsp;{paymentMethodText.slice(0, 1).toLowerCase().concat(paymentMethodText.slice(1))}</Typography>}
        </Box>
    )
}

export default OperationHistoryOrderDataText