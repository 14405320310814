import Constants from "../../constants";
import SaleService from "./sale.service";

class ItemService extends SaleService {

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.ITEM.GET_PAGED}?${data}`
        });
        return result.data.result;
    }

}

export default ItemService;