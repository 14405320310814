import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ModulePartner, PartnerType } from "@maysoft/sale-common-react";

import Screens from "constants/screens";
import Constants from "../../constants";
import useModule from "hooks/useModule";

import { DashboardLayout } from "layout";

const CustomerScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),

        types: searchParams.getAll("loyaltyClasses")?.map((el) => Number(el)),
        loyaltyClasses: searchParams.getAll("loyaltyClasses")?.map((el) => Number(el)),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.CUSTOMER_LIST,
            detail: Screens.CUSTOMER_EDIT,
        },
        keyOfStrings: "CUSTOMER",
    });

    return (
        <DashboardLayout isPermission isRequiredOrganization {...{ title, route }}>
            <ModulePartner
                key={moduleProps?.mode}
                {...moduleProps}
                partnerType={PartnerType.Customer}
                pathNameOrderView={Screens.ORDER_EDIT}
                hiddenInput={{
                    hiddenInputEmail: false,
                    hiddenInputWebsite: false,
                    hiddenInputFaxNumber: false,
                    hiddenInputTaxNumber: false,
                    hiddenInputAddressId: false,
                }}
                requiredInput={{
                    requiredInputInviteCode: false,
                }}
            />
        </DashboardLayout>
    );
};

export default CustomerScreen;
