import { Box } from "@maysoft/common-component-react";
import { CircularProgress } from "@mui/material";
import Helpers from "commons/helpers";
import { BookingDetailAmendType, ItineraryType, OrderPricingType, PaymentType } from "constants/enum";
import { useState } from "react";
import RequestBookingService, { IBookingDetailExtraFeeDetail, ICreateSubOrderRequest } from "services/booking/requestBooking.service";
import PaymentMethodSelector from "../components/PaymentMethodSelector";
import { IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import { IBookingDetailServiceFeeItem } from "services/sale/serviceFeeSetting";
import { IBookingDetailFlightAmendServicePopupExtraInfo, IService, ITransferBookingDetailViewModel } from "../../interfaces";
import Strings from "constants/strings";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IMultiLang } from "commons/interfaces";
import moment from "moment";
import { ITransportHubServiceItemExtraInfo } from "services/common/transportHub.service";
import { BookingHelpers } from "commons/bookingHelpers";
import { IAdditionServiceCalculateServiceFeeItem } from "./useLoadServiceFee";

const bookingService = new RequestBookingService();
export interface IUseBookingDetailPopupSubmitProps {
    onCompleted?: () => Promise<void>;
    data: IDetailBooking;
}
const useBookingDetailSubmit = ({ onCompleted, data }: IUseBookingDetailPopupSubmitProps) => {
    const { userProfile, listOrganization } = useSelector((state: RootState) => state.userInfo);
    const organizationId = userProfile?.organizationId;
    const currentOrganizationData = listOrganization.find(o => o.code === organizationId);
    const organizationLocation = currentOrganizationData?.detail?.location;
    const timezone = moment.tz.guess();

    const contact = BookingHelpers.getBookingContactInfo(data.bookingDetails);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [commonProperties, setCommonProperties] = useState<{
        documents: { documentId: string; documentUrl: string }[];
        paymentType: PaymentType;
    }>({
        documents: [],
        paymentType: PaymentType.Debt,
    });

    const handleRefundSubmit = async ({
        selectedItems, serviceFeeByBookingDetailId,
    }: { selectedItems: IAdditionServiceCalculateServiceFeeItem[], serviceFeeByBookingDetailId: Record<string, IBookingDetailServiceFeeItem> }) => {
        const { documents, paymentType } = commonProperties;
        const totalServiceFee = Object.values(serviceFeeByBookingDetailId).reduce((prev, c) => prev + c.fee, 0);
        const totalPartialRefundUnitPrice = selectedItems.reduce((prev, c) => prev + (c.unitPrice - c.fee) || 0, 0);
        const totalPartialRefundAmount = totalPartialRefundUnitPrice - totalServiceFee;

        try {
            setIsSubmitLoading(true);

            const documentsRequest = paymentType === PaymentType.Cash ? documents : [];
            const totalPartialRefundExtraFee = selectedItems.reduce((prev, c) => prev + c.fee, 0);
            await bookingService.createSubOrder({
                bookingId: data.id,
                amendType: BookingDetailAmendType.PartialRefund,
                paymentFee: 0,
                extraFee: totalPartialRefundExtraFee,
                extraFeeDetail: JSON.stringify({ partialRefundFee: totalPartialRefundExtraFee } as IBookingDetailExtraFeeDetail),
                bookingDetails: selectedItems.map(item => {
                    const bookingDetail = data.bookingDetails.find(detail => detail.id === item.detailId);
                    const serviceFee = serviceFeeByBookingDetailId[item.detailId];
                    const serviceFeePerItem = serviceFee.fee / serviceFee.quantity;
                    const serviceFeeTaxPerItem = serviceFee.feeTax / serviceFee.quantity;
                    const extraFeeDetail = { partialRefundFee: item.fee } as IBookingDetailExtraFeeDetail;
                    const amount = item.unitPrice - item.fee - serviceFeePerItem;
                    const userId = item.userId;

                    return {
                        members: [userId],
                        amount: amount,
                        description: undefined,
                        discount: 0,
                        promotion: 0,
                        extraFee: item.fee,
                        extraFeeDetail: JSON.stringify(extraFeeDetail),
                        extraInformation: JSON.stringify({
                            users: [{ id: userId, flight: { ticketNumber: BookingHelpers.getBookingTicketNumber(bookingDetail, userId), reservationCode: BookingHelpers.getBookingReservationCode([bookingDetail], userId)  } }],
                            contact: contact,
                            timezone,
                        } as IBookingDetailFlightAmendServicePopupExtraInfo),
                        feeCode: serviceFee.feeCode,
                        itemId: bookingDetail.itemId,
                        quantity: 1,
                        serviceFee: serviceFeePerItem,
                        serviceFeeTax: serviceFeeTaxPerItem,
                        specificTax: 0,
                        tax: 0,
                        type: bookingDetail.type,
                        unitPrice: item.unitPrice,
                        bookingDetailId: bookingDetail.id,
                    };
                }) as ICreateSubOrderRequest["bookingDetails"],
                paymentMethod: paymentType,
                documents:
                    documentsRequest.length > 0 ? documentsRequest : undefined,

                pricingType: OrderPricingType.External,
                currency: data.currency,
                amount: totalPartialRefundAmount,
                discount: 0,
                tax: 0,
                note: undefined,
            });
            Helpers.showAlert(Strings.BOOKING.REFUND_POPUP_SUCCESS, "success");
            onCompleted && await onCompleted();
        } catch (error) {
            const err = Helpers.renderExceptionError(error);
            Helpers.showAlert(err, "error");
        } finally {
            setIsSubmitLoading(false);
        }
    };
    const handleAdditionalServicesSubmit = async ({ paymentFee = 0, services, serviceFeeByBookingDetailId, amount, getItemNameByItemId }: { services: IService[], serviceFeeByBookingDetailId: Record<string, IBookingDetailServiceFeeItem>, amount: number, getItemNameByItemId: (id: string) => IMultiLang, paymentFee?: number }) => {
        const { documents, paymentType } = commonProperties;
        try {
            setIsSubmitLoading(true);

            const documentsRequest = paymentType === PaymentType.Cash ? documents : [];
            const bookingDetails = services.map((service) => {
                const bookingDetailId = service.bookingDetailId;
                const bookingDetail = data.bookingDetails.find(d => d.id === bookingDetailId);

                const itemId = service.itemId;
                const currentServiceFee = serviceFeeByBookingDetailId[service.bookingDetailId];
                const serviceFeePerItem = currentServiceFee.fee / currentServiceFee.quantity;
                const serviceFeeTaxPerItem = currentServiceFee.feeTax / currentServiceFee.quantity;

                return {
                    members: [service.userId],
                    discount: 0,
                    promotion: 0,
                    extraFee: 0,
                    extraFeeDetail: JSON.stringify({ additionFee: 0 } as IBookingDetailExtraFeeDetail),
                    specificTax: 0,
                    tax: 0,
                    extraInformation: JSON.stringify({
                        users: [{ id: service.userId, flight: { ticketNumber: BookingHelpers.getBookingTicketNumber(bookingDetail, service.userId), reservationCode: BookingHelpers.getBookingReservationCode([bookingDetail], service.userId) } }],
                        contact,
                        itemName: getItemNameByItemId(service.itemId)?.value,
                        timezone,
                    } as IBookingDetailFlightAmendServicePopupExtraInfo),

                    amount: service.unitPrice + serviceFeePerItem,
                    serviceFee: serviceFeePerItem,
                    serviceFeeTax: serviceFeeTaxPerItem,

                    unitPrice: service.unitPrice,
                    description: service.description,
                    feeCode: currentServiceFee.feeCode,
                    itemId: itemId,
                    quantity: 1,
                    type: ItineraryType.Flight,
                    bookingDetailId: bookingDetailId,
                }
            });
            await bookingService.createSubOrder({
                bookingId: data.id,
                amendType: BookingDetailAmendType.AdditionService,
                paymentFee,
                extraFee: 0,
                extraFeeDetail: JSON.stringify({ additionFee: 0 } as IBookingDetailExtraFeeDetail),
                bookingDetails: bookingDetails,

                paymentMethod: paymentType,
                documents:
                    documentsRequest.length > 0 ? documentsRequest : undefined,

                pricingType: OrderPricingType.External,
                currency: data.currency,
                amount: amount,
                discount: 0,
                tax: 0,
                note: undefined,
            });
            Helpers.showAlert(Strings.BOOKING.ADDITION_SERVICE_POPUP_SUCCESS, "success");
            onCompleted && await onCompleted();
        } catch (error) {
            const err = Helpers.renderExceptionError(error);
            Helpers.showAlert(err, "error");
        } finally {
            setIsSubmitLoading(false);
        }
    };
    const handleTransferSubmit = async ({ paymentFee = 0, transferItems, serviceFeeByBookingDetailId, amount }: { transferItems: ITransferBookingDetailViewModel[], serviceFeeByBookingDetailId: Record<string, IBookingDetailServiceFeeItem>, amount: number, paymentFee?: number }) => {
        let totalExtraFeeTransferFee = 0; 
        let totalExtraFeeOtherFee = 0;

        const bookingDetails: ICreateSubOrderRequest["bookingDetails"] = transferItems.map((item) => {
            const { bookingDetailId } = item;
            const serviceFeeResponse = serviceFeeByBookingDetailId[bookingDetailId];
            const serviceFeePerItem = serviceFeeResponse.fee / serviceFeeResponse.quantity;
            const serviceFeeTaxPerItem = serviceFeeResponse.feeTax / serviceFeeResponse.quantity;

            totalExtraFeeOtherFee += item.extraFeeOtherFee || 0;
            totalExtraFeeTransferFee += item.extraFeeTransferFee || 0;
            const extraFee = (item.extraFeeTransferFee || 0) + (item.extraFeeOtherFee || 0);
            const extraFeeDetail = { transferFee: item.extraFeeTransferFee, otherFee: item.extraFeeOtherFee } as IBookingDetailExtraFeeDetail;
            const unitPrice = item.unitPrice || 0;

            const arrivalPlaceLocation = item.arrivalPlaceObj.detail.countryCode;
            const departPlaceLocation = item.departPlaceObj.detail.countryCode;

            const departPlaceExtraInfo = Helpers.converStringToJson(item.departPlaceObj.detail.extraInfo) as ITransportHubServiceItemExtraInfo;
            const arrivalPlaceExtraInfo = Helpers.converStringToJson(item.arrivalPlaceObj.detail.extraInfo) as ITransportHubServiceItemExtraInfo;

            const extraInfoData = {
                users: [
                    {
                        id: item.userId,
                        flight: {
                            airlineObj: item.airlineObj,
                            arrivalPlaceObj: {
                                code: item.arrivalPlaceObj.code,
                                name: item.arrivalPlaceObj.name,
                                cityName: arrivalPlaceExtraInfo.CityName,
                                countryName: arrivalPlaceExtraInfo.CountryName,
                            },
                            arrivalTime: moment(item.arrivalTime * 1000).utc(true).unix(),
                            baggage: item.baggage,
                            cabinClass: item.cabinClass,
                            departPlaceObj: {
                                code: item.departPlaceObj.code,
                                name: item.departPlaceObj.name,
                                cityName: departPlaceExtraInfo.CityName,
                                countryName: departPlaceExtraInfo.CountryName,
                            },
                            departTime: moment(item.departTime * 1000).utc(true).unix(), // item.departTime is unix timestamp (+0)
                            seatNumber: item.seatNumber,
                            ticketNumber: item.ticketNumber,
                            reservationCode: item.reservationCode,
                            isInternational: !(arrivalPlaceLocation === departPlaceLocation && organizationLocation === arrivalPlaceLocation),
                        },
                    }],
                timezone,
                contact,
            } as IBookingDetailFlightAmendServicePopupExtraInfo;

            return {
                members: [item.userId],
                discount: 0,
                promotion: 0,
                extraFee,
                extraFeeDetail: JSON.stringify(extraFeeDetail),
                specificTax: 0,
                tax: 0,
                extraInformation: JSON.stringify(extraInfoData),

                amount: unitPrice + serviceFeePerItem + extraFee,
                serviceFee: serviceFeePerItem,
                serviceFeeTax: serviceFeeTaxPerItem,

                unitPrice,
                description: undefined,
                feeCode: serviceFeeResponse.feeCode,
                itemId: undefined,
                quantity: 1,
                type: ItineraryType.Flight,
                bookingDetailId: bookingDetailId,
            }
        })

        const totalTransferExtraFee = totalExtraFeeTransferFee + totalExtraFeeOtherFee;
        try {
            setIsSubmitLoading(true);
            await bookingService.createSubOrder({
                bookingId: data.id,
                amendType: BookingDetailAmendType.Transfer,
                paymentFee,
                extraFee: totalTransferExtraFee,
                extraFeeDetail: JSON.stringify({ transferFee: totalExtraFeeTransferFee, otherFee: totalExtraFeeOtherFee } as IBookingDetailExtraFeeDetail),
                bookingDetails: bookingDetails,

                paymentMethod: commonProperties.paymentType,
                documents:
                    commonProperties.documents.length > 0 ? commonProperties.documents : undefined,

                pricingType: OrderPricingType.External,
                currency: data.currency,
                amount: amount,
                discount: 0,
                tax: 0,
                note: undefined,
            });
            Helpers.showAlert(Strings.BOOKING.TRANSFER_POPUP_SUCCESS, "success");
            onCompleted && await onCompleted();
        } catch (error) {
            console.error(error)
            const err = Helpers.renderExceptionError(error);
            Helpers.showAlert(err, "error");
        } finally {
            setIsSubmitLoading(false);
        }
    }

    const renderPaymentContent = ({ title, onChangePaymentType }: { title: string, onChangePaymentType?: (paymentType: PaymentType) => void }) => {
        return <PaymentMethodSelector
            title={title}
            value={commonProperties.paymentType}
            onChange={(value) => {
                setCommonProperties({ ...commonProperties, paymentType: value })
                onChangePaymentType?.(value);
            }}
        // renderChilrenByPaymentMethod={(paymentType) => {
        //     return paymentType === PaymentType.Cash && (
        //         <FileUploader
        //             // ="Tài liệu đính kèm"
        //             defaultValue={commonProperties.documents.map(d => ({ fileId: d.documentId, fileUrl: d.documentUrl }))}
        //             multiple
        //             onChange={(fileUploadRequest) => setCommonProperties({ ...commonProperties, documents: fileUploadRequest.map(f => ({ documentId: f.fileId, documentUrl: f.fileAccessUrl || f.fileUrl })) })}
        //         />
        //     )
        // }}
        />
    }

    return {
        renderLoadingContent: () => <SubmitLoadingCircular />,
        renderPaymentContent,
        paymentMethod: commonProperties.paymentType,
        loading: isSubmitLoading,
        onRefundSubmit: handleRefundSubmit,
        onAddAdditionServicesSubmit: handleAdditionalServicesSubmit,
        onTransferSubmit: handleTransferSubmit,
        commonProperties,
    };
}

export default useBookingDetailSubmit;

const SubmitLoadingCircular = () => {
    return (
        <Box display="flex" alignItems={"center"} justifyContent="center" p={3}>
            <CircularProgress size={32} color="primary" />
        </Box>
    );
};