/* eslint-disable import/no-anonymous-default-export */
// =============================================================================
// Lang for Vietnamese
// ==================================

export default {
    App: {
        TITLE: "Booking Admin",
    },

    Languages: {
        VI: "Tiếng Việt",
        EN: "Tiếng Anh",
    },

    Home: {
        TITLE: "Trang chủ",
        WELLCOME_TO_SALE_SERVICE: "WELLCOME TO SALE SERVICE!",
        CREATE_ORGANIZATION: "Tạo công ty",
        TOTAL_WORKING_HOURS: "Total working hours",
        TOTAL_ABSENCE_HOURS: "Total absence hours",
    },

    ORGANIZATION_MANAGE: {
        TITLE_MENU: "Quản lý công ty",
        TITLE_MENU_ORGANIZATION: "Công ty",
        TITLE_MENU_GROUP: "Phòng ban",
        TITLE_MENU_STAFF: "Nhân sự",
        LIST_ORGANIZATION: "Danh sách công ty",
        NO_ORGANIZATION: "Không chọn",
        ADD_ORGANIZATIONS: "Thêm công ty",
        ADD_USERS: "Thêm người dùng",
    },

    PROFILE: {
        TITLE_MENU: "Thông tin cá nhân",
        BASIC_INFO: "Thông tin cơ bản",
        PROFILE: "Hồ sơ",

        // Update email
        UPDATE_EMAIL: "Cập nhật email",
        UPDATE: "Cập nhật",
        NEW_EMAIL: "Email mới",
        ENTER_NEW_EMAIL: "Nhập email mới",
        GET_OTP_CODE: "Lấy mã xác thực",
        INPUT_OTP_CODE: "Nhập mã xác thực",

        // OTPResendCountDownButton
        SEND_TO: "Gửi lại",
        SEND_TO_AFTER: "Gửi lại sau",
        OTP_CODE: "Mã OTP",
        ENTER_OTP_CODE: "Nhập mã OTP",

        // UpdatePasswordSection
        UPDATE_PASSWORD: "Cập nhật mật khẩu",
        OLD_PASSWORD: "Mật khẩu cũ",
        NEW_PASSWORD: "Mật khẩu mới",
        CONFIRM_NEW_PASSWORD: "Xác nhận mật khẩu mới",
        ENTER_OLD_PASSWORD: "Nhập mật khẩu cũ",
        ENTER_NEW_PASSWORD: "Nhập mật khẩu mới",
        ENTER_CONFIRM_NEW_PASSWORD: "Nhập xác nhận mật khẩu mới",
        CONFIRM_NEW_PASSWORD_VALIDATE: "Nhập xác nhận mật khẩu không khớp",
        DEVICE_VERIFICATION: "Xác minh thiết bị",
        CONFIRM_DEVICE_VERIFICATION: "Chúng tôi vừa gửi mã xác nhận gồm 4 ký tự tới email {0} của bạn. Vui lòng nhập nó vào ô bên dưới",

        // User profile view
        FULL_NAME: "Họ và tên",
        ENTER_FULL_NAME: "Nhập họ và tên",
        EMAIL: "Email",
        ENTER_EMAIL: "Nhập Email",
        PHONE_NUMBER: "Số điện thoại",
        ENTER_PHONE_NUMBER: "Nhập số điện thoại",
        GENDER: "Giới tính",
        SELECT_GENDER: "Chọn giới tính",
        BIRTHDATE: "Ngày sinh",
        SELECT_BIRTHDATE: "Chọn ngày sinh",
        SELECT_EMPLOYMENT_DATE: "Chọn ngày vào làm",
        EMPLOYMENT_DATE: "Ngày vào làm",
        TERMINATIONT_DATE: "Ngày nghỉ làm",
        ENTER_TERMINATIONT_DATE: "Chọn ngày nghỉ làm",
        IDENTITY_ID: "CMND/CCCD",
        IDENTITY_ID_ISSUED_DATE: "Ngày cấp",
        IDENTITY_ID_ISSUED_PLACE: "Nơi cấp",
        ENTER_IDENTITY_ID: "Nhập CMND/CCCD",
        SELECT_IDENTITY_ID_ISSUED_DATE: "Chọn ngày cấp",
        ENTER_IDENTITY_ID_ISSUED_PLACE: "Nhập nơi cấp",
        SOCIAL_INSURANCE_CODE: "Mã bảo hiểm xã hội",
        ENTER_SOCIAL_INSURANCE_CODE: "Nhập mã bảo hiểm xã hội",

        USER_INFORMATION: "Thông tin người dùng",
        UPDATE_USER_INFORMATION: "Cập nhật thông tin người dùng",
        USER_AVATAR: "Ảnh đại diện",
        UPLOAD_AVT_COMPLETE: "Cập nhật ảnh thành công. Vui lòng chờ đợi trong giây lát!",
        UPLOAD_AVT_ERROR: "Đã có phát sinh lỗi trong quá trình xử lý. Tải ảnh lên không thành công!",

        USER_CONTACT: "Thông tin liên hệ",
        DEFAULT_CONTACT: "Mặc định",
        ROLE: "Quyền",
        DONT_CHOOSE: "Không chọn",
        ORGANIZATION: "Công ty",
        ORGANIZATION_LIST: "Danh sách công ty",
        CREATE_ORGANIZATION: "Tạo mới công ty",
    },

    Common: {
        WEB: "Web",
        NEXT: "Tiếp theo",
        PREVIEW: "Xem trước",
        APP_MOBILE: "App Mobile",
        SELECT: "Chọn",
        COMMON: "Dùng chung",
        CONTINUE: "Tiếp tục",
        CANCEL: "Hủy",
        CANCELED: "Đã hủy",
        CANCEL_SELECT: "Bỏ chọn",
        DISPLAY: "Hiển thị",
        TOTAL: "Tổng",
        NOTIFICATION: "Thông báo",
        OK: "Ok",
        SAVE: "Lưu",
        SAVE_TEMP: "Lưu tạm",
        COMPLETE: "Hoàn thành",
        WAIT_TEST: "Chờ kiểm tra",
        PROCESSING: "Đang xử lý",
        UPDATE: "Cập nhật",
        IN_ACTIVE: "Ngưng hoạt động",
        ACTIVE: "Đang hoạt động",
        EMPTY_DATA: "Chưa có dữ liệu",
        NO_DATA: "Không có dữ liệu",
        ADD_NEW: "Thêm mới",
        ADD_IMAGE: "Thêm hình ảnh",
        GO_BACK: "Trở về",
        EXIT: "Thoát",
        CREATE_NEW: "Tạo mới",
        VIEW_ALL: "Xem tất cả",
        EDIT: "Chỉnh sửa",
        LOCK: "Khóa",
        UNBLOCK: "Mở khóa",
        DELETE: "Xóa",
        REPLACE: "Thay thế",
        UPLOAD: "Tải lên",
        UPLOAD_IMAGE: "Tải ảnh lên",
        SEARCH: "Tìm kiếm",
        SEE: "Xem",
        ACTION: "Thao tác",
        MILLION: "Tr",
        BILLION: "Tỷ",
        UNCONFIRM: "Hủy bỏ",
        FILTER: "LỌC",
        FILTER_LIST: "Lọc danh sách",
        RESET: "Hủy lọc",
        TYPE: "Loại",
        PHONE_NUMBER: "Số điện thoại",
        ENTER_PHONE_NUMBER: "Nhập số điện thoại",
        CODE: "Mã",
        INPUT: "Nhập",
        INPUT_NOTE: "Nhập ghi chú",
        EMAIL: "Email",
        DAY: "Ngày",
        HOUR: "Giờ",
        // TABLE
        LABEL_DISPLAY_ROWS: "{0} - {1} trên {2}",
        DOWN: "Tải về",
        DETAIL: "Chi tiết",
        APPROVE: "Phê duyệt",
        REJECT: "Từ chối",
        GENDER: "Giới tính",
        TIME: "Thời gian",
        START_TIME: "Thời gian bắt đầu",
        END_TIME: "Thời gian kết thúc",
        DISABLE: "Vô hiệu hóa",
        STATUS: "Trạng thái",
        COPY: "Sao chép",
        IMPORT: "Import",
        EXPORT: "Export",
        CITY: "Thành phố",
        PROVINCE: "Tỉnh thành",
        SELECT_PROVINCE: "Chọn tỉnh thành",
        DISTRICT: "Quận/huyện",
        SELECT_DISTRICT: "Chọn quận/huyện",
        WARDS: "Phường/xã",
        SELECT_WARDS: "Chọn phường/xã",
        SEARCH_TEXT: "Tìm kiếm",
        ORGANIZATION: "Công ty",
        NO_ORGANIZATION: "Không thuộc công ty nào",
        LIST_ORGANIZATION: "Danh sách công ty",
        ACTION_WITH_EXCEL_FILE: "Thao tác excel",
        EXPORT_EXCEL: "Xuất excel",
        IMPORT_EXCEL: "Nhập excel",
        SELECT_FILE_FROM_DEVIDE: "Chọn tệp từ thiết bị",
        INVALID_DATA: "Dữ liệu không hợp lệ",
        FIELD_REQUIRED: "Trường bắc buộc",
        ACCEPT: "Đồng ý",
        ATTENTION: "Chú ý",
        MALE: "Nam",
        FEMALE: "Nữ",
        OTHER: "Khác",
        ALL: "Tất cả",
        CHANGE_STATUS: "Đổi trạng thái",
        CONFIRM: "Xác nhận",
        DATE_FORMAT: "vi",
        CLOSE: "Đóng",
        PRINT: "In",
        EXPORT_PDF: "Xuất PDF",
        ADDRESS_LINE: "Địa chỉ",
        ADDRESS_LINE_PHD: "Nhập địa chỉ",
        NAME: "Họ và tên",
        NOTE: "Ghi chú",
        BIRTHDATE: "Sinh nhật",
        DATE_OF_BIRTH: "Ngày sinh",
        APPLY: "Áp dụng",
        NOT_APPLY: "Ngừng áp dụng",
        VIEW: "Xem chi tiết",
        HERE: "Tại đây",
        UPLOAD_FILE: "Tải tệp lên",
        LOAD_MORE: "Xem thêm",
        USE_ALL_SYSTEM: "Sử dụng toàn hệ thống",
        USE: "Sử dụng",
        FAX_NUMBER: "Số Fax",
        TAX_NUMBER: "Số Tax",
        FROM_DATE: "Ngày bắt đầu",
        TO_DATE: "Ngày kết thúc",
        FROM: "Từ",
        TO: "Đến",
        REJECTED: "Đã từ chối",
        APPROVED: "Đã duyệt",
        WAITING: "Đang chờ duyệt",
        DRAFT: "Nháp",
        DELETED: "Đã xóa",
        BLOCKED: "Đã khóa",
        PAUSE: "Tạm dừng",
        YES: "Có",
        NO: "Không",
        UPLOAD_FILE_FROM_LOCAL: "Tải lên từ máy tính",
        DOWNLOAD: "Tải về",
        IMAGE: "Hình ảnh",
        VIDEO: "Video",
        DOCUMENT: "Tài liệu",
        CHANGE: "Thay đổi",
        DROP_FILES_HERE: "Kéo thả file vào",
        CHOOSE_IMAGE: "Chọn ảnh",

        READ_MORE: "Xem thêm",
        COLLAPSE: "Thu gọn",
        EXPRIED: "Hết hạn",
        INFUTURE: "Chưa đến hạn",

        IndividualCustomer: "Khách hàng cá nhân",
        CompanyCustomer: "Khách hàng doanh nghiệp",
        CANCEL_ORDER: "Hủy đơn",
        SAVE_AND_PRINT: "Lưu và in",
        PRINT_ALL: "In tất cả",
        UNDER_MANAGEMENT: "Thuộc quản lý",
        SKIP: "Bỏ qua",
        UNREAD: "Chưa đọc",
        MARK_READ_ALL: "Đánh dấu đọc tất cả",
        LOADING: "Đang tải...",
        ADDRESS_INFO: "Thông tin địa chỉ",
        EXAMPLE: "VD",
        NO_NAME: "Không có tên",
        SERVICE_FEE: "Phí dịch vụ",
        PAYMENT_TYPE: "Phương thức thanh toán",
        SERVICE_FEE_TAX_INCLUDE: "Phí dịch vụ (đã bao gồm thuế)",
    },

    TRANSACTION: {
        TITLE_MENU: "Giao dịch",
    },

    AlertStatus: {
        ERROR: "error",
        WARNING: "warning",
        INFO: "info",
        SUCCESS: "success",
        QUESTION: "question",
    },

    Message: {
        ERROR: "Đã có phát sinh lỗi trong quá trình xử lý, vui lòng thử lại sau. Xin lỗi vì đã gây bất tiện.",
        SUCCESS: "Thao tác hành công",
        PLACEHOLDER: "Chọn...",
        CONFIRM_LOGOUT: "Bạn có muốn đăng xuất?",
        CONFIRM_CREATE: "Bạn có chắc muốn tạo?",
        CONFIRM_UPDATE: "Bạn có chắc muốn cập nhật?",
        CONFIRM_DELETE: "Bạn có thực sự muốn xóa?",
        CONFIRM_DELETE_ALL: "Bạn có thực sự muốn xóa tất cả?",
        CONFIRM_BLOCK: "Bạn có thực sự muốn khoá tài khoản?",
        CONFIRM_SUBMIT: "Bạn có thực sự muốn gửi?",
        CONFIRM_CANCEL: "Bạn có thực sự muốn hủy?",
        CONFIRM_CANCEL_ACTIVE: "Bạn có thực sự muốn hủy kích hoạt?",
        CONFIRM_IN_ACTIVE: "Bạn có thực sự muốn ngưng hoạt động?",
        CONFIRM_EXPORT_FILE: "Bạn có thực sự muốn tải tệp?",
        CONFIRM_APPROVE: "Bạn có thực sự muốn phê duyệt?",
        CONFIRM_REJECT: "Bạn có thực sự muốn từ chối?",
        CREATE_SUCCESS: "Tạo thành công",
        UPDATE_SUCCESS: "Cập nhật thành công",
        PAYMENT_SUCCESS: "Thanh toán thành công",
        UPDATE_STATUS_SUCCESS: "Cập nhật trạng thái thành công",
        EXPORT_FILE_SUCCESS: "Tải tệp thành công",
        DELETE_SUCCESS: "Xóa thành công",
        DELETE_ALL_SUCCESS: "Xóa tất cả thành công",
        BLOCK_SUCCESS: "Khoá thành công",
        CANCEL_SUCCESS: "Hủy thành công",
        APPROVE_SUCCESS: "Phê duyệt thành công",
        REJECT_SUCCESS: "Từ chối thành công",
        LOGIN_SUCCESS: "Đăng nhập thành công",
        LOGOUT_SUCCESS: "Đăng xuất thành công",
        CREATE_ERROR: "Tạo không thành công",
        BLOCK_ERROR: "Khoá không thành công",
        UPDATE_ERROR: "Cập nhật không thành công",
        EXPORT_FILE_ERROR: "Tải tệp không thành công",
        CANCEL_ERROR: "Hủy không thành công",
        APPROVE_ERROR: "Phê duyệt không thành công",
        REJECT_ERROR: "Từ chối không thành công",
        DELETE_ERROR: "Xóa không thành công",
        DELETE_ALL_ERROR: "Xóa tất cả không thành công",
        LOGIN_ERROR: "Đăng nhật không thành công",
        LOGOUT_ERROR: "Đăng xuất không thành công",
        EPR_TOKEN: "Phiên đăng nhập đã hết hạn!",
        PASSWORD_ERROR: "Tài khoản hoặc mật khẩu không đúng.",
        CREATE_USER_SUCCESS: "Thêm mới nhân sự thành công",
        PLEASE_COMPLETE_ALL_INFORMATION: "Vui lòng điền đầy đủ thông tin yêu cầu",
        INVALID_EMAIL: "Email không hợp lệ",
        INVALID_PHONENUMBER: "Số điện thoại không hợp lệ",
        CONFIRM_CHANGE_STATUS: "Bạn có chắc chắn muốn thay đổi trạng thái?",
        CHANGE_PHONE_NUMBER_SUCCESS: "Thay đổi số điện thoại thành công",
        CHANGE_PASSWORD_SUCCESS: "Thay đổi mật khẩu thành công",
        CHANGE_PROFILE_SUCCESS: "Thay đổi thông tin cá nhân thành công",
        NO_FUNCTION: "Xin lỗi! Hiện chưa có chức năng này!",
        CANCEL_ACTION_MESSAGE: "Thông tin chỉnh sửa chưa được lưu. Bạn chắc chắn muốn rời khỏi màn hình hiện tại không?",
        TOKEN_EXPIRED: "Hết hạn cho phiên đăng nhập! Vui lòng đăng nhập lại",
        USER_PROFILE_NOT_FOUND: "Không tìm thấy thông tin người dùng, vui lòng đăng nhập lại!",
        CONFIRM_EXPORT: "Bạn có muốn xuất file excel?",
        NOT_FORMAT_IMG: "Không đúng định dạng hình ảnh cho phép! (.png |.gif | .jpeg)",
        LIMIT_NUMBER_OF_PHOTO: "Giới hạn thêm tối thiểu là {0} ảnh và tối đa chỉ {1} ảnh",
        LIMIT_SIZE_OF_IMG: "Kích thước file lớn hơn {0}MB",
        NOT_FOUND: "Không tìm thấy dữ liệu!",
        NOT_DATA_PERMISSION: "Rất tiếc, bạn không có quyền thao tác trên dữ liệu này",
        NOT_PERMISSION: "Rất tiếc, bạn không có quyền truy cập. Vui lòng đăng nhập lại",
        NO_ROLE_UPDATE: "Bạn không có quyền cập nhật",
        NO_ROLE_DELETE: "Bạn không có quyền xóa",
        GROUP_ALREADY_EXIST: "Phòng ban đã tồn tại",
        PLEASE_ADD_PERMISSION: "Vui lòng thêm nhóm quyền",
        DONT_HAVE_ORGANIZATION: "Để sử dụng chức năng này vui lòng tạo hoặc ",
        UPLOAD_FILE_ERROR: "Upload không thành công",
        ORDER_AFTER_CANCELIATION_CANNOT_BE_RETURNED: "Đơn hàng sau khi hủy sẽ không thể lấy lại được!.",
        PLEASE_ENTER_THE_DISCOUNT_VALUE: "Vui lòng điền giá trị chiết khấu",
        CORRESPONDING_ORGANIZATION: " công ty tương ứng.",
        EMAIL_ALREADY_EXISTS: "Email đã tồn tại",
        PLEASE_SELECT_AN_ORGANIZATION: "Vui lòng chọn công ty",
        CHANGE_EMAIL_SUCCESS: "Thay đổi email thành công",
        NO_ADDRESS: "Chưa có thông tin, vui lòng thêm địa chỉ",
    },

    Validation: {
        EMAIL_ADDRESS: "Sai định dạng email",
        PHONE_NUMBER: "Sai định dạng số điện thoại",
        DATE_TIME: "Sai định dạng ngày tháng năm",
        USERNAME: "Tên tài khoản viết liền không dấu",
        REQUIRED: "Trường dữ liệu là bắt buộc",
        REQUIRED_NAME: "Vui lòng nhập họ và tên",
        REQUIRED_EMAIL: "Vui lòng nhập email",
        REQUIRED_USER_NAME: "Vui lòng nhập tên đăng nhập",
        REQUIRED_PASSWORD: "Vui lòng nhập mật khẩu",
        REQUIRED_CONFIRM_PASSWORD: "Vui lòng nhập lại mật khẩu",
        REQUIRED_IMAGE: "Vui lòng chọn ảnh",
        REQUIRED_ORGANIZATION: "Vui lòng chọn cửa hàng",
        REQUIRED_ADDRESS: "Vui lòng thêm địa chỉ",
        NOT_SAME: "Mật khẩu không trùng khớp",
        REQUIRED_NUMBER: "Vui lòng chỉ nhập số",
        LIMITED_SIZE: "Đã vượt quá độ dài cho phép",
        REQUIRED_FORM: "Vui lòng kiểm tra lại thông tin nhập",
        REQUIRED_AVATAR: "Vui lòng chọn ảnh đại diện",
        INVALID_TIME: "Thời gian không hợp lệ",
        ID_CARD: "Sai định dạng CMND/CCCD",
        REQUIRED_SPECIAL_CHARACTERS: "Trường dữ liệu không chứa các ký tự đặc biệt",
        ORDER_ITEM_REQUIRED: "Vui lòng chọn sản phẩm",
        ORDER_MONEY_REQUIRED: "Vui lòng nhập số tiền nhận từ khách hàng",
        ORDER_RECEIVE_MONEY_INVALID: "Số tiền nhận không được nhỏ hơn tổng tiền thanh toán",
        AMOUNT_INVALID: "Không được tạo đơn với số tiền âm",
        REQUIRED_CUSTOMER: "Vui lòng chọn khách hàng",
        REQUIRED_PRICE: "Vui lòng thiết lập giá",
        REQUIRED_CURRENCY_DEFAULT: "Vui lòng thêm đơn vị tiền tệ {0}",
        REQUIRED_CURRENCY: "Đơn vị tiền tệ {0} là bắt buộc",
        INVALID_CURRENCY_TIME: "Thời gian thiết lập không được nhỏ hơn thời gian hiện tại",
        FILTER_REQUIRED: "Vui lòng điền đầy đủ thông tin lọc",
    },

    Login: {
        CONFIRM_PASSWORD: "Nhập lại mật khẩu",
        REGISTER_SUCCESSFULL: "Đăng ký thành công",
        REGISTER_ERROR: "Đăng ký thấy bại. Hãy thử lại!",
        USER_NAME: "Tên đăng nhập",
        PASSWORD: "Mật khẩu",
        LOGOUT: "Đăng xuất",
        LOGIN: "Đăng nhập",
        REGISTER: "Đăng ký",
        REMEMBER_ME: "Ghi nhớ đăng nhập",
        FORGOT_PASSWORD: "Quên mật khẩu",
        USER_INFO: "Thông tin tài khoản",
        SELECT_ORGANIZATION: "Chọn công ty",
        ORGANIZATION: "Công ty",
        VERIFICATION_CODE: "Mã xác nhận",
        NEW_PASSWORD: "Mật khẩu mới",
        CONFIRM_NEW_PASSWORD: "Xác nhận mật khẩu mới",
    },

    Codename: {
        TITLE_MENU: "Danh mục dùng chung",
        TITLE_LIST_VIEW: "Danh sách danh mục",
        TITLE_UPDATE_VIEW: "Cập nhật thông tin danh mục",
        TITLE_DETAIL_VIEW: "Thông tin danh mục",
        TITLE_CREATE_VIEW: "Thêm mới danh mục",

        PARENT_GROUP_CODE: "000000",
        CODE: "Mã danh mục",
        ENTER_CODE: "Nhập mã danh mục",
        NAME: "Tên danh mục",
        INPUT_NAME: "Nhập tên danh mục",
        SHORT_NAME: "Tên viết tắt",
        INPUT_SHORT_NAME: "Nhập tên viết tắt",
        SEARCH_TEXT: "Tìm kiếm theo tên",
        ORGANIZATION_NAME: "Tên công ty",
        ALL_ORGANIZATIONS: "0",
        GROUPT_CODE_NAME: "Mã nhóm danh mục",
        GROUPT_NAME: "Nhóm danh mục",
        NAME_LIST: "Danh sách danh mục",
        CONFIRM_IN_ACTIVE: "Bạn có thực sự muốn ngưng hoạt động danh mục này?",
    },

    ATTRIBUTE: {
        TITLE_MENU: "Thuộc tính",
        TITLE_LIST_VIEW: "Danh sách thuộc tính",
        TITLE_CREATE_VIEW: "Thêm thuộc tính mới",
        TITLE_UPDATE_VIEW: "Cập nhật thuộc tính",
        TITLE_DETAIL_VIEW: "Thông tin thuộc tính",
    },

    ITEM: {
        TITLE_MENU: "Phòng khách sạn",
        TITLE_LIST_VIEW: "Danh sách phòng",
        TITLE_CREATE_VIEW: "Thêm phòng mới",
        TITLE_UPDATE_VIEW: "Cập nhật phòng",
        TITLE_DETAIL_VIEW: "Xem chi tiết phòng",
        SEARCH_TEXT: "Tìm kiếm theo mã, tên phòng",
        SAVE_PRINT_BARCOR: "Lưu và In mã vạch",
        VIEW_BRANCH_STORAGE: "Chi nhánh xem hàng",

        IMAGE: "Ảnh",
        ITEM_CODE: "Mã phòng",
        ITEM_NAME: "Tên phòng",
        BARCODE: "Barcode",
        ITEM_TYPE: "Loại sản phẩm",
        ITEM_GROUP: "Nhóm sản phẩm",
        BRAND: "Thương hiệu",
        ORIGIN: "Xuất xứ",
        EXPIRATION: "Kỳ hạn",
        INPUT_EXPIRATION: "Nhập kỳ hạn",
        SELECT_ORIGIN: "Chọn xuất xứ",
        BRAND_NAME: "Tên thương hiệu",
        INPUT_BRAND_NAME: "Nhập tên thương hiệu",
        INVENTORY: "Tồn kho",
        STOP_BUSINESS: "Ngừng kinh doanh",
        BUSINESS: "Đang kinh doanh",
        INFO_FILE: "Thông tin tệp",
        TOTAL_UPLOADS: "Tổng tải lên",
        TOTAL_SKU_CONFLICT: "Tổng SKU trùng",
        SKU_CONFLICT: "SKU trùng nhau:",
        TOTAL_SUCCESS: "Tổng SKU thành công",
        TOTAL_ERROR: "Tổng SKU lỗi",
        ATTENTION: "Chú ý",
        SELECT_ITEM_TYPE: "Chọn loại sản phầm",
        PRODUCT: "Mẫu phòng",
        SELECT_PRODUCT: "Chọn mẫu phòng",

        TYPE_ITEM: "Loại sản phẩm",
        BASIC_INFO: "Thông tin chung",
        SETTING_PRICE: "Thiết lập giá",
        ADD_SETTING_PRICE: "Thêm thiết lập giá",
        SETTING_STORAGE: "Thiết lập kho",
        DESCRIPTION_INFO: "Mô tả sản phẩm",
        ITEM_ATTRIBUTE: "Mô tả sản phẩm",
        SETTING_UNIT: "Thiết lập đơn vị quy đổi",
        // PRODUCT: "Sản phẩm",
        // SELECT_PRODUCT: "Chọn sản phẩm",
        COMBO_PRODUCT: "Gói sản phẩm",
        SELECT_COMBO_PRODUCT: "Chọn gói sản phẩm",
        COMBO_SERVICE: "Gói dịch vụ",
        SERVICE: "Dịch vụ",
        INPUT_ITEM_NAME: "Nhập tên sản phẩm",
        INPUT_ITEM_CODE: "Nhập mã sản phẩm hoặc hệ thống tự đặt",
        BAR_CODE: "Mã vạch",
        INPUT_BARCODE: "Sử dụng máy scan hoặc nhập tay",
        WEIGHT_UNIT: "Đơn vị cho trọng lượng",
        SELECT_WEIGHT_UNIT: "Chọn đơn vị cho trọng lượng",
        UNIT: "Đơn vị tính",
        UNIT_NAME: "Tên đơn vị",
        INPUT_UNIT_NAME: "Nhập tên đơn vị",
        SELECT_UNIT: "Chọn đơn vị tính",
        SUPPLIER: "Nhà cung cấp",
        SELECT_SUPPLIER: "Chọn nhà cung cấp",
        TAG: "Tag",
        INPUT_TAG: "Nhập tag",
        COST_PRICE: "Giá vốn",
        NAME_PRICE_TYPE: "Tên loại giá mới",
        INPUT_NAME_PRICE_TYPE: "Nhập tên loại giá mới",
        INPUT_COST_PRICE: "Nhập giá vốn",
        WHOLESALE_PRICE: "Giá bán sỉ",
        RETAIL_PRICE: "Giá bán lẻ",
        SALE_PRICE: "Giá bán",
        INPUT_SALE_PRICE: "Nhập giá bán",
        INPUT_WHOLESALE_PRICE: "Nhập giá bán sỉ",
        INPUT_RETAIL_PRICE: "Nhập giá bán lẻ",
        ADD_NEW_PRICE: "Thêm giá mới",
        NEW_PRICE_TYPE: "Thêm loại giá mới",
        DESCRIPTION: "Mô tả",
        INPUT_DESCRIPTION: "Nhập mô tả",
        WEIGHT: "Trọng lượng",
        INPUT_WEIGHT: "Nhập trọng lượng",
        SIZE_PRODUCT: "Kích thước sản phẩm",
        INPUT_SIZE_PRODUCT: "Nhập kích thước sản phẩm",
        ADD_ATTRIBUTE: "Thêm đặc tính",
        ATTRIBUTE_OTHER: "Đặc tính khác",
        ATTRIBUTE_NAME: "Tên đặc tính",
        INPUT_ATTRIBUTE_NAME: "Nhập tên đặc tính",
        VALUE: "Đơn vị tiền tệ",
        ATTRIBUTE_VALUE: "Giá trị đặc tính",
        INPUT_VALUE: "Nhập đơn vị tiền tệ",
        INPUT_ATTRIBUTE_VALUE: "Nhập giá trị đặc tính",
        HIGHEST_INVENTORY_LEVEL: "Định mức tồn kho cao nhất",
        MINIMUM_INVENTORY_LEVEL: "Định mức tồn kho thấp nhất",
        INPUT_LEVEL: "Nhập định mức",
        INPUT_INVENTORY: "Nhập tồn kho",
        BATCH_MANAGEMENT: "Quản lý lô",
        EXPIRY: "Hạn sử dụng",
        SHELFIFE_MANAGEMENT: "Quản lý ShelfLife",
        INPUT_SHELFIFE: "Nhập ShelfLife",
        DAY: "ngày",
        IMAGE_PRODUCT: "Hình ảnh sản phẩm",
        SELECT_ITEM_GROUP: "Chọn nhóm sản phẩm",
        SELECT_BRAND: "Chọn thương hiệu",
        SALES_PRICE: "Giá bán hàng",
        SALES_TAX: "Thuế bán hàng",
        IMPORT_TAX: "Thuế nhập hàng",
        APPLY_TAX: "Áp dụng thuế",
        INPUT_APPLY_TAX: "Nhập thuế áp dụng",
        CONVER_UNIT: "Đơn vị quy đổi",
        INPUT_CONVER_UNIT: "Nhập đơn vị quy đổi",
        ADD_CONVER_UNIT: "Thêm đơn vị quy đổi",
        AMOUNT: "Số lượng",
        INPUT_AMOUNT: "Nhập số lượng",
        GROUP_NAME: "Tên nhóm",
        INPUT_GROUP_NAME: "Nhập tên nhóm",
        GROUP_PARENT: "Nhóm cha",
        SELECT_GROUP_PARENT: "Chọn nhóm cha",

        ITEM: "Sản phẩm",
        COMBO: "Gói sản phẩm",
        START_TIME: "Thời gian bắt đầu",
        END_TIME: "Thời gian kết thúc",
        SELECT_START_TIME: "Chọn thời gian bắt đầu",
        SELECT_END_TIME: "Chọn thời gian kết thúc",
        INPUT_CODE_COMBO: "Nhập mã gói hoặc hệ thống tự đặt",
        COMBO_NAME: "Tên gói",
        INPUT_COMBO_NAME: "Nhập tên gói",
        ITEM_IN_COMBO: "Thành phần trong gói",
        SEARCH_ITEM_IN_COMBO: "Tìm kiếm tên sản phẩm mẫu",
        TOTAL_PRICE_IN_COMBO: "Tổng tiền trong gói",
        TOTAL: "Tổng cộng",
        PRICE: "Giá",
        LIST_PRICE: "Đơn giá hiển thị",
        INPUT_PRICE: "Nhập giá",
        INPUT_LIST_PRICE: "Nhập đơn giá hiển thị",
        ADD_NEW_ITEM_IN_COMBO: "Thêm sản phẩm vào gói",
        ADD_NEW_ITEM: "Thêm mới sản phẩm",
        DIRECT_SALE: "Sản phẩm bán trực tiếp",
        NON_RETURNABLE: "Hàng không đổi trả",

        ADD_NEW_UNIT: "Thêm đơn vị mới",
        ADD_NEW_BRAND: "Thêm thương hiệu mới",
        ADD_NEW_SIZE: "Thêm kích thước mới",
        ADD_NEW_GROUP_PRODUCT: "Thêm mới nhóm sản phẩm",
        TEXT_GUIDE_SHELFLIFE: "Còn {0} ngày so với hiện tại",
        EXPIRED: "Đã quán hạn",
        CONFIRM_SUSPENDED: "Bạn có thực sự muốn ngừng hoạt động các sản phẩm đã chọn?",
        WARNING_SUSPENDED: "Sản phẩm được chọn đã ngừng hoạt động",
        THE_PRICE_TYPE_ALREADY_EXISTS: "Loại giá được chọn đã tồn tại!",
        ATTRIBUTE_ALREADY_EXISTS: "Thuộc tính này đã tồn tại!",
        NO_FIND_PRODUCT: "Không tìm thấy sản phẩm",
        PRODUCT_ALREADY_EXISTS: "Sản phẩm này đã tồn tại!",

        NO_ENTER_MAX_STOCK_LEVEL: "Chưa nhập định mức tồn kho cao nhất",
        MAX_STOCK_LEVEL_BIGGER_MIN_STOCK_LEVEL: "Định mức tồn kho cao nhất phải lớn hơn định mức tồn kho thấp nhất",
        MIN_STOCK_LEVEL_LESSER_MIN_STOCK_LEVEL: "Định mức tồn kho thấp nhất phải nhỏ hơn định mức tồn kho cao nhất",
        TEMPLATE_IMPORT_ITEM: "Tệp mẫu để nhập excel cho loại sản phẩm ",
        TEMPLATE_IMPORT_COMBO: "Tệp mẫu để nhập excel cho loại sản phẩm ",
        TEMPLATE_IMPORT: "Tệp mẫu nhập excel ",
        CONTENT_ERROR: "Nội dung lỗi trả về: ",
        LINE_NUMBER: "Dòng số ",
    },

    PRODUCT: {
        TITLE_MENU: "Sản phẩm",
        TITLE_LIST_VIEW: "Danh sách nhóm hàng",
        TITLE_CREATE_VIEW: "Thêm nhóm hàng mới",
        TITLE_UPDATE_VIEW: "Cập nhật nhóm hàng",
        TITLE_DETAIL_VIEW: "Xem chi tiết nhóm hàng",

        PRODUCT: "Sản phẩm",
        PRODUCT_TITLE: "Mẫu phòng",
        PRODUC_LIST_TITLE: "Danh sách mẫu phòng",
        SERVICE: "Dịch vụ",
        SELECT_SERVICE: "Chọn dịch vụ",
        TENANT: "Người thuê",
        SELECT_TEANANT: "Chọn người thuê",
        DESCRIPTION: "Mô tả",
        INPUT_DESCRIPTION: "Nhập mô tả",
        TYPE: "Loại",
        RESOURCE: "Tính năng",
        SELECT_RESOURCE: "Chọn tính năng",
        SELECT_TYPE: "Chọn loại",
        NAME: "Tên sản phẩm",
        INPUT_NAME: "Nhập tên mẫu phòng",
        ATTRIBUTE: "Thuộc tính",
        SELECT_ATTRIBUTE: "Chọn thuộc tính",
        ORGANIZATION: "Công ty",
        SELECT_ORGANIZATION: "Chọn công ty",
        COMBO: "Gói",

        NORMAL_PRODUCT: "Sản phẩm thường",
        SERVICE_PRODUCT: "Sản phẩm kỹ thuật số",
        COMBO_SERVICE: "Gói tính năng tính phí",
        COMBO_PRODUCT: "Gói sản phẩm thường",
    },

    PRODUCT_TEMPLATE: {
        TITLE_MENU: "Danh sách phòng",
        TITLE_LIST_VIEW: "Danh sách phòng",
        TITLE_CREATE_VIEW: "Thêm mẫu phòng mới",
        TITLE_UPDATE_VIEW: "Cập nhật phòng",
        TITLE_DETAIL_VIEW: "Xem chi tiết phòng",
        NAME: "Tên phòng",
        CODE: "Mã phòng",
        CODE_PLACE_HOLDER: "Nhập mã phòng",
        SEARCH_TEXT: "Tìm kiếm tên phòng",
        TYPE: "Loại sản phẩm",
        BLUEPRINT: "Mẫu thiết kế",
        ALLOW_CREATE_ATTR: "Cho phép thêm đặc tính",
    },

    ORDER_TABE_HEADER: {
        STT: "STT",
        IMAGE: "Hình ảnh",
        NAME: "Tên sản phẩm",
        NAME_SHORT: "Tên SP",
        QUANTITY: "Số lượng",
        PRICE: "Đơn giá",
        AMOUNT: "Thành tiền",
        DISCOUNT: "Giảm giá",
        INVENTORY: "Tồn kho",
    },

    ORDER: {
        TITLE_MENU: "Quản lý đơn hàng",
        TITLE_CREATE_ORDER: "Tạo đơn hàng",
        TITLE_LIST_VIEW: "Danh sách đơn hàng",
        TITLE_CREATE_VIEW: "Thêm đơn hàng mới",
        TITLE_UPDATE_VIEW: "Cập nhật đơn hàng",
        TITLE_DETAIL_VIEW: "Chi tiết đơn hàng",
        SEARCH_TEXT: "Tìm kiếm theo mã đơn hàng/ tên sản phẩm",
        ENTER_SEARCH_TEXT: "Nhập mã đơn hàng",

        CODE: "Mã đơn hàng",
        PURCHASE_TIME: "Thời gian mua hàng",
        BRAND: "Chi nhánh mua hàng",
        CUSTOMER: "Khách hàng",
        SELECT_CUSTOMER: "Khách hàng",
        SELLER: "Người bán hàng",
        SELECT_SELLER: "Người bán hàng",
        TOTAL_VALUE: "Tổng giá trị đơn hàng",
        STATUS: "Trạng thái đơn hàng",
        PAYMENT_STATUS: "Trạng thái thanh toán",
        SELECT_STATUS: "Chọn trạng thái đơn hàng",
        RETURN: "Đổi trả hàng",

        GUEST: "Khách lẻ",
        CHOOSE_CUSTOMER: "Chọn khách hàng",
        RETURN_GOODS: "Đổi trả hàng",
        ORDER: "Đơn đặt hàng",
        INVENTORY: "Tồn kho",
        END_DATE_REPORT: "Báo cáo cuối ngày",
        ITEM_LIST: "Danh sách phòng",

        // modelVoucher
        VOUCHER: "Chiết khấu/Voucher",
        VOUCHER_TYPE: "Hình thức chiết khấu",
        VOUCHER_VALUE: "Giá trị chiết khấu",
        VOUCHER_COCE: "Mã giảm giá(Voucher)",
        DISCOUNT_CODE: "Mã giảm giá",
        DISCOUNT_CODE_INPUT: "Mã giảm giá",

        //khuyến mãi
        PROMOTION_PROGRAM: "Chương trình khuyến mãi",
        PROMOTION_ITEM: "Hàng khuyến mãi",

        //Add customer
        ADD_CUSTOMER: "Thêm mới khách hàng",
        CUSTOMER_NAME: "Tên khách hàng",
        CUSTOMER_NAME_INPUT: "Nhập tên khách hàng",
        CUSTOMER_BIRTHDAY: "Ngày sinh",
        SELECT_CUSTOMER_BIRTHDAY: "Chọn ngày sinh",
        APPLY_PRICE: "Bảng giá áp dụng",
        APPLY_PRICE_INPUT: "Chọn bảng giá áp dụng",
        CUSTOMER_ADDRESS: "Địa chỉ khách hàng",
        CUSTOMER_ADDRESS_INPUT: "Nhập tên địa chỉ",
        PROVINCE: "Tỉnh thành",
        DISTRICT: "Quận huyện",
        WARD: "Phường xã",
        TAX_NUMBER: "Mã số thuế",
        CUSTOMER_TYPE: "Chọn loại khách hàng",
        CUSTOMER_PHONE: "Số điện thoại khách hàng",
        CUSTOMER_PHONE_INPUT: "Số điện thoại khách hàng",
        CUSTOMER_LEVEL: "Hạn thành viên",

        //add item
        SALE_PRICE: "Giá bán",
        ITEM_INFORMATION: "Thông tin sản phẩm",
        ADD_ITEM: "Thêm mới sản phẩm",
        ITEM_NAME: "Tên sản phẩm",
        BARCODE: "Mã vạch",
        BARCODE_INPUT: "Sử dụng má scan hoặc nhập tay",
        ROOT_PRICE: "Giá vốn",
        WHOLESALE_PRICE: "Giá sỉ",
        WHOLESALE_PRICE_INPUT: "Nhập giá sỉ",
        PROVIDER: "Nhà cung cấp",
        SKU: "Mã sản phẩm(SKU)",
        SKU_INPUT: "Nhập mã sản phẩm hoặc hệ thống tự nhập",
        UNIT: "Đơn vị tính",
        RETAIL_PRICE: "Giá bán lẻ",
        RETAIL_PRICE_INPUT: "Nhập giá bán lẻ",
        INVENTORY_BEGINING: "Tồn kho đầu kì",
        INVENTORY_BEGINING_INPUT: "NHập số lượng tồn kho đầu kì",
        TAG: "Nhập tag",
        SIZE: "Kích cỡ sản phẩm",
        Weight: "Trọng lượng",
        CASH: "Tiền mặt",
        PERCENT: "%",
        MOMO: "Momo",
        DEBT: "Ghi nợ",
        CREDIT_CARD: "Quẹt thẻ",
        TRANSFER: "Chuyển khoản",
        //Thông tin sản phầm

        //order
        SEARCH: "Thêm sản phẩm",

        ORDER_CODE: "Mã đơn hàng",
        SALES_AGENT: "Nhân viên bán hàng",
        SALES_BRANCH: "Chi nhánh bán hàng",
        PHONE_NUMBER: "Số điện thoại",
        ADDRESS: "Địa chỉ",
        RETURN_ORDER_CODE: "Mã đơn đổi trả",

        SEQUENCY: "STT",
        IMAGE: "Hình ảnh",
        PRODUCT_CODE: "Mã sản phẩm",
        PRODUCT_NAME: "Tên sản phẩm",
        QUANTITY: "Số lượng",
        PRICE: "Đơn giá",
        TOTAL: "Thành tiền",
        DETAIL_PRODUCT: "Chi tiết sản phẩm",
        APPLY_PRICING_TYPE: "Bảng giá áp dung",

        TOTAL_PRODUCT: "Tổng sản phẩm",
        TOTAL_PRICE: "Tổng tiền hàng",
        VAT_TAX: "Thuế",
        LOYALTY_CLASS_DISCOUNT: "Giảm giá thành viên",
        APPLY_PROMOTIONS: "Áp dụng chương trình khuyến mãi",
        APPLY_PROMOTIONS_SHORT: "Áp dụng CTKM",
        ORDER_DISCOUNT: "Chiết khấu đơn hàng",
        USE_LOYALTY_POINT: "Sử dụng {0} điểm tích luỹ",
        TOTAL_AMOUNT_PAID_BY_CUSTOMER: "Tổng số tiền khách hàng thanh toán",
        PAYMENT_METHOD: "Phương thức thanh toán",
        CUSTOMER_PAID: "TIền khách đưa",
        MONEY_BACK: "Tiền trả lại khách",
        NOTE: "Ghi chú",
        PAYMENT_HISTORY: "Lịch sử đơn hàng",

        CANCEL: "Huỷ đơn hàng",
        PAY: "Thanh toán",
        COMPLETE: "Hoàn thành hóa đơn",
        CREATE_ORDER: "Tạo đơn",
        PRINT: "In hoá đơn",

        IN_STOCK: "Còn hàng",
        OUT_STOCK: "Hết hàng",
        APPLY_TAX: "Đã bao gồm thuế ",
        DONT_APPLY_TAX: "Không áp dụng thuế",
        NOT_INCLUDE_TAX: "Chưa bao gồm thuế",
        APPLICATION_RATE: "Mức áp dụng {0}%",
        CUSTOMER_INVENTORY: "Tồn kho khách đặt",
        SALABLE_INVENTORY: "Tồn kho có thể bán",
        ORDER_LIST: "ORDER_LIST",
        ITEM_LIST_TEMP: "ITEM_LIST_TEMP",
        REQUIRE_ITEM: "Vui lòng chọn sản phẩm",
        INVENTORY_ITEM_IS_NULL: "Sản phẩm {0} đã hết hàng, vui lòng chọn sản phẩm khác hoặc giảm số lượng sản phẩm.",
        SKU_CODE: "Mã sản phẩm(SKU)",
        MAIN_BRANCH: "Chi nhánh mặc định",
        CHANGE_AMOUNT: "Tiền thừa trả khách",
        ORDER_BOOKING: "Đơn hàng khách đặt",
        SHIP: "Giao hàng",
        VALUE: "Giá trị",
        VALUE_INPUT: "Nhập giá trị",
        CANT_FIND_ITEM: "Không tìm thấy sản phẩm",
        NVBH: "NVBH",
        THE_PRODUCT_HAS_BEEN_REMOVED: "Sản phẩm đã bị xóa khỏi hệ thống",
        ORDER_ALL: "Tất cả",
        ORDER_BOOK: "Đơn hàng khách đặt",

        SELECT_VOUCHER: "Chọn voucher",
        TOTAL_MONEY_PAYMENT: "Tổng tiền thanh toán",
        ORDER_ONE: "Đơn 1",
        COMPLETE_ORDER: "Hoàn thành hóa đơn",

        //paymentType
        BANKING: "Chuyển khoản ngân hàng",
        PAYPAL: "PAYPAL",
        VNPAY: "VNPAY",

        DOCUMENT: "Chứng từ",
        SELECT_DOCUMENT: "Chọn chứng từ",
    },

    STATUS: {
        ORDER: {
            Cancel: "Đã huỷ",
            Pending: "Đang chờ xử lý",
            New: "Mới",
            Confirm: "Xác nhận",
            Editing: "Chỉnh sửa",
            PaymentConfirm: "Xác nhận thanh toán",
            DebtConfirm: "Xác nhận nợ ",
            ExchangeRequest: "Yêu cầu đổi trả",
            ExchangeCancel: "Hủy đổi trả",
            Completed: "Hoàn thành",
        },
        PAYMENT: {
            WaitingConfirm: "Chờ thanh toán",
            Pending: "Đang xử lý",
            Issued: "Đã xuất vé",
            WaitingRefund: "Chờ hoàn tiền",
            Refunded: "Đã hoàn tiền",
            Cancel: "Đã huỷ",
            New: "Mới",
            Completed: "Đã thanh toán",
            Failed: "Thanh toán thất bại",
        },
        CONFIRM_STATUS: {
            Rejected: "Từ chối",
            Cancel: "Đã huỷ",
            Pending: "Chờ phê duyệt",
            Processing: "Đang phê duyệt",
            Confirmed: "Đã xác nhận",
            Completed: "Đã duyệt",
        },
    },

    LOYALTY_CUSTOMER: {
        TITLE_MENU: "Khách hàng thân thiết",
        SET_POINT: "Thiết lập điểm",
        MEMBERSHIP_OFFERS: "Ưu đãi hạng thành viên",
        MEMBERSHIP_LEVEL: "Hạng thành viên",
        SETUP_ACCUMULATED_POINTS: "Thiết lập điểm tích lũy",

        FORMULA_AND_CONVERT_POINT: "Xác định công thức tích lũy và quy đổi điểm",

        POINT_ACCUMULATION_RATE: "Tỷ lệ tích lũy điểm",
        CONVERSION_TARGET: "Mức chi tiêu quy đổi",
        ENTER_TAGET_AMOUNT: "Nhập số tiền chi tiêu",
        CONVERSION_POINTS: "Điểm quy đổi",
        ENTER_CONVERSION_POINTS: "Nhập điểm quy đổi",
        CURRENCY_COVERSION_RATE: "Tỷ lệ quy đổi thành tiền",
        ACCUMULATED_POINTS: "Điểm tích luỹ",
        ENTER_ACCUMULATED_POINTS: "Nhập số điểm tích luỹ",
        CURRECY_EXCHANGE: "Số tiền được quy đổi",
        ENTER_CURRECY_EXCHANGE: "Nhập số tiền được quy đổi",

        MEMBER_CLASS: "Hạng thành viên",
        CREATE_MEMBER_CLASS_LIST: "Tạo mới danh sách hạng thành viên",
        ADD_NEW_MEMBER: "Thêm hạng thành viên",
        RANKING_BY_EXPENSE: "Xếp hạng theo chi tiêu",
        RANKING_BY_POINT: "Xếp hạng theo điểm tích luỹ",
        START_AT_THE_0_ACCUMULATING_POINT: "Bắt đầu ở hạng khởi điểm và điểm tích lũy = 0",
        APPLY_MEMBERSHIP_TYPE_INCLUDING_PURCHASE_HISTORY: "Áp dụng tính hạng thành viên bao gồm lịch sử mua hàng",
        STARTING_FROM_THE_DATE: "Bắt đầu tính từ ngày",
        ENTER_STARTING_DATE: "Nhập ngày bắt đầu",
        APPLY_ALL: "Áp dụng tất cả",
        DEACTIVATE_MEMBER_IF_NO_TRANSACTION: "Hủy kích hoạt thành viên nếu không phát sinh giao dịch tại cửa hàng trong thời gian",

        LOYALTY_CLASS_NAME: "Tên hạng thành viên",
        TOTAL_TARGET: "Tổng chi tiêu",
        DISCOUNT_RATE: "Chiết khấu",
        MAX_DISCOUNT_RATE: "Giá trị tối đa chiết khấu",
        LEFT_BLANK_THE_VALUE_WILL_BE_UNLIMITED: "Nếu để trắng giá trị sẽ không giới hạn",
        VALUE_MUST_BE_GREATER_THAN_0: "Giá trị phải lớn hơn 0",
        SETTING_SUCCESS: "Đã thiết lập khách hàng thân thiết thành công!",
        CHANGE_VALUE_MESSAGE:
            "Thay đổi này sẽ được cập nhật vào cuối ngày và đồng thời sẽ làm thay đổi hạng thành viên và thông tin tích lũy điểm của khách hàng. Bạn có chắc chắn muốn thay đổi không?",

        RANKING: "Xếp hạng",
        ACCUMULATION_TIME: "Thời điểm tích lũy",
    },

    CUSTOMER: {
        TITLE_MENU: "Khách hàng",
        TITLE_LIST_VIEW: "Danh sách khách hàng",
        TITLE_CREATE_VIEW: "Thêm khách hàng mới",
        TITLE_UPDATE_VIEW: "Cập nhật khách hàng",
        TITLE_DETAIL_VIEW: "Chi tiết khách hàng",
        BASIC_INFO: "Thông tin khách hàng",

        ORDER_CODE: "Mã đơn hàng",
        ORDER_SELLER: "Nhân viên bán hàng",
        SELECT_ORDER_SELLER: "Chọn nhân viên bán hàng",
        ORDER_SELL_DATE: "Ngày bán",
        SELECT_ORDER_SELL_DATE: "Chọn ngày bán",
        ORDER_TOTAL_VALUE: "Tổng giá trị đơn hàng",
        ORDER_STATUS: "Trạng thái đơn hàng",
        SELECT_ORDER_STATUS: "Trạng thái đơn hàng",
        ORDER_PAY: "Thanh toán",
        PAYMENT_HISTORY: "Lịch sử mua hàng",
        ORDER_SEARCH_TEXT: "Tìm kiếm theo mã đơn hàng/ tên sản phẩm",
        ENTER_ORDER_SEARCH_TEXT: "Nhập mã đơn hàng",

        CUSTOMER_CODE: "Mã khách hàng",
        NAME_CUSTOMER: "Tên khách hàng",
        ENTER_NAME_CUSTOMER: "Nhập tên khách hàng",
        SELECT_MEMBER_CLASS: "Chọn hạng thành viên",
        PHONE_NUMBER: "Số điện thoại",
        CUSTOMER_TYPE: "Loại khách hàng",
        SELECT_CUSTOMER_TYPE: "Chọn loại khách hàng",
        PERSONAL_CUSTOMER: "Khách hàng cá nhân",
        LOYALTY_ACTIVE: "Kích hoạt thẻ thành viên",
        WEBSITE: "Website",
        ENTER_WEBSITE: "Enter website",
        ADDRESS: "Địa chỉ",
        ENTER_ADDRESS: "Chọn địa chỉ",
        FAX_NUMBER: "Fax",
        ENTER_FAX_NUMBER: "Nhập fax",
        TAX_NUMBER: "Mã số thuế",
        ENTER_TAX_NUMBER: "Nhập mã số thuế",
        USER: "Người dùng",
        SELECT_USER: "Chọn người dùng",
        TAG: "Tag",
        ENTER_TAG: "Nhập Tag",
        ENTER_SEARCH_TEXT: "Nhập từ khóa tìm kiếm",

        LOYALTY_CLASS: "Hạng thành viên",
        NOT_LOYALTY_CLASS: "Chưa có hạng thành viên",
        SELECT_LOYALTY_CLASS: "Chọn hạng thành viên",
        POINT: "Điểm tích luỹ",
        POINT_SHORT: "điểm",
        ENTER_POINT: "Nhập điểm tích luỹ",
        DEBT: "Công nợ",
        ENTER_DEBT: "Nhập công nợ",
    },

    AGENT: {
        TITLE_MENU: "Đại lý",
        TITLE_LIST_VIEW: "Danh sách đại lý",
        TITLE_CREATE_VIEW: "Thêm đại lý mới",
        TITLE_UPDATE_VIEW: "Cập nhật đại lý",
        TITLE_DETAIL_VIEW: "Chi tiết đại lý",
        BASIC_INFO: "Thông tin đại lý",

        ORDER_CODE: "Mã đơn hàng",
        ORDER_SELLER: "Nhân viên bán hàng",
        SELECT_ORDER_SELLER: "Chọn nhân viên bán hàng",
        ORDER_SELL_DATE: "Ngày bán",
        SELECT_ORDER_SELL_DATE: "Chọn ngày bán",
        ORDER_TOTAL_VALUE: "Tổng giá trị đơn hàng",
        ORDER_STATUS: "Trạng thái đơn hàng",
        SELECT_ORDER_STATUS: "Trạng thái đơn hàng",
        ORDER_PAY: "Thanh toán",
        PAYMENT_HISTORY: "Lịch sử mua hàng",
        ORDER_SEARCH_TEXT: "Tìm kiếm theo mã đơn hàng/ tên sản phẩm",
        ENTER_ORDER_SEARCH_TEXT: "Nhập mã đơn hàng",

        CUSTOMER_CODE: "Mã khách hàng",
        NAME_CUSTOMER: "Tên khách hàng",
        ENTER_NAME_CUSTOMER: "Nhập tên khách hàng",
        SELECT_MEMBER_CLASS: "Chọn hạng thành viên",
        PHONE_NUMBER: "Số điện thoại",
        CUSTOMER_TYPE: "Loại khách hàng",
        SELECT_CUSTOMER_TYPE: "Chọn loại khách hàng",
        PERSONAL_CUSTOMER: "Khách hàng cá nhân",
        LOYALTY_ACTIVE: "Kích hoạt thẻ thành viên",
        WEBSITE: "Website",
        ENTER_WEBSITE: "Enter website",
        ADDRESS: "Địa chỉ",
        ENTER_ADDRESS: "Chọn địa chỉ",
        FAX_NUMBER: "Fax",
        ENTER_FAX_NUMBER: "Nhập fax",
        TAX_NUMBER: "Mã số thuế",
        ENTER_TAX_NUMBER: "Nhập mã số thuế",
        USER: "Người dùng",
        SELECT_USER: "Chọn người dùng",
        TAG: "Tag",
        ENTER_TAG: "Nhập Tag",
        ENTER_SEARCH_TEXT: "Nhập từ khóa tìm kiếm",

        LOYALTY_CLASS: "Hạng thành viên",
        NOT_LOYALTY_CLASS: "Chưa có hạng thành viên",
        SELECT_LOYALTY_CLASS: "Chọn hạng thành viên",
        POINT: "Điểm tích luỹ",
        POINT_SHORT: "điểm",
        ENTER_POINT: "Nhập điểm tích luỹ",
        DEBT: "Công nợ",
        ENTER_DEBT: "Nhập công nợ",
    },

    PROMOTION: {
        TITLE_MENU: "Voucher / CTKM",
        TITLE_DETAIL: "Chi tiết CTKM",
        TITLE_UPDATE: "Cập nhật CTKM",
        TITLE_CREATE: "Tạo mới CTKM",
        TITLE_LIST_VIEW: "Danh sách Voucher / CTKM",
        SEARCH_TEXT: "Tìm kiếm theo mã chương trình",
        CODE: "Mã chương trình",
        NAME: "Tên CTKM",
        START_DAY: "Ngày hiệu lực",
        END_DAY: "Ngày hết hạn",
        APPLY_BRANCH: "Chi nhánh áp dụng",
        SELECT_APPLY_BRANCH: "Chi nhánh áp dụng",
        APPLY_OBJECT: "Đối tượng áp dụng",
        SELECT_APPLY_OBJECT: "Chọn đối tượng áp dụng",
        APPLY_BASE: "Cơ sở áp dụng",
        SELECT_APPLY_BASE: "Chọn cơ sở áp dụng",
        PROMOTION_METHOD: "Hình thức KM",
        SELECT_PROMOTION_METHOD: "Chọn Hình thức KM",
        APPLY_QUANTITY: "Số lượng KM áp dụng",
        REMAIN_QUANTITY: "Số lượng khuyến mãi còn lại",
        PROMATION_DETAIL: "Chi tiết CTKM",
        PROMATION_CONDITION: "Điều kiện khuyến mãi",
        PROMOTION_CODE: "Mã CTKM",
        ENTER_PROMOTION_CODE: "Nhập mã CTKM",
        PROMOTION_NAME: "Tên CTKM",
        ENTER_PROMOTION_NAME: "Nhập tên CTKM",
        START_TIME: "Ngày hiệu lực",
        SELECT_START_TIME: "Chọn ngày hiệu lực",
        END_TIME: "Ngày hết hạn",
        SELECT_END_TINE: "Chọn ngày hết hạn",
        UNKNOWN: "Không xác định",
        APPLY_ALL: "Áp dụng tất cả",
        PROGRESSIVE: "Khuyến mãi lũy tiến",
        PROGRESSIVE_NOTE: "(Nếu không check 1 đơn hàng chỉ được áp dụng 1 lần khuyến mãi)",
        ENTER_MAXIMUM_DISCOUNT: "Nhập số tiền giảm giá tối đa / 1 đơn",
        MAXIMUM_DISCOUNT: "Số tiền giảm giá tối đa / 1 đơn hàng",
        POINT_ON_TOTAL: "Điểm tặng tính trên tổng giá trị hoá đơn thanh toán",
        CRITERIA: "Tiêu chí",
        INVOICE_VALUE: "Giá trị hóa đơn",
        ENTER_INVOICE_VALUE: "Nhập giá trị hóa đơn từ",
        INVOICE_VALUE_FROM: "Giá trị hóa đơn từ",
        DISCOUNT_VALUE: "Số tiền giảm giá",
        ENTER_DISCOUNT_VALUE: "Nhập số tiền giảm giá",
        ADD_CRITERIA: "Thêm tiêu chí",
        AUTO_PRINT: "Tự động in voucher khi thanh toán",
        APPLY_MULTI_PROMOTION: "Áp dụng đồng thời nhiều chương trình khuyến mãi",
        GIFT_QUANTITY: "Số lượng hàng tặng",
        ENTER_GIFT_QUANTITY: "Nhập số lượng hàng tặng",
        GIFT_GOOD_GROUP: "Hàng / nhóm hàng tặng",
        SELECT_GIFT_GOOD_GROUP: "Chọn hàng / nhóm hàng tặng",
        GIFT_ITEM_QUANTITY_TOTAL: "Tổng số hàng tặng",
        ENTER_GIFT_ITEM_QUANTITY_TOTAL: "Nhập tổng số hàng tặng",
        ITEM_DISCOUNT_QUANTITY_TOTAL: "Tổng số hàng giảm giá",
        ENTER_ITEM_DISCOUNT_QUANTITY_TOTAL: "Nhập tổng số hàng giảm giá",
        GIFT_POINT_TOTAL: "Tổng số điểm được tặng",
        ENTER_GIFT_POINT_TOTAL: "Nhập tổng số điểm được tặng",
        MAXIMUM_POINT: "Số điểm tối đa / 1 đơn hàng",
        ENTER_MAXIMUM_POINT: "Nhập tổng số điểm tối đa / 1 đơn hàng",
        MAXIMUM_GIFT_POINT: "Số lượng điểm tặng tối đa / 1 đơn hàng",
        ENTER_MAXIMUM_GIFT_POINT: "Nhập số lượng điểm tặng tối đa / 1 đơn",
        GIFT_POINT: "Điểm tặng",
        ENTER_GIFT_POINT: "Nhập điểm tặng",
        PERCENT_GIFT_POINT: "% điểm tặng",
        ENTER_PERCENT_GIFT_POINT: "Nhập % điểm tặng",
        GIFT_VOUCHER_TOTAL: "Tổng số lượng voucher tặng",
        GIFT_VOUCHER_QUANTITY: "Số lượng voucher tặng",
        ENTER_GIFT_VOUCHER_QUANTITY: "Nhập số lượng voucher tặng",
        VOUCHER_RELEASE: "Đợt phát hành voucher",
        SELECT_VOUCHER_RELEASE: "Chọn đợt phát hành voucher",
        ENTER_GIFT_VOUCHER_TOTAL: "Nhập tổng số lượng voucher tặng",
        GOOD_BUY_GROUP: "Hàng / nhóm hàng mua",
        SELECT_GOOD_BUY_GROUP: "Chọn hàng / nhóm hàng mua",
        GIFT_PROMOTION_TOTAL: "Tổng số lượng hàng khuyến mãi",
        ENTER_GIFT_PROMOTION_TOTAL: "Nhập tổng số lượng hàng khuyến mãi",
        GOOD_BUY_QUANTITY: "Số lượng hàng mua",
        ENTER_GOOD_BUY_QUANTITY: "Số lượng hàng mua",
        GOOD_DISCOUNT_GROUP: "Hàng / nhóm hàng giảm giá",
        SELECT_GOOD_DISCOUNT_GROUP: "Chọn hàng / nhóm hàng giảm giá",
        GOOD_DISCOUNT_QUANTITY: "Số lượng hàng giảm giá",
        ENTER_GOOD_DISCOUNT_QUANTITY: "Nhập số lượng hàng giảm giá",
        PROMOTION_VALUE: "Số tiền khuyến mãi",
        ENTER_PROMOTION_VALUE: "Nhập số tiền khuyến mãi",
        PROMOTION_PERCENT: "% khuyến mãi",
        ENTER_PROMOTION_PERCENT: "Nhập % khuyến mãi",
        GIFT_GOOD_TOTAL: "Tổng số lượng hàng tặng",
        ENTER_GIFT_GOOD_TOTAL: "Nhập tổng số lượng hàng tặng",
        MAXIMUM_GIFT_GOOD: "Số lượng hàng tặng tối đa / 1 đơn hàng",
        ENTER_MAXIMUM_GIFT_GOOD: "Nhập số lượng tặng tối đa / 1 đơn",
        ACCUMULATED_POINT: "Số điểm tích lũy tặng",
        ENTER_ACCUMULATED_POINT: "Nhập số điểm tích lũy tặng",
        ACCUMULATED_POINT_PERCENT: "% điểm tích lũy tặng",
        ENTER_ACCUMULATED_POINT_PERCENT: "Nhập % điểm tích lũy tặng",
        QUANTITY_BUY_FROM: "Số lượng mua từ",
        PROMOTION_PRICE: "Giá khuyến mãi",
        MAXIMUM_GOOD: "Số lượng hàng tối đa / 1 đơn hàng",
        ENTER_MAXIMUM_GOOD: "Nhập số lượng hàng tối đa / 1 đơn",
        MAXIMUM_VOUCHER: "Số lượng voucher tặng tối đa / 1 đơn hàng",
        ENTER_MAXIMUM_VOUCHER: "Số lượng voucher tặng tối đa / 1 đơn",
        GIFT_GOOD: "Hàng tặng",
        DISCOUNT_GOOD: "Hàng giảm giá",
        DISCOUNT_GOOD_QUANTITY: "Số lượng hàng giảm giá",
        ENTER_DISCOUNT_GOOD_QUANTITY: "Nhập số lượng hàng giảm giá",
        DISCOUNT_PERCENT: "% giảm giá",
        ENTER_DISCOUNT_PERCENT: "Nhập % giảm giá",
        EXCLUDE_LOYALTY_POINT: "Áp dụng tích điểm thành viên",
        NEW_CRITERIA: "Tiêu chí mới",
        DISCOUNT_BY_VALUE: "Giảm giá theo số tiền",
        DISCOUNT_BY_PERCENT: "Giảm giá theo %",
        PROMOTION_BY_VALUE: "Khuyến mãi theo số tiền",
        PROMOTION_BY_PERCENT: "Khuyến mãi theo %",
        START_DATE_BIGGER_END_DATE: "Ngày hiệu lực phải nhỏ hơn ngày hết hạn",
        EXPENSE_START_DATE_BIGGER_EXPENSE_END_DATE: "Ngày ngày bắt đầu phải nhỏ hơn ngày kết thúc tính tổng chi tiêu tối thiểu",
        ITEM: "Hàng hóa",
        BILL: "Hóa đơn",
        BILL_AND_ITEM: "Hóa đơn và hàng hóa",
        BILL_DISCOUNT: "Giảm giá hóa đơn",
        GIFT_ITEM: "Tặng hàng",
        ITEM_DISCOUNT: "Giảm giá hàng",
        GIFT_LOYALTY_POINT: "Tặng điểm tích lũy",
        GIFT_VOUCHER: "Tặng voucher",
        ITEM_DISCOUNT_BY_QUANTITY: "Giá bán theo số lượng mua",
        VALUE: "Giá trị",
        UNIT: "Đơn vị",
        EXPIRED: "Đã hết hạn",
        VOUCHER: "Voucher / Coupon",
        PROMOTION: "CTKM",
        CANCEL: "Hủy kích hoạt",
        PRODUCT: "Sản phẩm",
        COMBO: "Combo",
        SERVICE: "Dịch vụ",
        NOT_USE: "Chưa sử dụng",
        USED: "Đã sử dụng",
        VIEW_LIST: "Xem danh sách",
        NOTE_ENTER_PAGE: "Vui lòng nhập số trang nhỏ hơn hoặc bằng",
        SEARCH_BY_VOUCHER_ID: "Tìm kiếm theo mã voucher",
    },

    VOUCHER: {
        TITLE_MENU: "Phát hành Voucher/ Coupon",
        RELASE_INFOMATION_TITLE: "Thông tin chung đợt phát hành",
        APPLICABILITY_RANGE_TITLE: "Phạm vi áp dụng",
        EMPLOYEE_LIST_TITLE: "Danh sách khách hàng đủ điều kiện",

        RELEASE_CODE: "Mã đợt phát hành",
        ENTER_RELEASE_CODE: "Nhập mã đợt phát hành",
        RELEASE_NAME: "Tên đợt phát hành",
        ENTER_RELEASE_NAME: "Nhập tên đợt phát hành",
        VOUCHER_VALUE: "Mệnh giá",
        VOUCHER_PERCENT: "Phần trăm",
        ENTER_VOUCHER_VALUE: "Nhập mệnh giá Voucher",
        ENTER_VOUCHER_PERCENT: "Nhập phần trăm",
        RELEASE_FORM: "Hình thức phát hành",
        VOUCHER: "Voucher",
        COUPON: "Coupon",
        IDENTIFICATION_VOUCHER: "Voucher định danh",
        APPLY_MULTIPLE_VOUCHERS_ON_ONE_BILL: "Áp dụng nhiều Voucher / Coupon trên 1 hoá đơn",
        ACTIVE: "Kích hoạt",

        EFFECTIVE_DATE: "Ngày hiệu lực",
        SELECT_EFFECTIVE_DATE: "Chọn ngày hiệu lực",
        EXPIRATION_DATE: "Ngày hết hạn",
        SELECT_EXPIRATION_DATE: "Chọn ngày hết hạn",
        APPLICABLE_OBJECT: "Đối tượng áp dụng",
        APPLICABLE_BRAND: "Chi nhánh áp dụng",
        APPLICABLE_GOODS: "Hàng/ nhóm hàng áp dụng",
        APPLICABLE_ITEMS: "Sản phẩm áp dụng",
        SELECT_APPLICABLE_OBJECT: "Chọn đối tượng áp dụng",
        SELECT_APPLICABLE_BRAND: "Chọn chi nhánh áp dụng",
        SELECT_APPLICABLE_GOODS: "Chọn hàng / nhóm hàng áp dụng",
        LOYALTY_CLASS: "Hạng thành viên",
        SELECT_LOYALTY_CLASS: "Chọn hạng thành viên",
        MINIMUN_TOTAL_TARGET: "Tổng chi tiêu tối thiểu",
        ENTER_MINIMUN_TOTAL_TARGET: "Nhập tổng chỉ tiêu tối thiểu",
        START_DATE_OF_THE_MINIMUN_TOTAL_TARGET: "Ngày bắt đầu tính tổng chỉ tiêu tối thiểu",
        END_DATE_OF_THE_MINIMUN_TOTAL_TARGET: "Ngày kết thúc tính tổng chỉ tiêu tối thiểu",
        SELECT_START_DATE_OF_THE_MINIMUN_TOTAL_TARGET: "Chọn ngày bắt đầu tính tổng chi tiêu tối thiểu",
        SELECT_END_DATE_OF_THE_MINIMUN_TOTAL_TARGET: "Chọn ngày kết thúc tính tổng chi tiêu tối thiểu",
        ADD_NEW_CONDITION: "Thêm mới điều kiện",
        CONDITION_NAME: "Tên điều kiện",
        ENTER_CONDITION_NAME: "Nhập tên điều kiện",
        VALUE: "Giá trị",
        ENTER_VALUE: "Nhập giá trị",
        APPLY_ALL: "Áp dụng tất cả",

        VOUCHER_LENGTH: "Độ dài mã voucher",
        START_CHARACTER: "Kí tự bắt đầu",
        NOTE: "Ghi chú",
        ENTER_VOUCHER_LENGTH: "Nhập độ dài mã voucher",
        ENTER_START_CHARACTER: "Nhập kí tự bắt đầu",
        ENTER_NOTE: "Nhập ghi chú",

        SEND_SMS: "Gửi SMS",
        PRINT_VOUCHER: "In voucher",
        RELEASE: "Phát hành",
        OTHER_ACTION: "Thao tác khác",
        CANCEL_RELEASE: "Huỷ phát hành",
        DELETE: "Xoá",

        QUALIFIED_QUANTITY: "Số lượng đủ điều kiện",
        RELEASE_QUANTITY: "Số lượng phát hành",
        ENTER_RELEASE_QUANTITY: "Nhập số lượng phát hành",

        CUSTOMER_CODE: "Mã khách hàng",
        CUSTOMER_NAME: "Tên khách hàng",
        CUSTOMER_PHONE_NUMBER: "Số điện thoại khách hành",
        TOTAL_SPENDING: "Tổng chi tiêu",
        VOUCHER_CODE: "Mã voucher",
        RELEASE_DATE: "Ngày phát hành",
        USE_DATE: "Ngày sử dụng",
        CREATE_CODE: "Tạo mã",
        RELEASE_NOW: "Phát tất cả hành ngay khi tạo voucher",
        BIRTHDAY: "Tháng sinh nhật",
        SELECT_MONTH: "Chọn tháng",
        SEARCH_TEXT: "Tìm kiếm theo tên/sku",
        ISSUE_ALL: "Phát hành tất cả",
        ISSUE_ALL_CONFIRM_MESSAGE: "Bạn có chắc chắn muốn phát hành toàn bộ voucher trong đợt phát hành?",
    },

    SYSTEM_SETTING: {
        TITLE_MENU: "Cấu hình hệ thống",
        TITLE_LIST_VIEW: "Danh sách mã hệ thống",

        CODE: "Mã",
        NAME: "Tên",
        VALUE: "Giá trị",
        DESCRIPTION: "Mô tả",
        UPDATE: "Cập nhật thông tin",
        DETAIL: "Chi tiết thông tin",

        ATR_COMMON: {
            Title: "Cấu hình đặc tính chung",
            Origin: "Mã xuất xứ",
            Brand: "Mã thương hiệu",
            CreateTenant: "Mã tạo người thuê",

            Enter_Origin: "Nhập mã xuất xứ",
            Enter_Brand: "Nhập mã thương hiệu",
            Enter_CreateTenant: "Nhập mã tạo người thuê",
        } as any,

        VNPAY_CONFIG: {
            Title: "Cấu hình VNPAY",
            TestUrl: "Test Url",
            ReturnUrl: "Return Url",
            Url: "Url",
            TmnCode: "Tmn Code",
            HashSecret: "Hash Secret",

            Enter_TestUrl: "Nhập Test Url",
            Enter_ReturnUrl: "Nhập Return Url",
            Enter_Url: "Nhập Url",
            Enter_TmnCode: "Nhập Tmn Code",
            Enter_HashSecret: "Nhập Hash Secret",
        } as any,

        PAYPAL_CONFIG: {
            Title: "Cấu hình PAYPAL",
            ClientId: "Clien tId",
            BaseURL: "Base URL",
            Secret: "Secret",

            Enter_ClientId: "Nhập Clien tId",
            Enter_BaseURL: "Nhập Base URL",
            Enter_Secret: "Nhập Secret",
        } as any,

        PAYMENT_CONFIG: {
            Title: "Cấu hình thanh toán",
            VNPay: "VNPay",
            Paypal: "Paypal",
            Checkout: "Checkout",
        } as any,

        PAYMENT_GATEWAY: {
            Title: "Cấu hình cổng thanh toán",
            Web: "Web",
            Ios: "Ios",
            Android: "Android",

            Name: "Tên cổng thanh toán",
            Credentials: "Thông tin xác thực",
            Tax: "Thuế",
            IsDefault: "Mặc định",

            Enter_Name: "Nhập tên cổng thanh toán",
            Enter_Credentials: "Nhập thông tin xác thực",
            Enter_Tax: "Nhập thuế",
        } as any,

        PARTNER_SETTING: {
            Title: "Cấu hình đối tác",
            Type: "Loại đối tác",
            Code: "Thuộc tính",
        } as any,
    } as any,

    MEMBERSHIP: {
        TITLE_MENU: "Danh mục gói thành viên",
        TITLE_LIST_VIEW: "Danh sách gói thành viên",
        TITLE_CREATE_VIEW: "Thêm gói thành viên mới",
        TITLE_UPDATE_VIEW: "Cập nhật gói thành viên",
        TITLE_DETAIL_VIEW: "Xem chi tiết gói thành viên",
        SEARCH_TEXT: "Tìm kiếm theo mã, tên gói thành viên",

        DETAIL: "Chi tiết gói thành viên",
        CODE: "Mã gói thành viên",
        INPUT_CODE: "Nhập mã gói thành viên",
        NAME: "Tên gói thành viên",
        INPUT_NAME: "Nhập tên gói thành viên",
        ROLE: "Quyền hạn",
        SELECT_ROLE: "Chọn quyền hạn",
        DESCRIPTION: "Mô tả",
        ENTER_CODE: "Nhập mã gói thành viên",
        ENTER_NAME: "Nhập tên gói thành viên",
        ENTER_DESCRIPTION: "Nhập mô tả",

        ITEM_LIST: "Danh sách tình năng",
        ITEM_NAME: "Tên tính năng",
        ENTER_ITEM_NAME: "Nhập tên tính năng",
        PRICE: "Giá",
        ENTER_PRICE: "Nhập giá",
        ATTRIBUTE: "Thuộc tính",
        SELECT_ATTRIBUTE: "Chọn thuộc tính",
        EXPIRED_DATE: "Thời hạn",
        ENTER_EXPIRED_DATE: "Nhập thời hạn",
        IMAGE: "Hình ảnh",
        ADD_NEW_ITEM: "Thêm sản phẩm",
        BASIC_INFO: "Thông tin cơ  bản",
        ITEM_ATTRIBUTE: "Mô tả đặc điểm gói thành viên",
    },

    CASH_BOOK: {
        TITLE: "Sổ quỹ",

        CREATE_RECEIVE_VOUCHER: "Tạo phiếu thu",
        CREATE_PAYMENT_VOUCHER: "Tạo phiếu chi",

        TYPE: "Loại phiếu",
        STATUS: "Trạng thái",
        CODE: "Mã phiếu",
        PARTNER_NAME: "Tên NV/KH/NCC",
        CREATE_TIME: "Ngày ghi nhận",
        CREATE_USER: "Người tạo",
        PAYMENT_TYPE: "Phương thức thanh toán",
        MONEY: "Số tiền",
        CURRENCY: "ĐVTT",
        DESCRIPTION: "Diễn giải",
        RECEIVE: "Phiếu thu",
        PAYMENT: "Phiếu chi",
        ALL: "Tất cả",
        REFERENCE_DOCUMENT: "Chứng từ tham chiếu",

        STAFF_NAME: "Tên nhân viên",
        CUSTOMER: "Khách hàng/NCC",
        VIEW_REPORT: "Xem báo cáo",

        THIS_RESULT_IS_RECORDED_ON_A_MONTHLY_BASIS: "Kết quả này được ghi nhận theo tháng",
        BEGINNING_MONEY: "Tiền đầu kỳ",
        ENDING_MONEY: "Tiền cuối kỳ",
        TOTAL_REVENUE: "Tổng thu",
        TOTAL_EXPENDITURE: "Tổng chi",
        FROM_DATE: "Từ ngày",
        TO_DATE: "Đến ngày",
    },

    RECEIVE_VOUCHER: {
        TITLE: "Tạo mới phiếu thu",
        TITLE_DETAIL_VIEW: "Thông tin chung",
        TITLE_UPDATE_VIEW: "Cập nhật phiếu thu",
        TITLE_DETAIL: "Thông tin phiếu thu",

        // RevenueRecognition
        CUSTOMER: "Khách hàng",
        SUPPLIER: "Nhà cung cấp",
        OTHER: "Đối tác",
        CODE: "Mã phiếu thu",
        REVENUE_RECOGNITION: "Nhóm người nộp",
        PAYER: "Tên người nộp",
        BRANCH: "Chi nhánh",
        VOUCHER_CODE: "Mã phiếu",
        RECEIVE_TYPE: "Loại phiếu thu",
        RECEIVE_DATE: "Ngày ghi nhận",
        RECEIVE_VALUE: "Giá trị ghi nhận",
        MONEY: "Số tiền",
        PAYMENT_METHOD: "Phương thức thanh toán",
        DESCRIPTION: "Diễn giải",
        BANK: "Ngân hàng nộp",
        ACCOUNT: "Tài khoản nộp",
        IS_DEFAULT: "Ghi nhận vào kết quả hoạt động kinh doanh",

        SELF_CREATED: "Tự tạo",
        AUTOMATIC: "Tạo tự động",
        CUSTOMER_ORDER: "Khách hàng đặt cọc",
        COLLECTION_AND_TRANSFER: "Thu nợ đối tác vận chuyển",
        HISTORY_UPDATE: "Lịch sử chỉnh sửa",
        COMMIT: "Xác nhận thanh toán",
        CREATE_AUTO: "Tạo tự động",
        UPDATED: "Đã chỉnh sửa",

        RECEIVE_CONFIRM_SAVE: "Bạn có chắc chắn hoàn tất phiếu thu?",
    },

    PAYMENT_TICKET: {
        TITLE_CREATE: "Tạo mới phiếu chi",
        TITLE_UPDATE: "Cập nhật phiếu chi",
        TITLE_DETAIL: "Thông tin phiếu chi",

        CODE: "Mã phiếu chi",
        BACSIC_INFO: "Thông tin chung",
        RECIPIENT_GROUP: "Nhóm người nhận",
        SELECT_RECIPIENT_GROUP: "Chọn nhóm người nhận",
        CODE_TICKET: "Mã phiếu",
        ENTER_CODE_TICKET: "Mã phiếu tự động",
        RECIPIENT_NAME: "Tên người nhận",
        ENTER_RECIPIENT_NAME: "Nhập tên người nhận",
        TYPE_PAYMENT: "Loại phiếu chi",
        SELECT_TYPE_PAYMENT: "Chọn loại phiếu chi",
        RECEIVED_DATE: "Ngày nhận",
        SELECT_RECEIVED_DATE: "Chọn ngày nhận",
        RECOGNIZED_VALUE: "Giá trị ghi nhận",
        MONEY: "Số tiền",
        ENTER_MONEY: "Nhập số tiền",
        PAYMENT_METHODS: "Phương thức thanh toán",
        SELECT_PAYMENT_METHODS: "Chọn phương thức thanh toán",
        ACCOUNT_GET: "Tài khoản nhận",
        ENTER_ACCOUNT_GET: "Nhập tài khoản nhận",
        BANK_NAME: "Ngân hàng nhận",
        ENTER_BANK_NAME: "Nhập ngân hàng nhận",
        EXPLAIN: "Diễn giải",
        ENTER_EXPLAIN: "Nhập diễn giải",
        SELF_CREATED: "Tự tạo",
        AUTOMATIC: "Tạo tự động",
        CUSTOMER_DEPOSIT: "Tiền gửi của khách hàng",
        COLLECT_DEBT_FROM_SHIPPING_PARNERS: "Thu nợ đối tác vận chuyển",

        CUSTOMER: "Khách hàng",
        SUPPLIER: "Nhà cung cấp",
        OTHER: "Đối tác",
        STAFF: "Nhân viên",
        CONFIRM_COMPLETE: "Bạn có chắc muốn hoàn thành phiếu chi này?",
        IS_DEFAULT: "Ghi nhận vào kết quả hoạt động kinh doanh",
        HISTORY_UPDATE: "Lịch sử chỉnh sửa",
        COMMIT: "xác nhận thanh toán",
        CREATE_AUTO: "Tạo tự động",
        UPDATED: "Đã chỉnh sửa",
        INVOICE_DATE: "Ngày chứng từ",
        SELECT_INVOICE_DATE: "Chọn ngày chứng từ",
    },

    SERVICE_ORGANIZATION: {
        TITLE_MENU: "Công ty",
        TITLE_LIST_VIEW: "Danh sách công ty",
        ADD_ORGANIZATIONS: "Thêm công ty",
        ADD_USERS: "Thêm người dùng",
        ADD_NEW_ORGANIZATION: "Thêm mới công ty",
    },

    BUNDLE_PRODUCT: {
        TITLE_MENU: "Gói sản phẩm",
        TITLE_LIST_VIEW: "Danh sách gói sản phẩm",
        TITLE_CREATE_VIEW: "Thêm gói sản phẩm mới",
        TITLE_UPDATE_VIEW: "Cập nhật gói sản phẩm",
        TITLE_DETAIL_VIEW: "Xem chi tiết gói sản phẩm",
        SEARCH_TEXT: "Tìm kiếm theo mã, tên gói sản phẩm",
    },

    SUPPLIER: {
        TITLE_MENU: "Nhà cung cấp",
        TITLE_LIST_VIEW: "Danh sách nhà cung cấp",
        TITLE_CREATE_VIEW: "Thêm nhà cung cấp mới",
        TITLE_UPDATE_VIEW: "Cập nhật nhà cung cấp",
        TITLE_DETAIL_VIEW: "Chi tiết nhà cung cấp",
        BASIC_INFO: "Thông tin nhà cung cấp",
    },

    MANUFACTURER: {
        TITLE_MENU: "Khách sạn",
        TITLE_LIST_VIEW: "Danh sách khách sạn",
        TITLE_CREATE_VIEW: "Thêm khách sạn mới",
        TITLE_UPDATE_VIEW: "Cập nhật khách sạn",
        TITLE_DETAIL_VIEW: "Chi tiết khách sạn",
        BASIC_INFO: "Thông tin khách sạn",
    },

    BOOKING: {
        TITLE_MENU: "Quản lý đặt chỗ",
        TITLE_MENU_AMEND: "Quản lý dịch vụ bổ sung",
        TITLE_MENU_FLIGHT: "Đặt chỗ máy bay",
        TITLE_MENU_HOTEL: "Đặt chỗ khách sạn",
        TITLE_LIST: "Danh sách đặt chỗ",
        TITLE_CREATE: "Thêm mới đặt chỗ",
        TITLE_UPDATE: "Cập nhật đặt chỗ",
        TITLE_DETAIL: "Chi tiết đặt chỗ",

        MESSAGE_CANCEL_SUCCESS: "Huỷ yêu cầu đặt chỗ thành công",
        MESSAGE_CONFIRM_CANCEL: "Bạn có thực sự muốn huỷ yêu cầu đặt chỗ này?",

        MESSAGE_DELELE_SUCCESS: "Xoá yêu cầu đặt chỗ thành công",
        MESSAGE_CONFIRM_DELETE: "Bạn có thực sự muốn xóa yêu cầu đặt chỗ này?",

        MESSAGE_APPROVE_SUCCESS: "Xác nhận yêu cầu đặt chỗ thành công",
        MESSAGE_CONFIRM_APPROVE: "Bạn có chắc chắn xác nhận yêu cầu đặt chỗ này?",

        MESSAGE_END_TIME_INVALID: "Ngày nhận phòng phải nhỏ hơn ngày trả phòng",

        GENERAL_INFO: "Thông tin chung",
        EXTRA_INFO: "Thông tin bổ sung",

        EMAIL: "Email",
        ENTER_EMAIL: "Nhập email",
        PHONE_NUMBER: "Số điện thoại",
        ENTER_PHONE_NUMBER: "Nhập số điện thoại",
        END_TIME: "Ngày trả phòng",
        START_TIME: "Ngày nhận phòng",
        BOOKING_CODE: "Mã đặt phòng",
        ENTER_BOOKING_CODE: "Nhập mã đặt phòng hoặc hệ thống tự phát sinh",
        STATUS: "Trạng thái đặt chỗ",
        REQUEST_MANAGE: "Quản lý yêu cầu",
        SELECT_STATUS: "Chọn trạng thái",

        AGENT: "Đại lý",
        FLIGHT: "Chuyến bay",
        HOTEL: "Khách sạn",
        CUSTOMER: "Khách hàng",
        PERSON_IN_CHARGE: "Người phụ trách",
        SELECT_OR_ADD_AGENT: "Chọn hoặc thêm mới đại lý",
        SELECT_OR_ADD_HOTEL: "Chọn hoặc thêm mới khách sạn",
        SELECT_OR_ADD_CUSTOMER: "Chọn hoặc thêm mới khách hàng",
        SELECT_OR_ADD_PERSON_IN_CHARGE: "Chọn hoặc thêm mới người phụ trách",

        ADULT_SLOT: "Số người lớn",
        CHILDREN_SLOT: "Số trẻ em",
        CHILDREN_AGE: "Tuổi của trẻ",

        // CardStatusInfo
        STATUS_INFO_PAYMENT_STATUS_TEXT_EXPIRED: "Lý do: Hết hạn thanh toán",
        STATUS_INFO_DUE_DATE: "Hạn thanh toán {0}",
        STATUS_INFO_CONFIRM_REFUND: "Xác nhận hoàn tiền",
        STATUS_INFO_FETCHING_REFUND_DETAIL: "Đang tải thông tin hoàn tiền",
        STATUS_INFO_PAYMENT_STATUS_TEXT_REJECT: "Lý do: Đặt chỗ bị từ chối duyệt",
        STATUS_INFO_PAYMENT_STATUS_TEXT_REFUND_AT: "Đã hoàn tiền lúc {0} - {1}",
        STATUS_INFO_TITLE_BOOKING_CODE: "Mã đơn hàng: {code}",
        STATUS_INFO_TITLE_BOOKING_AT: "Thời gian đặt: ",

        // member info
        MEMBER_INFO_TITLE: "Thông tin hành khách",
        MEMBER_INFO_ADULT: "Người lớn",
        MEMBER_INFO_NATIONALITY: "Quốc tịch",
        MEMBER_INFO_ISSUED_AT: "Nơi cấp",
        MEMBER_INFO_EXPIRED_AT: "Ngày hết hạn",
        MEMBER_INFO_PASSPORT: "Số hộ chiếu",

        // Add service
        ADD_SERVICE_ADDITION_SERVICE: "Dịch vụ bổ sung",
        ADD_SERVICE_TRANSFER_FLIGHT: "Thay đổi chuyến bay",
        ADD_SERVICE_TICKET_REFUND: "Hoàn vé",

        REFUND: "Hoàn vé",
        CONFIRM_REFUND_TITLE: "Xác nhận hoàn tiền",
        CONFIRM_REFUND_SUCCESS: "Đã hoàn tiền thành công!",
        CONFIRM_REFUND_MISSION_REASON_ERROR: "Vui lòng nhập ghi chú",
        CONFIRM_REFUND_LATER_BUTTON: "Để sau",
        CONFIRM_REFUND_REASON_PLACEHOLDER: "Nhập ghi chú hệ thống",
        CONFIRM_REFUND_PROMPT: "Sau khi bạn xác nhận, hệ thống sẽ tự động gửi một email thông báo cho khách hàng về việc hoàn tiền",

        PAYMENT_FEE: "Phí thanh toán",
        POPUP_TOTAL: "Tổng thanh toán",
        POPUP_GO_BACK_BUTTON: "Quay lại",
        RESERVATION_CODE: "Mã đặt chỗ",
        CABIN_CLASS: "Hạng vé",
        TICKET_NUMBER: "Số vé",
        SEAT: "Chỗ ngồi",
        SEAT_NUMBER: "Số ghế",
        BAGGAGE: "Hành lý",
        DEPARTURE: "Điểm đi",
        ARRIVAL: "Điểm đến",

        PAYMENT_TYPE_DEBT: "Quỹ xuất vé",
        PAYMENT_TYPE_NINEPAY_COLLECTION: "Chuyển khoản ngân hàng/ Quét QR code",
        PAYMENT_TYPE_NINEPAY_ATM: "Thẻ ATM nội địa",
        PAYMENT_TYPE_NINEPAY_CREDIT: "Thẻ Visa/Master/JCB phát hành trong nước",
        PAYMENT_TYPE_NINEPAY_CREDIT_INTERNATIONAL: "Thẻ Visa/Master/JCB phát hành nước ngoài",

        ADDITION_SERVICE_SERVICE_TEXT: "Dịch vụ",
        ADDITION_SERVICE_ADD_SERVICE_TEXT: "Thêm dịch vụ",
        ADDITION_SERVICE_TEXT: "Tổng tiền dịch vụ bổ sung",
        ADDITION_SERVICE_TOTAL_PRICE_TEXT: "Tổng tiền dịch vụ bổ sung",
        ADDITION_SERVICE_POPUP_CREATE_TITLE: "Thêm dịch vụ bổ sung",
        ADDITION_SERVICE_POPUP_CONFIRM_TITLE: "Xác nhận mua thêm dịch vụ bổ sung",
        ADDITION_SERVICE_POPUP_SUCCESS: "Đã thêm dịch vụ bổ sung",
        ADDITION_SERVICE_REACH_LIMIT: "Đã đạt giới hạn số lượng dịch vụ cho phép",
        ADDITION_SERVICE_CONFIRM_NOTE: "Lưu ý: bạn không thể hoàn tác sau khi thực hiện",
        ADDITION_SERVICE_CONFIRM_SUMMARY_TITLE: "Bạn đang thực hiện mua thêm dịch vụ bổ sung cho:",
        ADDITION_SERVICE_CONFIRM_MUST_PAY_TITLE: "Số tiền cần thanh toán",
        FLIGHT_PASSENGER: "Hành khách",
        ADDITION_SERVICE_TOTAL_UNIT_PRICE: "Tổng tiền dịch vụ bổ sung",
        ADDITION_SERVICE_NO_SERVICE_TO_ADD: "Không có dịch vụ bổ sung nào để thêm",

        TRANSFER_POPUP_CONFIRM_TITLE: "Xác nhận thay đổi chuyến bay",
        TRANSFER_POPUP_SUCCESS: "Đã cập nhật thay đổi",
        TRANSFER_POPUP_NOSHOW_FEE: "Phí noshow",
        TRANSFER_POPUP_NOSHOW_FEE_PLACEHOLDER: "Nhập phí noshow",
        TRANSFER_POPUP_FARE_DIFFERENT: "Chênh lệch giá vé",
        TRANSFER_POPUP_FARE_DIFFERENT_PLACEHOLDER: "Nhập chênh lệch giá vé",
        TRANSFER_POPUP_TOTAL_FLIGHT_CHANGE_FEE: "Tổng tiền đổi vé",
        TRANSFER_POPUP_TOTAL_FLIGHT_CHANGE_FEE_PLACEHOLDER: "Phí thay đổi chuyến bay",
        TRANSFER_POPUP_CONFIRM_NOTE: "Lưu ý: bạn không thể hoàn tác sau khi thay đổi chuyến bay",
        TRANSFER_POPUP_CURRENT_FLIGHT: "Chuyến bay hiện tại",
        TRANSFER_POPUP_NEW_FLIGHT: "Chuyến bay mới",
        TRANSFER_POPUP_CARRIER: "Hãng",
        TRANSFER_POPUP_TIME: "Thời gian",
        TRANSFER_POPUP_DEPARTURE_ARRIVAL: "Điểm đi - điểm đến",
        TRANSFER_POPUP_FEE_TITLE: "Phí phát sinh thay đổi chuyến bay",
        TRANSFER_POPUP_SELECT_DEPARTURE: "Chọn điểm đi",
        TRANSFER_POPUP_SELECT_ARRIVAL: "Chọn điểm đến",
        TRANSFER_POPUP_DEPARTURE_TIME: "Thời gian khởi hành",
        TRANSFER_POPUP_ARRIVAL_TIME: "Thời gian hạ cánh",
        TRANSFER_POPUP_CARRIER_FULL_TEXT: "Hãng hàng không",
        TRANSFER_POPUP_CARRIER_FULL_TEXT_PLACEHOLDER: "Chọn hãng hàng không",
        TRANSFER_POPUP_RESERVATION_CODE_PLACEHOLDER: "Nhập mã đặt chỗ",
        TRANSFER_POPUP_TICKET_NUMBER_PLACEHOLDER: "Nhập số vé",
        TRANSFER_POPUP_INPUT_FIELD_PLACEHOLDER: "Nhập thông tin",
        TRANSFER_POPUP_TICKET_NUMBER: "Số vé (nếu có)",
        TRANSFER_POPUP_CABIN_CLASS: "Hạng đặt chỗ",
        TRANSFER_POPUP_CABIN_CLASS_PLACEHOLDER: "Chọn hạng đặt chỗ",
        TRANSFER_POPUP_NO_DETAILS_TO_TRANSFER: "Không tìm thấy thông tin thay đổi chuyến bay nào",

        REFUND_POPUP_REFUNDED_CHIP_LABEL: "Đã hoàn vé",
        REFUND_POPUP_SUCCESS: "Hoàn vé thành công",
        REFUND_POPUP_CONFIRM_TITLE: "Xác nhận hoàn vé",
        REFUND_POPUP_REFUND_AMOUNT: "Tiền hoàn vé",
        REFUND_POPUP_CUSTOMER_REFUND: "Tổng tiền hoàn trả khách",
        REFUND_POPUP_CONFIRM_NOTE: "Lưu ý: bạn không thể hoàn tác sau khi thực hiện hoàn vé",
        REFUND_POPUP_CONFIRM_SUMMARY_TITLE: "Bạn đang thực hiện hoàn vé:",
        REFUND_POPUP_CUSTOMER_REFUND_TEXT: "Số tiền hoàn cho khách là",
        REFUND_POPUP_ERROR_NO_ITEM_SELECTED: "Vui lòng chọn ít nhất một vé trước khi tiếp tục",
        REFUND_POPUP_NO_DETAILS_TO_REFUND: "Không có tìm thấy thông tin vé để hoàn tiền",
    },

    PARTNER: {
        TITLE_MENU: "Đối tác",

        AGENT: "Đại lý",
        EXPERT: "Chuyên gia",
        REFERRAL: "Người giới thiệu",
        SPONSOR: "Nhà tài trợ",
        CUSTOMER: "Khách hàng",
        SUPPLIER: "Nhà cung cấp",
        MANUFACTURER: "Nhà sản xuất",
    },

    //
    ORGANIZATION: {
        TITLE_MENU_PARENT: "Quản lý công ty",
        TITLE_MENU: "Công ty",
        TITLE_LIST_VIEW: "Danh sách công ty",
        TITLE_CREATE_VIEW: "Thêm mới công ty",
        TITLE_UPDATE_VIEW: "Cập nhật công ty",
        TITLE_DETAIL_VIEW: "Chi tiết công ty",
    },

    GROUP: {
        TITLE_MENU: "Phòng ban",
        TITLE_LIST_VIEW: "Danh sách phòng ban",
        TITLE_CREATE_VIEW: "Thêm phòng ban mới",
        TITLE_UPDATE_VIEW: "Cập nhật phòng ban",
        TITLE_DETAIL_VIEW: "Chi tiết phòng ban",
    },

    STAFF: {
        TITLE_MENU: "Nhân sự",
        TITLE_LIST_VIEW: "Danh sách nhân sự",
        TITLE_CREATE_VIEW: "Thêm mới nhân sự",
        TITLE_UPDATE_VIEW: "Cập nhật nhân sự",
        TITLE_DETAIL_VIEW: "Chi tiết nhân sự",
        TITLE_STAFF_INFORMATION: "Thông tin nhân sự",
        TITLE_STAFF_WORKING_GROUP: "Thông tin phòng ban",
    },

    SERVICE_FEE: {
        TITLE_MENU: "Phí dịch vụ",
        TITLE_LIST_VIEW: "Danh sách phí dịch vụ",
    },
    
    HIDDEN_FEE: {
        TITLE_MENU: "Phí hidden",
        TITLE_LIST_VIEW: "Phí hidden",
    },

    TICKET_FUND: {
        TITLE_MENU: "Quỹ xuất vé",
    },

    LIMIT_SETTING: {
        TITLE_MENU: "Cài đặt hạn mức",
        TITLE_LIST_VIEW: "Danh sách hạn mức",
        TITLE_CREATE_VIEW: "Thêm hạn mức mới",
        TITLE_UPDATE_VIEW: "Cập nhật hạn mức",
        TITLE_DETAIL_VIEW: "Thông tin hạn mức",
    },

    APPROVE_DEPOSIT: {
        TITLE_MENU: "Duyệt thanh toán",
        TITLE_LIST_VIEW: "Duyệt thanh toán",
        TITLE_CREATE_VIEW: "Tạo mới thanh toán",
        TITLE_UPDATE_VIEW: "Cập nhật thanh toán",
        TITLE_DETAIL_VIEW: "Thông tin thanh toán",
    },

    REQUEST_DEPOSIT: {
        TITLE_MENU: "Yêu cầu thanh toán",
        TITLE_LIST_VIEW: "Yêu cầu thanh toán",
        TITLE_CREATE_VIEW: "Tạo mới thanh toán",
        TITLE_UPDATE_VIEW: "Cập nhật thanh toán",
        TITLE_DETAIL_VIEW: "Thông tin thanh toán",
    },

    SERVICE_INFO: {
        TITLE_MENU: "Thông tin dịch vụ",
        TITLE_LIST_VIEW: "Thông tin dịch vụ",
        TITLE_CREATE_VIEW: "Tạo mới thông tin dịch vụ",
        TITLE_UPDATE_VIEW: "Cập nhật thông tin dịch vụ",
        TITLE_DETAIL_VIEW: "Thông tin thông tin dịch vụ",
    },

    ROLE: {
        TITLE_MENU: "Vai trò",
        TITLE_LIST_VIEW: "Vai trò",
        TITLE_CREATE_VIEW: "Thêm mới vai trò",
        TITLE_UPDATE_VIEW: "Cập nhật vai trò",
        TITLE_DETAIL_VIEW: "Chi tiết vai trò",
        BASIC_INFO: "Vai trò",
        SEARCH_TEXT: "Tìm kiếm theo mã/tên",
        ENTER_SEARCH_TEXT: "Nhập mã/tên",
        DISCRIPTION: "Mô tả",
        CODE: "Mã",
        NAME_ROLE: "Tên",
        SHOW: "Hiển thị",
        RESOURCE: "Tính năng",
        ACTIVATED: "Đang hoạt động",
        READ: "Xem",
        ROLE: "Vai trò",
        CREATE: "Thêm mới",
        DELETE: "Xoá",
        UPDATE: "Chỉnh sửa",
        IMPORT: "Nhập",
        EXPORT: "Xuất",
        NONE: "Không",
        OWNER: "Chủ sở hữu",
        MANAGER: "Quản lý",
        TYPE: "Loại",
        SELECT_TYPE: "Chọn loại",
        NORMAL: "Normal",
        DEFAULT_ROLE: "Default",
        MEMBER: "Member",
        OWNER_ROLE: "Organization Owner",
        ORGANIZATION_ADMIN: "Organization Admin",
        GROUP_ADMIN_ROLE: "Group admin",
        SERVICE_ADMIN: "Service Admin",
        WARNING_OWNER: "Bạn không có quyền cập nhật vai trò này",
    },
};
