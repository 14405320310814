import moment from "moment";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Constants from "../../constants";
import useModule from "hooks/useModule";
import ModuleBookingOrder from "components/BookingOrder";

import { DashboardLayout } from "layout";

const BookingOrderScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),

        endTime: !Helpers.isNullOrEmpty(searchParams.get("endTime")) ? searchParams.get("endTime") : moment().endOf("month").unix(),
        startTime: !Helpers.isNullOrEmpty(searchParams.get("startTime")) ? searchParams.get("startTime") : moment().startOf("month").unix(),

        code: searchParams.get("code"),
        searchText: searchParams.get("searchText"),
        customServiceType: searchParams.get("customServiceType"),
        paymentStatus: Helpers.isNullOrEmpty(searchParams.get("paymentStatus")) ? undefined : Number(searchParams.get("paymentStatus")),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.ORDER_LIST,
            detail: Screens.ORDER_EDIT,
        },
        keyOfStrings: "ORDER",
    });

    return (
        <DashboardLayout isPermission {...{ title, route }}>
            <ModuleBookingOrder
                {...moduleProps}
            />
        </DashboardLayout>
    );
};

export default BookingOrderScreen;
