import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ModuleGroup, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import Constants from "constants/index";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";
import { ITitleRoute } from "commons/interfaces";
import { setListPathName } from "store/slice/titleRoute.slice";

interface IModel {
    mode?: number;
    title?: string;
    route?: ITitleRoute[];
}

interface IRequestGetPaged {
    listStatus?: string[];
    searchText?: string;
    orderby?: string;
    pageSize?: number;
    pageNumber?: number;
    totalCount?: number;
}

const GroupScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const idDetail = searchParams.get("id");
    const modeList = Screens.GROUP_LIST.includes(window.location.pathname);
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const resourcePermissions = getResourcePermissions(Constants.ResourceUri.GROUP);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const itemPathName = listPathName.find((el) => el.pathName === Screens.GROUP_LIST);

    const [dataRequest] = useState<IRequestGetPaged>({
        searchText: searchParams.get("searchText"),
        listStatus: searchParams.getAll("listStatus"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
        totalCount: itemPathName?.totalCount,
    } as IRequestGetPaged);

    const [model, setModel] = useState<IModel>({} as IModel);

    useEffect(() => {
        if (Helpers.isNullOrEmpty(idDetail)) {
            handleChangeMode(Mode.Create);
        } else {
            let mode = resourcePermissions.canUpdate ? pramsMode || Mode.Update : Mode.View;
            handleChangeMode(mode);
        }
    }, [idDetail, pramsMode]);

    const handleGoBack = () => {
        const itemP = listPathName.find((el) => el.pathName === Screens.GROUP_LIST);
        navigate(Screens.GROUP_LIST + (itemP ? itemP.query : ""));
    };

    const handleChangeMode = (value: Mode) => {
        let title: string = "";
        let route = [{ title: Strings.GROUP.TITLE_MENU, route: Screens.GROUP_LIST }];

        if (value === Mode.Create) {
            title = Strings.GROUP.TITLE_CREATE_VIEW;
            route = [
                { title: Strings.GROUP.TITLE_MENU, route: Screens.GROUP_LIST },
                { title: Strings.Common.CREATE_NEW, route: "" },
            ];
        }
        if (value === Mode.Update) {
            title = Strings.GROUP.TITLE_UPDATE_VIEW;
            route = [
                { title: Strings.GROUP.TITLE_MENU, route: Screens.GROUP_LIST },
                { title: Strings.Common.UPDATE, route: "" },
            ];
        }
        if (value === Mode.View) {
            title = Strings.GROUP.TITLE_DETAIL_VIEW;
            route = [
                { title: Strings.GROUP.TITLE_MENU, route: Screens.GROUP_LIST },
                { title: Strings.Common.VIEW, route: "" },
            ];
        }

        setModel({
            ...model,
            mode: value,
            title: title,
            route: route,
        });

        if (!Helpers.isNullOrEmpty(idDetail)) {
            navigate(Screens.GROUP_EDIT + `?id=${idDetail}&mode=${value}`, { replace: true });
        }
    };

    return (
        <DashboardLayout
            isPermission
            isRequiredOrganization
            title={modeList ? Strings.GROUP.TITLE_LIST_VIEW : model.title}
            route={modeList ? [{ title: Strings.GROUP.TITLE_MENU, route: "" }] : model.route}
        >
            <ModuleGroup
                lableGroup={Strings.GROUP.TITLE_MENU}
                lableStaff={Strings.STAFF.TITLE_MENU}
                lableOrganization={Strings.ORGANIZATION.TITLE_MENU}

                // hidenActionCreateMember
                hidenInputSelectGroupParent
                hidenActionCreate={!resourcePermissions.canCreate}
                hidenActionDelete={!resourcePermissions.canDelete}
                hidenActionUpdate={!resourcePermissions.canUpdate}

                keyModuleContainer={{
                    list: { pathName: Screens.GROUP_LIST },
                    detail: { pathName: Screens.GROUP_EDIT },
                    create: { pathName: Screens.GROUP_CREATE },
                }}
                requestGetPaged={dataRequest}
                onNavigate={(data) => {
                    if (data.id) {
                        navigate(Screens.GROUP_EDIT + `?id=${data.id}&mode=${data.mode}`);
                    } else {
                        navigate(Screens.GROUP_CREATE);
                    }
                }}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.GROUP_LIST, query, totalCount }));
                    navigate(Screens.GROUP_LIST + query, { replace: true });
                }}
                mode={model.mode}
                idDetail={idDetail}
                onGoBack={handleGoBack}
                onChangeMode={(value) => {
                    handleChangeMode(value);
                }}
            />
        </DashboardLayout>
    );
};

export default GroupScreen;
