import { ICodename } from "commons/interfaces";
import { PaymentType } from "constants/enum";
import Constants from "constants/index";
import { useMemo } from "react";
import Strings from "constants/strings";

export const usePaymentMethodCodenames = (props?: { showCodenameAll?: boolean }) => {
    const { showCodenameAll = false } = props || {};

    const codenames = useMemo(() => {
        const codenames = [{
            code: Constants.CODENAME_ALL,
            name: Strings.Common.ALL,
        }, {
            code: PaymentType.Debt,
            name: Strings.BOOKING.PAYMENT_TYPE_DEBT,
        }, {
            code: PaymentType.NinePayCollection,
            name: Strings.BOOKING.PAYMENT_TYPE_NINEPAY_COLLECTION,
        }, {
            code: PaymentType.NinePayATM,
            name: Strings.BOOKING.PAYMENT_TYPE_NINEPAY_ATM,
        }, {
            code: PaymentType.NinePayCredit,
            name: Strings.BOOKING.PAYMENT_TYPE_NINEPAY_CREDIT,
        }, {
            code: PaymentType.NinePayCreditInternational,
            name: Strings.BOOKING.PAYMENT_TYPE_NINEPAY_CREDIT_INTERNATIONAL,
        },] as ICodename[];
        return codenames;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Strings.getLanguage()]);

    const outCodenames = useMemo(() => {
        return codenames.filter(el => {
            if (showCodenameAll && el.code === Constants.CODENAME_ALL) return true;

            switch (el.code) {
                case PaymentType.Debt:
                case PaymentType.NinePayCollection:
                case PaymentType.NinePayATM:
                case PaymentType.NinePayCredit:
                case PaymentType.NinePayCreditInternational:
                    return true;

                default:
                    return false;
            }
        })
    }, [codenames, showCodenameAll]);

    return outCodenames;
}

export default usePaymentMethodCodenames;
