
import { Box, Button, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import { useAuth } from "providers/authProvider";



const InaccessibleLayout = () => {
    const auth = useAuth();

    const handleLogout = () => {
        Helpers.showConfirmAlert(
            Strings.Message.CONFIRM_LOGOUT,
            async () => {
                await auth.signoutRedirect();
            }
        );
    };

    return (
        <Box
            sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                px: 1,
                py: 2,
                position: "relative",
                [breakpoints.up("xl")]: {
                    transition: transitions.create(["margin-left", "margin-right"], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                },
            })}
        >
            <Box sx={{
                gap: 2,
                display: "flex",
                minHeight: "50vh",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
            }}>
                <Box sx={{
                    display: "flex",
                    marginTop: "24px",
                    marginBottom: "24px",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <Box sx={{
                        width: "99px",
                        height: "99px",
                        display: "flex",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "0.25em solid #facea8",
                        borderRadius: "50%",
                    }}>
                        <Box sx={{
                            color: "#facea8",
                            fontSize: "3.75rem"
                        }}>
                            !
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ gap: 0.5, display: "grid", textAlign: "center" }}>
                    <Typography variant="h6">{"Rất tiếc tài khoản đăng nhập của bạn không có quyền truy cập vào hệ thống"}</Typography>
                </Box>

                <Typography variant="button">{"Vui lòng đăng nhập lại bằng tài khoản khác!"}</Typography>

                <Button variant="contained" color="info" onClick={() => { handleLogout(); }}>
                    {"Đăng xuất"}
                </Button>
            </Box>
        </Box>
    );
};

export default InaccessibleLayout;
