import {
    Autocomplete,
    Box,
    Button,
    Chip,
    DataTable,
    DataTableStatusCell,
    DatePicker,
    FormField,
    Typography,
    useCommonComponentContext,
} from "@maysoft/common-component-react";
import { FileDownloadOutlined, InfoOutlined } from "@mui/icons-material";
import { Card, Grid, IconButton } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";

import Helpers from "commons/helpers";
import { ItineraryType, Mode, NeedApprove, SupplierCode } from "constants/enum";
import Constants from "constants/index";
import Strings from "constants/strings";
import RequestBookingService, { IRecordBookingV2 } from "services/booking/requestBooking.service";
import useDataRequestBooking from "./useDataRequestBooking.hook";

const requestBookingService = new RequestBookingService();

export type IDataRequestGetPaged = {
    code?: string;
    type?: number;
    searchText?: string;
    requestStatus?: number;
    customReservationType?: any;
    bookingEndTime?: string | number;
    bookingStartTime?: string | number;

    orderby?: string;
    pageSize?: number;
    pageNumber?: number;
    totalCount?: number;
};

const RequestBookingContainer = (props: {
    title?: string;

    type?: ItineraryType;
    supplierCode?: SupplierCode;
    hidenActionCreate?: boolean;
    hidenActionUpdate?: boolean;
    hidenActionExport?: boolean;

    requestGetPaged: IDataRequestGetPaged;

    onNavigate: (data?: { id?: string; mode?: number }) => void;
    onGetPaged: (data: { query: string; totalCount: number }) => void;
}) => {
    const { userInfo, onError, onHideLoading, onShowLoading } = useCommonComponentContext();

    const {
        requestStatusList,
        itineraryTypeList,

        genReservationtype,
        listReservationType,

        getDataMapOrgaByIds,
        getDataMapOrderPriceByIds,
        getDataConfirmStatusByBooking,
        getCellReservationStatusByBooking,
    } = useDataRequestBooking();

    const [model, setModel] = useState<{
        requestData?: IDataRequestGetPaged;
        requestDataTemp?: IDataRequestGetPaged;
    }>({});

    const [dataMapOrg, setDataMapOrg] = useState<Map<string, any>>(new Map());
    const [dataOrderPrice, setDataOrderPrice] = useState<Map<string, any>>(new Map());

    const [dataGetPaged, setDataGetPaged] = useState<IRecordBookingV2[]>([]);

    const [loadingDataTable, setLoadingDataTable] = useState<boolean>(false);

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(userInfo?.userProfile?.organizationId)) {
            const newReq: IDataRequestGetPaged = {
                code: props?.requestGetPaged?.code,
                type: props?.requestGetPaged?.type,
                searchText: props?.requestGetPaged?.searchText,
                requestStatus: props?.requestGetPaged?.requestStatus,
                bookingEndTime: props?.requestGetPaged?.bookingEndTime,
                bookingStartTime: props?.requestGetPaged?.bookingStartTime,
                customReservationType: props?.requestGetPaged?.customReservationType,

                pageSize: props?.requestGetPaged?.pageSize,
                pageNumber: props?.requestGetPaged?.pageNumber,
                totalCount: props?.requestGetPaged?.totalCount,
            };

            getPagedBooking(newReq);
        }
    }, [props?.requestGetPaged, userInfo?.userProfile?.organizationId]);

    const getPagedBooking = async (req?: IDataRequestGetPaged) => {
        try {
            // onShowLoading();
            setLoadingDataTable(true);

            const pageSize = req?.pageSize || Constants.ROW_PER_PAGE;
            const pageNumber = Helpers.getPageNumber(req?.pageNumber || 1, pageSize, req?.totalCount || 0) || 1;

            const result = await requestBookingService.getPagedV2({
                code: req?.code,
                searchText: req?.searchText,
                requestStatus: req?.requestStatus,
                type: Helpers.isNullOrEmpty(props.type) ? req?.type : props.type,
                bookingEndTime: req?.bookingEndTime ?? moment().endOf("month").unix(),
                bookingStartTime: req?.bookingStartTime ?? moment().startOf("month").unix(),
                ...genReservationtype(req?.customReservationType),
                pageSize,
                pageNumber,
                organizationId: userInfo?.userProfile?.organizationId,
            });

            const orderIds = [...(result.items || [])].map((el) => el.orderId);
            if (orderIds.length > 0) {
                const newDataOrderPrice = await getDataMapOrderPriceByIds(orderIds);
                setDataOrderPrice(newDataOrderPrice);
            }

            const idOrgs = [...(result.items || [])].map((el) => el.organizationId);
            if (idOrgs.length > 0) {
                const newDataOrg = await getDataMapOrgaByIds(idOrgs);
                setDataMapOrg(newDataOrg);
            }

            setDataGetPaged(result.items);

            const newModelRequestData: IDataRequestGetPaged = {
                ...req,
                pageSize,
                pageNumber,
                totalCount: result.totalCount,
                bookingEndTime: req?.bookingEndTime ?? moment().endOf("month").unix(),
                bookingStartTime: req?.bookingStartTime ?? moment().startOf("month").unix(),
            };

            setModel({
                requestData: newModelRequestData,
                requestDataTemp: newModelRequestData,
            });

            const query = `?${Helpers.handleFormatParams(newModelRequestData)}`;

            props?.onGetPaged({ query, totalCount: result.totalCount });
        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            onError(e);
        } finally {
            // onHideLoading();
            setLoadingDataTable(false);
        }
    };

    const exportCSV = async () => {
        Helpers.showConfirmAlert("Bạn chắc chắn muốn tải file về", async () => {
            try {
                onShowLoading();

                const result = await requestBookingService.exportCsv({
                    code: model.requestData?.code,
                    searchText: model.requestData?.searchText,
                    requestStatus: model.requestData?.requestStatus,
                    type: Helpers.isNullOrEmpty(props.type) ? model.requestData?.type : props.type,
                    bookingEndTime: model.requestData?.bookingEndTime ?? moment().endOf("month").unix(),
                    bookingStartTime: model.requestData?.bookingStartTime ?? moment().startOf("month").unix(),
                    ...genReservationtype(model.requestData?.customReservationType),
                    organizationId: userInfo?.userProfile?.organizationId,
                    pageSize: undefined,
                    pageNumber: undefined,
                });

                const date = moment().format("HHmmDDMMYYYY");

                var fileName = `Flight_Booking_${date}.csv`;
                const downloadUrl = URL.createObjectURL(result);
                const a = document.createElement("a");

                a.href = downloadUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
            } catch (error) {
                const e = Helpers.renderExceptionError(error);
                onError(e);
            } finally {
                onHideLoading();
            }
        });
    };

    // #region Filter Form
    const RenderFilterForm = () => (
        <>
            <Grid container spacing={2}>
                {Helpers.isNullOrEmpty(props.type) && (
                    <Grid item xs={12}>
                        <Autocomplete
                            label={"Loại đặt chỗ"}
                            placeholder={"Chọn loại đặt chỗ"}
                            data={itineraryTypeList || []}
                            defaultValue={model.requestDataTemp?.type}
                            onChange={(value) => {
                                const newValue = !Helpers.isNullOrEmpty(value) ? Number(value) : undefined;

                                setModel((prev) => ({
                                    ...prev,
                                    requestDataTemp: {
                                        ...prev.requestDataTemp,
                                        type: newValue,
                                    },
                                }));
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <DatePicker
                        label={"Thời gian"}
                        views={["month", "year"]}
                        placeholder={"Chọn thời gian"}
                        value={Helpers.getDateValue(model?.requestDataTemp?.bookingStartTime)}
                        onChangeValue={(value: any) => {
                            const valStartTime = !Helpers.isNullOrEmpty(value) ? moment(value).startOf("month").unix() : undefined;

                            const valEndTime = !Helpers.isNullOrEmpty(value) ? moment(value).endOf("month").unix() : undefined;

                            setModel((prev) => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    bookingEndTime: valEndTime,
                                    bookingStartTime: valStartTime,
                                },
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormField
                        label={"Mã đơn hàng/ đặt chỗ"}
                        placeholder={"Nhập mã đơn hàng/ đặt chỗ"}
                        defaultValue={model.requestDataTemp?.code}
                        onBlur={(value: any) => {
                            setModel((prev) => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    code: value,
                                },
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormField
                        label={"Khách hàng/ Công ty"}
                        placeholder={"Nhập khách hàng/ Công ty"}
                        defaultValue={model.requestDataTemp?.searchText}
                        onBlur={(value: any) => {
                            setModel((prev) => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    searchText: value,
                                },
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        label={"Trạng thái duyệt"}
                        placeholder={"Chọn trạng thái duyệt"}
                        data={requestStatusList || []}
                        defaultValue={model.requestDataTemp?.requestStatus}
                        onChange={(value) => {
                            const newValue = !Helpers.isNullOrEmpty(value) ? Number(value) : undefined;

                            setModel((prev) => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    requestStatus: newValue,
                                },
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        label={"Trạng thái đặt chỗ"}
                        data={listReservationType || []}
                        placeholder={"Chọn trạng thái đặt chỗ"}
                        defaultValue={model.requestDataTemp?.customReservationType}
                        onChange={(value) => {
                            setModel((prev) => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    customReservationType: value,
                                },
                            }));
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );

    // #region Chip Value Filter
    const RenderChipValueFilter = () => (
        <>
            {(!Helpers.isNullOrEmpty(model?.requestData?.code) ||
                !Helpers.isNullOrEmpty(model?.requestData?.searchText) ||
                !Helpers.isNullOrEmpty(model?.requestData?.requestStatus) ||
                !Helpers.isNullOrEmpty(model?.requestData?.bookingEndTime) ||
                !Helpers.isNullOrEmpty(model?.requestData?.bookingStartTime) ||
                !Helpers.isNullOrEmpty(model?.requestData?.customReservationType)) && (
                <Box sx={{ marginTop: 1, marginBottom: 2 }}>
                    {!Helpers.isNullOrEmpty(model?.requestData?.bookingStartTime) && !Helpers.isNullOrEmpty(model?.requestData?.bookingEndTime) && (
                        <Chip
                            label={"Thời gian"}
                            value={[
                                moment(Helpers.getDateValue(model?.requestData?.bookingStartTime)).format("DD/MM/YYYY"),
                                moment(Helpers.getDateValue(model?.requestData?.bookingEndTime)).format("DD/MM/YYYY"),
                            ].join(" - ")}
                            onDelete={() => {
                                getPagedBooking({
                                    ...model.requestData,
                                    bookingEndTime: moment().endOf("month").unix(),
                                    bookingStartTime: moment().startOf("month").unix(),
                                });
                            }}
                        />
                    )}

                    {!Helpers.isNullOrEmpty(model?.requestData?.code) && (
                        <Chip
                            label={"Mã đơn hàng/ đặt chỗ"}
                            value={model?.requestData?.code}
                            onDelete={() => {
                                getPagedBooking({ ...model.requestData, code: undefined });
                            }}
                        />
                    )}

                    {!Helpers.isNullOrEmpty(model?.requestData?.searchText) && (
                        <Chip
                            label={"Khách hàng/ Công ty"}
                            value={model?.requestData?.searchText}
                            onDelete={() => {
                                getPagedBooking({ ...model.requestData, searchText: undefined });
                            }}
                        />
                    )}

                    {!Helpers.isNullOrEmpty(model?.requestData?.requestStatus) && (
                        <Chip
                            label={"Trạng thái duyệt"}
                            value={[...(requestStatusList || [])].find((el) => el.code === model?.requestData?.requestStatus)?.name || ""}
                            onDelete={() => {
                                getPagedBooking({ ...model.requestData, requestStatus: undefined });
                            }}
                        />
                    )}

                    {!Helpers.isNullOrEmpty(model?.requestData?.customReservationType) && (
                        <Chip
                            label={"Trạng thái đặt chỗ"}
                            value={[...(listReservationType || [])].find((el) => el.code === model?.requestData?.customReservationType)?.name || ""}
                            onDelete={() => {
                                getPagedBooking({ ...model.requestData, customReservationType: undefined });
                            }}
                        />
                    )}
                </Box>
            )}
        </>
    );

    // #region Data Columns
    const dataColumns = useMemo(
        () => [
            {
                Header: Strings.Common.ACTION,
                accessor: "action",
                width: "60px",
                Cell: (row: any) => (
                    <IconButton
                        color="info"
                        onClick={() => {
                            props?.onNavigate({ id: row?.row?.original?.id, mode: Mode.View });
                        }}
                    >
                        <InfoOutlined />
                    </IconButton>
                ),
            },
            {
                Header: "Thời gian đặt",
                accessor: "createTime",
                width: "120px",
                Cell: (row: any) => {
                    const newValue = Helpers.getDateValue(row?.value);
                    return (
                        <Box display="grid">
                            <Typography variant="caption" fontWeight="bold" color="secondary">
                                {newValue ? moment(newValue).format("HH:mm DD/MM/YYYY") : "-"}
                            </Typography>
                        </Box>
                    );
                },
            },
            {
                Header: "Booking Code",
                accessor: "externalBookingCode",
                width: "90px",
            },
            {
                Header: Strings.ORDER.ORDER_CODE,
                accessor: "bookingCode",
                width: "80px",
                Cell: (row: any) => {
                    const orderCode = dataOrderPrice.get(row?.row?.original?.orderId)?.orderCode || "-";
                    return <>{orderCode}</>;
                },
            },
            {
                Header: "Mã đặt chỗ",
                accessor: "reservationCode",
                width: "80px",
                Cell: (row: any) => {
                    if (Helpers.isNullOrEmpty(row?.row?.original?.extraInfo)) {
                        return <></>;
                    } else {
                        const extraInfo = Helpers.converStringToJson(row?.row?.original?.extraInfo);

                        if (extraInfo && !Helpers.isNullOrEmpty(extraInfo?.IssueTicketInfo)) {
                            const issueTicketInfo = extraInfo && Helpers.converStringToJson(extraInfo?.IssueTicketInfo);
                            return <> {issueTicketInfo?.Booking?.Itineraries?.[0]?.reservation_code || "-"} </>;
                        } else {
                            return <>-</>;
                        }
                    }
                },
            },
            {
                Header: "Khách hàng",
                accessor: "name",
                width: "200px",
                Cell: (row: any) => (
                    <Box display="grid">
                        <Typography variant="caption" fontWeight="bold" color="secondary">
                            {row?.value}
                        </Typography>
                    </Box>
                ),
            },
            {
                Header: "Số điện thoại",
                accessor: "phoneNumber",
                width: "100px",
                Cell: (row: any) => {
                    const value = row.value || "-";
                    const extraInfoParsed = Helpers.converStringToJson(row.row.original.extraInfo);

                    if (extraInfoParsed.Contact) {
                        const { phone_number, phone_code } = extraInfoParsed.Contact;
                        return (
                            <>
                                +{phone_code}
                                {phone_number}
                            </>
                        );
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: "Công ty",
                accessor: "organizationId",
                width: "200px",
                Cell: (row: any) => <>{dataMapOrg.get(row.value)?.name || "-"}</>,
            },
            {
                Header: "Tổng tiền",
                accessor: "totalAmount",
                width: "120px",
                Cell: (row: any) => {
                    const currency = row?.row?.original?.currency || userInfo?.userProfile?.currency;
                    let totalAmount = (row?.value || 0) + (row?.row?.original?.serviceFee || 0) + 0;

                    if (!Helpers.isNullOrEmpty(row?.row?.original?.orderId)) {
                        totalAmount = dataOrderPrice.get(row?.row?.original?.orderId)?.amount || 0;
                    }

                    return <>{`${Helpers.formatCurrency(totalAmount)} ${currency}`}</>;
                },
            },
            {
                Header: "Trạng thái duyệt",
                accessor: "itineraryDetailConfirmStatus",
                Cell: (row: any) =>
                    Number(row?.row?.original?.needApprove) === NeedApprove.Approve ? (
                        <DataTableStatusCell data={getDataConfirmStatusByBooking(Number(row?.value))} />
                    ) : (
                        <></>
                    ),
            },
            {
                Header: "Trạng thái đặt chỗ",
                accessor: "paymentStatus",
                Cell: (row: any) => {
                    const extraInfo = Helpers.converStringToJson(row?.row?.original?.extraInfo);
                    const valueConver = extraInfo?.LastTicketDate ? Helpers.convertDDMM_To_MMDD(extraInfo?.LastTicketDate) : undefined;
                    const valueUnix = valueConver ? moment.utc(valueConver).unix() : undefined;

                    const newdata = getCellReservationStatusByBooking({
                        lastTicketDate: valueUnix,
                        paymentStatus: row?.value,
                        status: row?.row?.original?.status,
                    });

                    return <DataTableStatusCell data={newdata} />;
                },
            },
        ],
        [dataMapOrg, dataOrderPrice, getDataConfirmStatusByBooking, userInfo?.userProfile?.currency, getCellReservationStatusByBooking]
    );

    return (
        <Box>
            <Grid container spacing={3}>
                {/* Button Action */}
                {!Helpers.isNullOrEmpty(props.title) && (
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: !Helpers.isNullOrEmpty(props.title) ? "space-between" : "end",
                            }}
                        >
                            {!Helpers.isNullOrEmpty(props.title) && <Typography variant="h5">{props.title}</Typography>}
                        </Box>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <RenderChipValueFilter />

                    <Card>
                        <Box
                            sx={{
                                padding: 2,
                                width: "100%",
                                overflow: "auto",
                            }}
                        >
                            <DataTable
                                loading={loadingDataTable}
                                rowPerPageOptions={[20, 50, 100]}
                                rowPerPage={model.requestData?.pageSize}
                                totalCount={model.requestData?.totalCount || 0}
                                pageNumber={model.requestData?.pageNumber || 1}
                                onChangePageSize={(pageSize) => {
                                    getPagedBooking({ ...model.requestData, pageSize });
                                }}
                                onChangePageNumber={(pageNumber) => {
                                    getPagedBooking({ ...model.requestData, pageNumber });
                                }}
                                table={{
                                    rows: dataGetPaged,
                                    columns: dataColumns,
                                }}
                                // actionList={actionList}

                                filterForm={<RenderFilterForm />}
                                onFilter={() => {
                                    getPagedBooking({ ...model.requestDataTemp, pageNumber: 1 });
                                }}
                                onReset={() => {
                                    getPagedBooking({
                                        pageNumber: 1,
                                        pageSize: Constants.ROW_PER_PAGE,
                                    });
                                }}
                                onCloseFilter={() => {
                                    const newData = { ...model.requestData };
                                    setModel({
                                        ...model,
                                        requestDataTemp: newData,
                                    });
                                }}
                                leftElement={
                                    <>
                                        {!props.hidenActionExport && (
                                            <Button
                                                color="success"
                                                disabled={loadingDataTable}
                                                onClick={() => {
                                                    exportCSV();
                                                }}
                                            >
                                                <FileDownloadOutlined /> &nbsp; {Strings.Common.DOWNLOAD}
                                            </Button>
                                        )}
                                    </>
                                }
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RequestBookingContainer;
