import { Box } from "@maysoft/common-component-react";
import DeeptechAccordion from "../../components/DeeptechAccordion";
import TextWithPrice from "../../components/TextWithPrice";
import TransferFlightItem from "../../components/TransferFlightPopupItem";
import { IBookingDetailPopupUser, ITransferBookingDetailViewModel } from "components/Booking/BookingDetailContainer/interfaces";
import { IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import { ICodename } from "commons/interfaces";
import Strings from "constants/strings";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";

interface IEditModeFlightBookingDetailTransferFlightPopupProps {
    data: IDetailBooking;
    handleChangeValue: (newTransferRequest: ITransferBookingDetailViewModel, key: keyof ITransferBookingDetailViewModel) => void;

    users: IBookingDetailPopupUser[];
    airlines: ICodename[];
    cabinClassCodenames: ICodename[];

    amount: number;
    serviceFee: number;
    paymentFee: number;
    totalTransferPrice: number;
    currency: string;
    transferRequests: ITransferBookingDetailViewModel[];
    transferRequestErrors: Record<string, {
        [key: string]: string;
    }>;

    isLoadingServiceFee: boolean;
    renderPaymentContent: (props: { title: string }) => JSX.Element;
}

const EditModeFlightBookingDetailTransferFlightPopup = ({
    handleChangeValue,

    users,
    airlines,
    cabinClassCodenames,

    isLoadingServiceFee,
    renderPaymentContent,

    currency,
    amount,
    serviceFee,
    paymentFee,
    totalTransferPrice,

    transferRequests,
    transferRequestErrors,
}: IEditModeFlightBookingDetailTransferFlightPopupProps) => {
    const { getBookingDetailsByUserId } = useAddMoreBookingServiceContext();
    return <Box display="flex" flexDirection="column" gap={2}>
            {users.map((user) => (
                <DeeptechAccordion
                    key={user.id}
                    data={user}
                    renderContent={() => {
                        return (
                            <>
                                {getBookingDetailsByUserId(user.id).map((bookingDetailItem) => {
                                    const data = transferRequests.find(x => x.bookingDetailId === bookingDetailItem.id && x.userId === user.id);
                                    const error = transferRequestErrors?.[data?._id];
                                    return <TransferFlightItem
                                        key={`${user.id}_${bookingDetailItem.id}`}
                                        data={data}
                                        onChange={handleChangeValue}
                                        user={user}
                                        error={error}
                                        airlines={airlines}
                                        detail={bookingDetailItem}
                                        cabinClasses={cabinClassCodenames}
                                    />
                                })}
                            </>
                        );
                    }}
                />
            ))}
            {renderPaymentContent({ title: Strings.Common.PAYMENT_TYPE })}

            <Box display="flex" flexDirection="column" gap={1}>
                <TextWithPrice
                    text={Strings.BOOKING.TRANSFER_POPUP_TOTAL_FLIGHT_CHANGE_FEE}
                    currency={currency}
                    titleFontWeight="bold"
                    price={totalTransferPrice}
                />
                <TextWithPrice
                    text={Strings.Common.SERVICE_FEE_TAX_INCLUDE}
                    currency={currency}
                    titleFontWeight="bold"
                    price={serviceFee}
                    loading={isLoadingServiceFee}
                />
                <TextWithPrice
                    text={"Phí thanh toán"}
                    currency={currency}
                    titleFontWeight="bold"
                    price={paymentFee}
                    loading={isLoadingServiceFee}
                />
                <TextWithPrice
                    fontWeight="medium"
                    text={Strings.BOOKING.POPUP_TOTAL}
                    currency={currency}
                    color="warning"
                    price={amount}
                    loading={isLoadingServiceFee}
                />
            </Box>
        </Box>
}

export default EditModeFlightBookingDetailTransferFlightPopup;