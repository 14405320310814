import Constants from "../../constants";
import IdentityService from "./identity.service";

class OrganizationService extends IdentityService {

    public getOrganizationByIds = async (ids: string[]) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORGANIZATION.GET_ORGANIZATION_BY_IDS,
            data: { "ids": ids },
        });
        return result.data.result;
    };
}

export default OrganizationService;
